// General
export const ASC = 'ASC';
export const DESC = 'DESC';
export const NONE = 'NONE';
export const PREV = 'Prev';
export const NEXT = 'Next';

export const API_ERROR_CODES = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  FOUND_TOO_MANY_INVENTORIES: 'FOUND_TO_MANY_INVENTORIES_FOR_STYLE',
  SHIP_NODE_ALREADY_PRESENT_IN_DG: 'SHIP_NODE_ALREADY_PRESENT_IN_DG',
};

// Tables
export const RULE_TYPE_NAME = 'ruleTypeName';
export const VERSION = 'version';
export const BASED_ON_VERSION = 'basedOn';
export const LAST_MODIFIED = 'lastModified';
export const EDITOR = 'editor';

export const ROW_HEIGHT = 35;

export const INPUT_TYPES = {
  NUMBER: 'number',
  TEXT: 'text',
};

export const ALPHANUMERIC_FIELDS = [
  RULE_TYPE_NAME,
  VERSION,
  BASED_ON_VERSION,
  LAST_MODIFIED,
  EDITOR,
];

// Dialogs
export const ACTIVATE = 'Activate';
export const CANCEL = 'Cancel';
export const ADD = 'ADD';
export const CONTINUE = 'CONTINUE';

// Roles
export const Roles = {
  VIEWER: 'Viewer',
  EDITOR: 'Editor',
  METADATA_EDITOR: 'Meta data editor',
};
