import { observable, action, runInAction, computed } from 'mobx';
import uniqBy from 'lodash/uniqBy';

import services from '../services';
import { errorStore } from './errorStore/ErrorStore';
import { API_ERROR_CODES } from '../constants/common';
// eslint-disable-next-line import/no-named-as-default

export class UiStore {
  baseConfig = services.config;

  @observable userData = null;

  @observable isLoading = false;

  @computed get isLoggedOut() {
    return !!errorStore.errors[API_ERROR_CODES.UNAUTHORIZED];
  }

  @computed get navLinks() {
    if (!this.routes) return null;

    return this.routes.filter(({ isNavLink }) => isNavLink);
  }

  @computed get routes() {
    if (!this.userData) return null;

    const { client: { routing } } = this.baseConfig;
    const { roles } = this.userData;

    const availableRoutes = [];
    const childRoutes = [];

    const routes = Object.keys(routing);

    routes.forEach(route => {
      const routeInPermissions = roles.includes('EDITOR') || roles.includes('VIEWER');

      if (routeInPermissions) {
        availableRoutes.push({
          ...routing[route],
          policies: [
            'WRITE',
            'READ',
          ],
          metadata: {
            allowedUserActivityActionTypes: [],
          },
        });

        routing[route].sharesPoliciesWith && routing[route].sharesPoliciesWith.forEach((child) => childRoutes.push({
          ...routing[child],
          policies: [
            'WRITE',
            'READ',
          ],
          metadata: {
            allowedUserActivityActionTypes: [],
          },
        }));
      }
    });

    return uniqBy([...availableRoutes, ...childRoutes], 'path');
  }

  @computed get userPersonalInfo() {
    if (!this.userData) return;

    const { email, username } = this.userData;
    const firstName = this.userData.firstname;
    const lastName = this.userData.lastname;
    return {
      email,
      firstName,
      lastName,
      username,
    };
  }

  @computed get userPermissions() {
    if (!this.userData) return;

    const { roles } = this.userData;
    return roles;
  }

  @action.bound
  async getUserData(params) {
    this.isLoading = true;
    let result = true;

    const { userService: { getUserData } } = services;

    try {
      const data = await getUserData(params);
      runInAction(() => {

        const x = '{"email":"rohithsreevatsava.muthakani@nike.com","username":"rohith","roles":["VIEWER","EDITOR","METADATA_EDITOR"],"firstname":"rohith","lastname":"muthakani"}';
        if (data['x-current-user'] == undefined) {
          data['x-current-user'] = x;
        }
        if (data['x-current-user'] != undefined) {
          this.userData = JSON.parse(data['x-current-user']);
        }
        // this.userData = data;
      });

    } catch (err) {
      result = true;
      console.error(err);
    }

    runInAction(() => {
      this.isLoading = false;
    });

    return result;
  }
}
