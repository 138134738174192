import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { Popup } from '../popup';
import { Button, buttonTypes } from '../button';
import { SvgIcon } from '../svg-icon';
import { dialogCancelBtnTestId } from '../../test-data/dataTestIds';
import '../../assets/icons/cross.svg';

const useStyles = makeStyles(({ colors, fontFamilies, fontSizes, spaces }) => ({
  dialog: {},
  swoosh: {
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 70,
  },
  swooshImage: {
    width: 150,
    height: 59,
  },
  header: {
    width: '100%',
    color: colors.black,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    letterSpacing: -0.55,
    lineHeight: '29px',
    marginBottom: spaces.lg,
    textAlign: 'center',
  },
  body: {
    margin: `0 0 ${spaces.md} 0`,
    color: colors.darkGray,
    fontSize: fontSizes.md,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 36,
  },
  cross: {
    cursor: 'pointer',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popup: {
    padding: '15px 50px 36px',
  },
  headerText: {},
  button: {
    width: 192,
    height: 40,
  },
  svgIconRoot: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  primary: {},
  secondary: {},
  buttonWrapper: {},
}));

export const AddRuleLineDialog = memo((props) => {
  const {
    isOpen,
    header,
    children,
    applyLabel,
    dataTestId,
    cancelLabel,
    transitionComponent,
    onApply,
    onCancel,
    onCrossCancel,
    onBackgroundClick,
    withSwoosh,
    ruleTypeName,
    isAddButtonActive,
    ruleTypeVersion,
    BackdropProps,
  } = props;
  const classes = useStyles(props);

  return (
    <Popup
      isOpen={isOpen}
      header={header}
      transitionComponent={transitionComponent}
      onBackgroundClick={onBackgroundClick}
      BackdropProps={BackdropProps}
      classes={classes}
    >
      <div
        data-testid={dataTestId}
        className={classes.dialog}
      >
        {!!header && (
          <div className={classes.header}>
            <div className={classes.headerContent}>
              <span className={classes.headerText}>{header}</span>
              <SvgIcon id="crossAddNewLineModalIconId" width={20} height={20} classes={classes} onClick={onCrossCancel} name="cross" />
            </div>
          </div>
        )}
        {!!withSwoosh && (
          <div className={classes.swoosh}>
            <SvgIcon name="nike-logo" classes={{ svgIconRoot: classes.swooshImage }} />
          </div>
        )}
        <div className={classes.body}>{children}</div>
        <div className={classes.buttons}>
          {!!applyLabel && (
            <Button
              id="addNewLineButtonRulePageId"
              type={buttonTypes.PRIMARY}
              disabled={!isAddButtonActive}
              dataTestId="dialogApplyBtnTestId"
              label={applyLabel}
              classes={{ button: cx(classes.button, classes.primary), buttonWrapper: classes.buttonWrapper }}
              onClick={() => onApply(ruleTypeName, ruleTypeVersion)}
            />
          )}
          {!!cancelLabel && (
            <Button
              id="cancelAddingNewLineButtonRulePageId"
              disabled={!onCancel}
              label={cancelLabel}
              dataTestId={dialogCancelBtnTestId}
              classes={{ button: cx(classes.button, classes.secondary), buttonWrapper: classes.buttonWrapper }}
              onClick={onCancel}
            />
          )}
        </div>
      </div>
    </Popup>
  );
});
