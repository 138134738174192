import { getFlexRuletypeName } from '../../utils/getFlexRuletypeName';

export class RuleLineServices {
  constructor(adapter, { api: { ruleTypes: { path } } }) {
    this._adapter = adapter;
    this._ruleLinesPath = path;
  }

  async getRuleLines(anchor, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params) {
    if (anchor) {
      params.anchor = anchor;
    }
    const ruleTypes = await this._adapter.get({
      path: 'getRuleTypes',
      isFlex: true,
    }).then(({ data }) => data.ruleTypes);
    if (isDraft && ruleTypeVersion === 'Draft') {
      if (Object.keys(ruleTypes).includes(ruleTypeName)) {
        return this._adapter.get({
          path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${basedOnVersion.match(/\d/g).join('')}/draftContent`,
          params: { ...params },
        }).then(({ data }) => data);
      }
      return this._adapter.get({
        path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${basedOnVersion.match(/\d/g).join('')}/draftContent`,
        params: { ...params },
      }).then(({ data }) => data);
    }
    if (Object.keys(ruleTypes).includes(ruleTypeName)) {
      return this._adapter.get({
        path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion}/content`,
        params: { ...params },
      }).then(({ data }) => data);
    }
    return this._adapter.get({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion}/content`,
      params: { ...params },
    }).then(({ data }) => data);
  }

  createDraft(ruleTypeName, ruleTypeVersion) {
    return this._adapter.post({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion.match(/\d/g).join('')}/createDraft`,
    });
  }

  hasModifications(ruleTypeName, ruleTypeVersion) {
    return this._adapter.get({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion.match(/\d/g).join('')}/draftHasModifications`,
    }).then(({ data }) => data);
  }

  getUrlFromYaml(ruleTypeName) {
    return this._adapter.get({
      path: `getRuleUrl/${ruleTypeName}`,
      isFlex: true,
    }).then(({ data }) => data);
  }

  async getRuleLineRowHeadings(ruleTypeName) {
    const ruleTypes = await this._adapter.get({
      path: 'getRuleTypes',
      isFlex: true,
    }).then(({ data }) => data.ruleTypes);
    const index = ruleTypeName.indexOf('-');
    let rtName = '';
    if (index) {
      rtName = ruleTypeName.substr(0, index);
    } else {
      rtName = ruleTypeName;
    }
    if (Object.keys(ruleTypes).includes(rtName)) {
      return this._adapter.get({
        path: `getRuleMeta/${ruleTypeName}`,
        isFlex: true,
      }).then(({ data }) => data);
    }
    return this._adapter.get({
      path: `${this._ruleLinesPath}/${ruleTypeName}/meta`,
    }).then(({ data }) => data);
  }

  addDraftLine(ruleTypeName, ruleTypeVersion, user, data) {
    return this._adapter.post({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion}/draftLine`,
      body: [{
        user: user,
        action: 'UPSERT',
        json: JSON.stringify(data),
      }],
    });
  }

  updateDraftLine(ruleTypeName, ruleTypeVersion, id, data) {
    return this._adapter.post({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion}/draftLine`,
      body: [{
        ruleId: id,
        action: 'UPSERT',
        json: JSON.stringify(data),
      }],
    });
  }

  deleteDraftLine(ruleTypeName, ruleTypeVersion, actions) {
    return this._adapter.post({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion}/draftLine`,
      body: actions,
    });
  }

  removeDraftLine(ruleTypeName, ruleTypeVersion, lineIds) {
    return this._adapter.post({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion}/removeDraftLine`,
      body: lineIds,
    });
  }

  finalizeDraft(ruleTypeName, ruleTypeVersion) {
    return this._adapter.post({
      path: `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion}/finalizeDraft`,
    });
  }

  async getJsonDataForRuleType(ruleTypeName, ruleTypeVersion, basedOnVersion) {
    const res = await this._adapter.get({
      path: ruleTypeVersion === 'Draft'
        ? `${this._ruleLinesPath}/${ruleTypeName}/versions/${basedOnVersion}/downloadDraft`
        : `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion}`,
      params: {
        responseType: 'blob',
      },
    });
    return res.data;
  }

  downloadFile(ruleTypeName, ruleTypeVersion, basedOnVersion) {
    this._adapter.get({
      path: ruleTypeVersion === 'Draft'
        ? `${this._ruleLinesPath}/${ruleTypeName}/versions/${basedOnVersion}/downloadDraft`
        : `${this._ruleLinesPath}/${ruleTypeName}/versions/${ruleTypeVersion}`,
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${ruleTypeName}${ruleTypeVersion}.json`);
      document.body.appendChild(link);
      link.click();
    });
  }

  downloadYamlFile(ruleTypeName) {
    this._adapter.get({
      path: `/getRuleYaml/${ruleTypeName}`,
      isFlex: true,
      params: {
        responseType: 'blob',
      },
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${ruleTypeName}.yaml`);
      document.body.appendChild(link);
      link.click();
    });
  }

  async uploadFile(ruleTypeName, formData, setProgress) {
    const onUploadProgress = data => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    };
    const ruleTypes = await this._adapter.get({
      path: 'getRuleTypes',
      isFlex: true,
    }).then(({ data }) => data.ruleTypes);
    if (Object.keys(ruleTypes).includes(ruleTypeName)) {
      return this._adapter.post({
        path: `${this._ruleLinesPath}/${getFlexRuletypeName(ruleTypeName, ruleTypes)}`,
        body: formData,
        onUploadProgress: onUploadProgress,
      });
    }
    return this._adapter.post({
      path: `${this._ruleLinesPath}/${ruleTypeName}`,
      body: formData,
      onUploadProgress: onUploadProgress,
    });
  }
}
