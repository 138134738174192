import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import cx from 'classnames';

import * as themeColors from '../../styles/colors';

const useStyles = makeStyles(({ mixins }) => ({
  resetFiltersButtonContainer: {
    ...mixins.flexCenterCenter,
  },
  resetFiltersButton: {
    background: themeColors.white,
    color: themeColors.blue,
    textDecorationLine: 'underline',
  },
  viewOlderVersionsDivider: {
    marginRight: 69,
  },
  iconContainer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const FilterLabel = observer(({ isUnderline, icon, label, onClick, id }) => {
  const classes = useStyles();

  return (
    <div id={id} data-testid="filterLabelTestId" className={label === 'Reset filters' ? classes.resetFiltersButtonContainer : cx([classes.resetFiltersButtonContainer, classes.viewOlderVersionsDivider])}>
      <span className={classes.iconContainer}>{icon}</span>
      <button data-testid="filterLabelButtonTestId" type="button" style={{ textDecorationLine: isUnderline ? 'underline' : 'none' }} className={classes.resetFiltersButton} onClick={onClick}>{label}</button>
    </div>
  );
});
