import {
  observable,
  action,
  computed,
} from 'mobx';

import {
  TYPE_ERROR,
  TYPE_SUCCESS,
  TYPE_WARNING,
  TYPE_LIFETIME,
} from '../../constants/notification';

import { randomInteger } from '../../utils/random';

export class NotificationStore {
  @observable notifications = [];

  @computed get notShown() {
    return this.notifications.filter(({ shown }) => !shown);
  }

  @action.bound get(id) {
    return this.notifications.find(({ id: currentId }) => currentId === id);
  }

  @action.bound has(id) {
    return this.get(id) !== undefined;
  }

  @action.bound add({ overrideNotification, id, type, customTitle, message = '', lifetime, contentRenderer }) {
    const shown = false;
    const resolvedId = !id || this.has(id) ? randomInteger() : id;

    lifetime = lifetime !== undefined && +lifetime > -1 ? lifetime : TYPE_LIFETIME[type];

    if (overrideNotification && id !== resolvedId) {
      const previousNotification = this.get(id);

      if (previousNotification) {
        previousNotification.forceClose = true;
      }
    }

    this.notifications = [...this.notifications, {
      id: resolvedId,
      type,
      customTitle,
      message,
      lifetime,
      shown,
      contentRenderer,
    }];

    return id;
  }

  @action.bound addError(message) {
    return this.add({ type: TYPE_ERROR, message });
  }

  @action.bound addWarning(message) {
    return this.add({ type: TYPE_WARNING, message });
  }

  @action.bound addSuccess(message) {
    return this.add({ type: TYPE_SUCCESS, message });
  }

  @action.bound delete(ids) {
    const deleteNotification = (id) => {
      const notification = this.get(id);
      this.notifications.remove(notification);
    };

    if (Array.isArray(ids)) {
      ids.forEach(deleteNotification);
    } else {
      deleteNotification(ids);
    }
  }

  @action.bound clear() {
    this.notifications.clear();
  }

  @action.bound markAsShown(ids) {
    const markNotificationAsShown = (id) => {
      const index = this.notifications.findIndex(({ id: currentId }) => currentId === id);
      if (index === -1) return;
      this.notifications[index].shown = true;
    };

    if (Array.isArray(ids)) {
      ids.forEach(markNotificationAsShown);
    } else {
      markNotificationAsShown(ids);
    }
  }
}
