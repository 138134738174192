import React, { memo } from 'react';
import { func, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Popup } from '../popup';
import { Button } from '../button';

const useStyles = makeStyles(({ colors, fontFamilies }) => ({
  modalHeading: {
    fontFamily: fontFamilies.primary,
    fontSize: 23,
    lineHeight: 'initial',
  },
  modalBody: {
    color: colors.black,
    margin: '18px 0 24px',
  },
  modalActions: {
    marginLeft: 'auto',
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
}));

export const UnauthorizedModal = memo((props) => {
  const {
    isOpen,
    onClick,
  } = props;
  const classes = useStyles(props);

  return (
    <Popup
      isOpen={isOpen}
      classes={{ paper: classes.paper }}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <div className={classes.modalHeading}>Session Expired</div>

      <div className={classes.modalBody}>
        You have been inactive for a while, please refresh the page
      </div>

      <Button
        dataTestId="refreshPageBtn"
        label="Refresh"
        classes={{ button: classes.modalActions }}
        onClick={onClick}
      />
    </Popup>
  );
});

UnauthorizedModal.propTypes = {
  isOpen: bool,
  onClick: func.isRequired,
};
