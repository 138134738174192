import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { matchPath } from 'react-router';
import cx from 'classnames';

import { Header } from '../header';
import { Navigation } from '../navigation';
import { useStores } from '../../custom-hooks/store/use-stores';
import { UnauthorizedModal } from '../unauthorized-modal';

import { destructUrlPattern } from '../../constants/urls';
import { DEFAULT_VIEW } from '../../constants/ui';

const useStyles = makeStyles(({ colors, mixins }) => ({
  pageLayout: {
    ...mixins.flexColumn,
    flex: 1,
    height: '100%',
  },
  headerPlaceholder: {
    height: 60,
    background: colors.black,
    color: colors.white,
  },
  mainContent: {
    ...mixins.flexColumn,
    background: colors.white,
    flex: 1,
    position: 'relative',
    padding: '0 100px 20px',
    transition: '.2s',
    overflowY: 'auto',
    filter: ({ darkMode }) => darkMode && 'invert(92%)',
  },
  view: {
    marginBottom: 50,
  },
}));

export const PageLayout = observer((props) => {
  const { children } = props;
  const {
    routing: {
      location: { pathname },
    },
    uiStore: {
      isLoggedOut,
      darkMode,
      baseConfig: {
        pageLayoutConfig,
      },
    },
  } = useStores();
  const classes = useStyles({ ...props, darkMode });

  const rootRef = useRef(null);

  const [currentScrollPos, setCurrentScrollPos] = useState(0);
  const [isNavCollapsed, setNavCollapsed] = useState(false);

  const {
    params: {
      path: currentPathName,
    } = {},
  } = matchPath(pathname, { path: destructUrlPattern }) || {};

  const pageConfig = pageLayoutConfig[currentPathName] ? currentPathName : DEFAULT_VIEW;
  const { withNavbar } = pageLayoutConfig[pageConfig];
  const onPageRefresh = () => window.location.reload();

  const onScrollHandler = () => {
    const { current: { scrollTop } } = rootRef;

    if (!currentScrollPos && scrollTop > currentScrollPos) {
      setCurrentScrollPos(scrollTop);
      setNavCollapsed(!isNavCollapsed);
    } else if (!currentScrollPos && !scrollTop) {
      setCurrentScrollPos(0);
      setNavCollapsed(isNavCollapsed);
    }
  };

  useEffect(() => {
    rootRef.current.addEventListener('scroll', onScrollHandler);

    return () => {
      rootRef.current.removeEventListener('scroll', onScrollHandler);
    };
  }, []);

  return (
    <div className={classes.pageLayout} data-testid="pageLayoutWrapper">
      <Header />
      {withNavbar && <Navigation />}

      <UnauthorizedModal
        isOpen={isLoggedOut}
        onClick={onPageRefresh}
      />

      <div className={cx(classes.mainContent, classes[pageConfig])} ref={rootRef}>
        {children}
      </div>
    </div>
  );
});

PageLayout.propTypes = {};

PageLayout.defaultProps = {};
