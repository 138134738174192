import React from 'react';
import { Router } from 'react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { LoginRequired } from '@nike/aegis-auth-react'

import { routingStore } from './stores/RoutingStore';
import { App } from './components/App';
import { NotificationProvider } from './components/notification-provider';
import { theme } from './styles/theme';
import { oAuthClient } from './config';
const browserHistory = createBrowserHistory();

export const history = syncHistoryWithStore(browserHistory, routingStore);

const Root = () => {
  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <NotificationProvider>
          <LoginRequired client={oAuthClient} >
            <App oAuthClient={oAuthClient} />
          </LoginRequired>
        </NotificationProvider>
      </MuiThemeProvider>
    </Router>
  );
};

export default Root;

Root.propTypes = {};
