import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { object } from 'prop-types';
import { useStores } from '../../custom-hooks/store/use-stores';

import { SvgIcon } from '../svg-icon';
import { UserInfo } from '../user-info';
import { UserRole } from '../user-role';
import { Button, buttonTypes } from '../button';
import { Dialog } from '../dialog';
import { FeedbackForm } from '../feedback-form';
import { blue, white } from '../../styles/colors';

import '../../assets/icons/nike-logo.svg';

const useStyles = makeStyles(({ colors, fontFamilies, mixins }) => ({
  header: {
    ...mixins.flexRowBetweenCenter,
    minHeight: 60,
    padding: '0 90px',
    background: colors.black,
  },
  appSection: {
    fontSize: 20,
    color: colors.white,
    fontFamily: fontFamilies.primary,
    textTransform: 'uppercase',
    display: 'flex',
  },
  logo: {
    width: 64,
    height: 32,
    verticalAlign: 'middle',
    fill: colors.white,
    margin: '0 20px',
  },
  userPanel: {
    ...mixins.flexRowStartCenter,
  },
  leaveFeedbackButton: {
    backgroundColor: blue,
    width: 133,
    height: 36,
    fontSize: 12,
    marginRight: 36,
  },
  dialogHeader: {
    color: white,
    border: 'none',
  },
  dialogButton: {
    width: 404,
    textTransform: 'none',
  },
}));

export const Header = observer((props) => {
  const classes = useStyles(props);
  const {
    uiStore: {
      userPersonalInfo,
      userPermissions,
    },
    ruleTypesStore: {
      sendFeedback,
    },
    notificationStore: {
      addSuccess,
    },
  } = useStores();

  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [isSubmitFeedbackDisabled, setIsSubmitFeedbackDisabled] = useState(true);
  const openFeedbackDialog = useCallback(() => setIsFeedbackDialogOpen(true), [setIsFeedbackDialogOpen]);
  const closeFeedbackDialog = useCallback(() => setIsFeedbackDialogOpen(false), [setIsFeedbackDialogOpen]);

  const handleOnApplyFeedbackRequest = async () => {
    setIsSubmitFeedbackDisabled(true);
    const res = await sendFeedback();
    if (res.status === 200) {
      closeFeedbackDialog();
      addSuccess('Feedback has been successfully submitted. Thank you!');
    } else {
      setIsSubmitFeedbackDisabled(false);
    }
  };

  return (
    <div className={classes.header} data-testid="headerWrapper">
      <div className={classes.appSection}>
        <SvgIcon name="nike-logo" classes={{ svgIconRoot: classes.logo }} />
        <div>
          Rule Engine
        </div>
      </div>

      <div className={classes.userPanel}>
        <Button
          type={buttonTypes.PRIMARY}
          label="Leave feedback"
          classes={{ button: classes.leaveFeedbackButton }}
          onClick={openFeedbackDialog}
          isRounded
          height={40}
        />
        <UserRole userData={userPersonalInfo} roles={userPermissions} />
        <UserInfo userData={userPersonalInfo} />
      </div>

      <Dialog
        isOpen={isFeedbackDialogOpen}
        header="a"
        applyLabel="Submit"
        onApply={handleOnApplyFeedbackRequest}
        isRulePage
        isApplyDisabled={isSubmitFeedbackDisabled}
        onCrossCancel={closeFeedbackDialog}
        classes={{
          button: classes.dialogButton,
          header: classes.dialogHeader,
        }}
        withSwoosh
        withCross
        isReversed
      >
        <FeedbackForm setIsSubmitFeedbackDisabled={setIsSubmitFeedbackDisabled} />
      </Dialog>
    </div>
  );
});

Header.propTypes = {
  classes: object,
};

Header.defaultProps = {
  classes: {},
};
