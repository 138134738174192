import React, { Fragment, useEffect, useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Table } from '../../components/table';
import { ActivateRuleTypeVersionDialog } from '../../components/activate-rule-types-version';
import { useStores } from '../../custom-hooks/store/use-stores';
import { getColumnSize } from '../../utils/columnSetting';
import { HEADER_ROW_FIELDS, ROW_FIELDS } from '../../constants/ruleTypes';
import { BASED_ON_VERSION, RULE_TYPE_NAME, VERSION } from '../../constants/common';
import { RuleTypesTableHeading } from './table-heading';
import { RuleFilters } from '../../components/rule-filters';
import { Dialog } from '../../components/dialog';
import { FilterCheckbox } from '../../components/filter-checkbox/FilterCheckbox';
import { FilterLabel } from '../../components/filter-label';
import * as themeColors from '../../styles/colors';
import { SvgIcon } from '../../components/svg-icon';
import { Button, buttonTypes } from '../../components/button';

import '../../assets/icons/eye.svg';

const useStyles = makeStyles(({ mixins }) => ({
  ruleTypesWrapper: {
    ...mixins.flexColumnGreedy,
  },
  secondaryFiltersContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fixedWrapper: {
    position: 'sticky',
    width: '100%',
    maxWidth: 1595,
    paddingTop: 20,
    background: themeColors.white,
    zIndex: 10,
  },
  filtersAndResetContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  resetFiltersContainer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 46,
    marginTop: 30,
  },
  dialogBody: {
    maxWidth: 400,
  },
  dialogHeader: {
    position: 'static',
    borderBottom: 'none',
  },
  dialogSwoosh: {
    marginTop: 50,
  },
  dialogHeaderContent: {
    maxWidth: 400,
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
  },
  dialogButtons: {
    flexDirection: 'row',
  },
  dialogButton: {
    width: 192,
    height: 40,
  },
  dialogCross: {
    position: 'absolute',
    top: 29,
    right: 29,
  },
  addNewRuleTypeButton: {
    height: 40,
    position: 'absolute',
    top: 18,
    right: 10,
    width: 200,
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Helvetica Neue',
  },
  infoContainer: {
    color: '#666666',
  },
  textDivider: {
    marginBottom: 30,
  },
  textAndInputDivider: {
    marginBottom: 24,
  },
  strongWords: {
    fontWeight: 500,
    color: themeColors.black,
  },
  textField: {
    '& input': {
      fontWeight: 700,
      fontSize: 14,
    },
  },
}));

export const RuleTypes = observer(() => {
  const classes = useStyles();
  const {
    ruleTypesStore: {
      isDataLoading,
      getRuleTypes,
      ruleTypes,
      ruleTypesCount,
      pageIndex,
      pagesCount,
      selectedItems,
      toggleItem,
      deselectAllItems,
      sorting,
      onSortApply,
      activateRuleType,
      selectedRuleTypes,
      isDraftOnly,
      allRuleTypes,
      allRuleTypesNames,
      filterForDraftOnly,
      resetFiltersState,
      getRuleTypesWithViewAll,
      updateAllVersionsRuleTypeState,
      unselectItems,
      versionsDropdown,
      archiveRule,
      paramsObject,
      removeDraft,
    },
    uiStore: {
      userPermissions,
    },
  } = useStores();

  const [isViewVersionsActive, setIsViewVersionsActive] = useState(false);
  const [isDeleteDraftActive, setIsDeleteDraftActive] = useState(false);
  const history = useHistory();
  const isRulePage = false;

  useEffect(() => {
    const getRuleTypesAsync = async () => {
      await getRuleTypes({
        anchor: pageIndex,
        params: paramsObject,
      });
    };
    getRuleTypesAsync();
  }, [getRuleTypes, pageIndex, paramsObject]);

  const dropdownOptions = useMemo(() => {
    let optionsObj = {};
    if (allRuleTypesNames) {
      optionsObj = { names: [...new Set(allRuleTypesNames)], isFirst: false };
    }
    if (selectedRuleTypes.length === 1) {
      let versions = [];
      if (allRuleTypes.length >= 1) {
        versions = allRuleTypes.filter(ruleType => ruleType.data.ruleTypeName === selectedRuleTypes[0]).map((el) => el.data[VERSION]);
        updateAllVersionsRuleTypeState(versions);
      } else {
        versions = versionsDropdown;
      }
      if (paramsObject.draftOnly) {
        versions = versions.filter((el) => el.includes('Draft'));
      }
      optionsObj = { ...optionsObj, versions: versions };
    }
    return optionsObj;
  }, [allRuleTypes, allRuleTypesNames, selectedRuleTypes.length, selectedRuleTypes && selectedRuleTypes.length, updateAllVersionsRuleTypeState, paramsObject.draftOnly]);

  const onRowClick = useCallback((path, ruleTypeName, ruleTypeVersion, basedOnVersion, isActive) => {
    let version = null;
    let isDraft = false;
    if (ruleTypeVersion.includes('Draft')) {
      version = 'Draft';
      isDraft = true;
    } else {
      version = ruleTypeVersion.match(/\d/g).join('');
    }
    history.push({
      pathname: path,
      search: '?' + new URLSearchParams({ ruleTypeName: ruleTypeName, ruleTypeVersion: version, basedOnVersion: basedOnVersion, isDraft: isDraft, isActive: isActive }),
    });
  }, [history]);

  const onAddNewRuleTypeButtonClick = useCallback(() => {
    history.push('/mpruleengineui/add');
  }, [history]);

  const onCheckboxClick = useCallback((rowData) => {
    toggleItem(rowData);
  }, [toggleItem]);

  const applySorting = useCallback((sortField, sortOrder) => {
    onSortApply(sortField, sortOrder);
  }, [onSortApply]);

  const [isActivationDialogOpen, setIsActivationDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const withIcon = true;
  const isUnderline = true;
  const openArchiveDialog = useCallback(() => setIsArchiveDialogOpen(true), [setIsArchiveDialogOpen]);
  const closeArchiveDialog = useCallback(() => { setIsArchiveDialogOpen(false); unselectItems(); }, [setIsArchiveDialogOpen, unselectItems]);
  const openActivationDialog = useCallback(() => setIsActivationDialogOpen(true), [setIsActivationDialogOpen]);
  const closeActivationDialog = useCallback(() => { setIsActivationDialogOpen(false); unselectItems(); }, [setIsActivationDialogOpen, unselectItems]);
  const closeOnCross = useCallback(() => setIsActivationDialogOpen(false), [setIsActivationDialogOpen]);
  const diselectAllItems = useCallback(() => deselectAllItems(), [deselectAllItems]);
  const openDeleteDialog = useCallback(() => setIsDeleteDraftActive(true), [setIsDeleteDraftActive]);
  const closeDeleteDialog = useCallback(() => setIsDeleteDraftActive(false), [setIsDeleteDraftActive]);

  const selectedItemName = selectedItems[0] && selectedItems[0].data[RULE_TYPE_NAME];
  const selectedItemVersion = selectedItems[0] && selectedItems[0].data[VERSION];
  const selectedItemBasedOnVersion = selectedItems[0] && selectedItems[0].data[BASED_ON_VERSION].match(/\d/g);

  const activateVersion = useCallback((name, version) => {
    activateRuleType({
      ruleType: name,
      versionNumber: version.match(/\d/g).join(''),
    });
  }, [activateRuleType]);

  const paginateForth = async () => {
    await getRuleTypes({
      anchor: pageIndex + 1,
      params: paramsObject,
    });
  };
  const paginateBack = async () => {
    await getRuleTypes({
      anchor: pageIndex - 1,
      params: paramsObject,
    });
  };

  const archiveRuletype = () => {
    openArchiveDialog();
  };

  const getCorrectVersionDisplay = (version) => {
    if (version) {
      if (version.match(/\d/g) === null) {
        return version;
      }
      return version.match(/\d/g).join('');
    }
  };

  return (
    <Fragment>
      <div className={classes.ruleTypesWrapper}>
        <div className={classes.fixedWrapper}>
          <div className={classes.filtersAndResetContainer}>
            <RuleFilters isDataLoading={isDataLoading} options={dropdownOptions} />
            <div className={classes.resetFiltersContainer}>
              <SvgIcon width={20} height={20} name="refresh" onClick={resetFiltersState} />
              <FilterLabel
                id="resetFilterLinkId"
                withIcon={withIcon}
                className={classes.resetFilters}
                label="Reset filters"
                onClick={resetFiltersState}
                isUnderline={isUnderline}
              />
            </div>
          </div>
          <div className={classes.secondaryFiltersContainer}>
            <FilterCheckbox id="draftOnlyCheckboxId" text="Draft only" checked={isDraftOnly} onCheck={filterForDraftOnly} />
          </div>
          {
            userPermissions && userPermissions.includes('METADATA_EDITOR') ? (
              <Button
                type={buttonTypes.PRIMARY}
                label="ADD NEW RULETYPE"
                classes={{ button: classes.addNewRuleTypeButton }}
                onClick={onAddNewRuleTypeButtonClick}
                height={40}
              />
            ) : null
          }
        </div>
        <RuleTypesTableHeading
          isDataLoading={isDataLoading}
          isReadOnly={false}
          itemsCount={ruleTypesCount}
          selectedItemsCount={selectedItems.length}
          pagesCount={pagesCount}
          pageIndex={pageIndex}
          onActivate={selectedItems && selectedItems.length > 0 && selectedItems[0].data.version.includes('Draft') ? openDeleteDialog : openActivationDialog}
          onCancel={diselectAllItems}
          onArchive={archiveRuletype}
          onNextPageClick={paginateForth}
          onPrevPageClick={paginateBack}
          isUnderline={false}
          activateLabel={selectedItems && selectedItems.length > 0 && selectedItems[0].data.version.includes('Draft') ? 'Remove' : 'Activate'}
          getRuleTypesWithViewAll={getRuleTypesWithViewAll}
          setIsViewVersionsActive={setIsViewVersionsActive}
          isViewVersionsActive={isViewVersionsActive}
          isSelectedItemVersionActive={selectedItemVersion && selectedItemVersion.includes('active')}
          icon={paramsObject.viewAll ? <SvgIcon width={20} height={20} name="eye" /> : <SvgIcon width={20} height={20} name="hide" />}
          isUserEditor={userPermissions && userPermissions.includes('EDITOR')}
          isSelectedDraft={selectedItems && selectedItems.length > 0 && selectedItems[0].data.version.includes('Draft')}
        />
        <Table
          withCheckbox={userPermissions && userPermissions.includes('EDITOR')}
          isEditMode={false}
          isDataLoading={isDataLoading}
          rowFields={ROW_FIELDS}
          headerRowFields={HEADER_ROW_FIELDS}
          items={ruleTypes || []}
          originalItemsCount={ruleTypesCount}
          columnsSizing={HEADER_ROW_FIELDS.reduce(getColumnSize, '')}
          classes={{ tableRoot: classes.table }}
          onCheckboxClick={onCheckboxClick}
          onRowClick={onRowClick}
          sorting={sorting}
          onSortApply={applySorting}
          isRulePage={isRulePage}
        />
      </div>
      <ActivateRuleTypeVersionDialog
        isDialogOpen={isActivationDialogOpen}
        onActivate={activateVersion}
        closeDialog={closeActivationDialog}
        ruleTypeName={selectedItemName}
        ruleTypeVersion={selectedItemVersion}
        activateLabel="ACTIVATE"
        cancelLabel="CANCEL"
        onCrossCancel={closeOnCross}
        header={`Activate “${selectedItemName}” version ${getCorrectVersionDisplay(selectedItemVersion)}?`}
        correctActivationWord="activate"
        withCross
      >
        <div className={classes.textField}>
          <div className={classes.infoContainer}>
            <p className={classes.textDivider}>
              If you <span className={classes.strongWords}>activate</span> “{selectedItemName}” <span className={classes.strongWords}>version {getCorrectVersionDisplay(selectedItemVersion)}</span> the previous version of this ruletype will be inactivated.
            </p>
            <p className={classes.textDivider}>
              Please note that the version activation may take up to <br /> <span className={classes.strongWords}>2 minutes</span>.
            </p>
            <p className={classes.textAndInputDivider}>
              To confirm, please type ‘activate’.
            </p>
          </div>
        </div>
      </ActivateRuleTypeVersionDialog>
      <ActivateRuleTypeVersionDialog
        isDialogOpen={isArchiveDialogOpen}
        onActivate={archiveRule}
        closeDialog={closeArchiveDialog}
        ruleTypeName={selectedItemName}
        ruleTypeVersion={getCorrectVersionDisplay(selectedItemVersion)}
        onCrossCancel={closeArchiveDialog}
        activateLabel="ARCHIVE"
        cancelLabel="CANCEL"
        header={`Archive “${selectedItemName}” version ${getCorrectVersionDisplay(selectedItemVersion)}?`}
        correctActivationWord="archive"
        withCross
      >
        <div className={classes.textField}>
          <div className={classes.infoContainer}>
            <p className={classes.textDivider}>
              After <span className={classes.strongWords}>archivation</span> “{selectedItemName}” <span className={classes.strongWords}>version {getCorrectVersionDisplay(selectedItemVersion)}</span> will not be available for viewing or editing
            </p>
            <p className={classes.textAndInputDivider}>
              To confirm, please type ‘archive’.
            </p>
          </div>
        </div>
      </ActivateRuleTypeVersionDialog>
      <Dialog
        isOpen={isDeleteDraftActive}
        header={`Delete draft ${selectedItems && selectedItems.length > 0 ? selectedItems[0].data.ruleTypeName : ''} based on version ${selectedItemBasedOnVersion && selectedItemBasedOnVersion.join('')}?`}
        applyLabel="DELETE"
        cancelLabel="CANCEL"
        onApply={() => {
          removeDraft({ ruleType: selectedItemName, versionNumber: selectedItems[0].data.basedOn });
          closeDeleteDialog();
        }}
        isRulePage
        onCancel={closeDeleteDialog}
        onCrossCancel={closeDeleteDialog}
        classes={{
          button: classes.dialogButton,
          buttons: classes.dialogButtons,
          body: classes.dialogBody,
          header: classes.dialogHeader,
          headerContent: classes.dialogHeaderContent,
          swoosh: classes.dialogSwoosh,
          cross: classes.dialogCross,
        }}
        withSwoosh
        withCross
        isReversed
      >
        <div>
          Removed draft can&apos;t be restored. All information from this draft will be lost.
        </div>
      </Dialog>
    </Fragment>
  );
});

RuleTypes.propTypes = {};

RuleTypes.defaultProps = {};
