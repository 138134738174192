import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { blackOpacity5 } from '../../styles/colors';

const useStyles = makeStyles(() => ({
  symbolsCounterContainer: {
    fontSize: 12,
    fontWeight: 500,
    color: blackOpacity5,
  },
}));

export const SymbolsCounter = ({ maxCount, currentSymbolsCount }) => {
  const classes = useStyles();
  return (
    <p className={classes.symbolsCounterContainer}>{currentSymbolsCount}/{maxCount}</p>
  );
};
