import React, { memo } from 'react';
import { object, oneOf, shape, string } from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { getUserColor } from '../../utils/user';
import { SIZES_ENUM } from '../../constants/ui';

const useStyles = makeStyles(({ colors }) => ({
  userInfo: {
    backgroundColor: ({ email }) => email && getUserColor(email) || colors.darkGray,
    display: 'flex',
    borderRadius: 50,
    justifyContent: 'center',
    textAlign: 'center',
    userSelect: 'none',
    color: colors.white,
  },
  big: {
    width: 50,
    height: 50,
    lineHeight: '50px',
    fontSize: 22,
  },
  mid: {
    lineHeight: '40px',
    fontSize: 18,
    width: 40,
    height: 40,
  },
  small: {
    lineHeight: '25px',
    fontSize: 14,
    width: 25,
    height: 25,
  },
}));

export const UserInfo = memo((props) => {
  const classes = useStyles(props);

  const { userData: { firstName = '', lastName = '', email = '' } = {}, size, style = {} } = props;
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  return (
    <div
      className={cx(classes.userInfo, classes[size])}
      style={{ backgroundColor: getUserColor(email), ...style }}
    >
      <span>{initials || 'N/A'}</span>
    </div>
  );
});

UserInfo.propTypes = {
  userData: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
  }),
  size: oneOf(Object.values(SIZES_ENUM)),
  style: object,
};

UserInfo.defaultProps = {
  size: SIZES_ENUM.mid,
  style: {},
};
