import React, { memo, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, number, func } from 'prop-types';
import cx from 'classnames';

import { PREV, NEXT } from '../../constants/common';
import { paginationPrevTestId, paginationNextTestId } from '../../test-data/dataTestIds';

const useStyles = makeStyles(({ mixins, colors }) => ({
  paginator: {
    ...mixins.flexCenterCenter,
  },
  paginationArrows: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  arrowBox: {
    width: 5,
    marginRight: 2,
    background: 'none',
    cursor: 'pointer',
    appearance: 'none',

    '&[disabled]': {
      cursor: 'default',

      '& > *': {
        borderColor: colors.darkGray,
      },
    },
  },
  arrow: {
    width: 6,
    height: 6,
    borderLeft: `2px solid ${colors.black}`,
    borderTop: `2px solid ${colors.black}`,
    transform: 'rotate(45deg)',
  },
  [`arrow${PREV}`]: {
    transform: 'rotate(-45deg)',
  },
  [`arrow${NEXT}`]: {
    transform: 'rotate(135deg)',
  },
}));

export const Paginator = memo((props) => {
  const classes = useStyles(props);
  const {
    isDataLoading,
    itemsCount,
    pagesCount,
    currentPageIndex,
    minIndex,
    maxIndex,
    onPrevPageClick,
    onNextPageClick,
    id,
  } = props;

  if (isDataLoading) {
    return <Fragment />;
  }

  const renderText = () => `${itemsCount === 0 ? minIndex : minIndex + 1}-${itemsCount === 0 ? maxIndex : maxIndex + 1} of ${itemsCount}`;
  const renderButtons = () => (
    <div className={classes.paginationArrows}>
      {[PREV, NEXT].map(renderButton)}
    </div>
  );
  const paginationHandlers = { onPrevPageClick, onNextPageClick };
  const paginationTestIds = { paginationPrevTestId, paginationNextTestId };
  const renderButton = direction => (
    <button
      type="button"
      data-testid={paginationTestIds[`pagination${direction}TestId`]}
      key={`pagination-button-${direction}`}
      onClick={paginationHandlers[`on${direction}PageClick`]}
      className={classes.arrowBox}
      disabled={direction === PREV ? currentPageIndex === 0 : (currentPageIndex >= pagesCount - 1 || itemsCount === 0)}
    >
      <div className={cx(classes.arrow, classes[`arrow${direction}`])} />
    </button>
  );

  return (
    <div id={id} className={classes.paginator}>
      {renderText()}
      {renderButtons()}
    </div>
  );
});

Paginator.displayName = 'Paginator';

Paginator.propTypes = {
  isDataLoading: bool,
  pagesCount: number,
  currentPageIndex: number,
  minIndex: number,
  maxIndex: number,
  onPrevPageClick: func.isRequired,
  onNextPageClick: func.isRequired,
};

Paginator.defaultProps = {
  isDataLoading: false,
  pagesCount: 1,
  currentPageIndex: 0,
  minIndex: 0,
  maxIndex: 0,
};
