// const MOCK_USER_DATA = {
//   email: 'Test.User@nike.com',
//   firstname: 'Test',
//   lastname: 'User',
//   username: 'TUser',
//   roles: ['VIEWER', 'EDITOR', 'METADATA_EDITOR'],
//   permissions: [
//     {
//       resource: 'RULE_TYPES_SCREEN',
//       policies: [
//         'WRITE',
//         'READ',
//       ],
//       metadata: {
//         allowedUserActivityActionTypes: [],
//       },
//     },
//     {
//       resource: 'ADD_NEW_VERSION_SCREEN',
//       policies: [
//         'WRITE',
//         'READ',
//       ],
//       metadata: {
//         allowedUserActivityActionTypes: [],
//       },
//     },
//     {
//       resource: 'RULE_LINE_SCREEN',
//       policies: [
//         'WRITE',
//         'READ',
//       ],
//       metadata: {
//         allowedUserActivityActionTypes: [],
//       },
//     },
//     {
//       resource: 'ADD_RULE_TYPE_SCREEN',
//       policies: [
//         'WRITE',
//         'READ',
//       ],
//       metadata: {
//         allowedUserActivityActionTypes: [],
//       },
//     },
//   ],
// };
// eslint-disable-next-line import/no-named-as-default,import/extensions

export class UserService {
  constructor(adapter, { api: { ruleTypes: { path } } }) {
    this._adapter = adapter;
    this._userDataPath = path;
  }

  // eslint-disable-next-line no-unused-vars
  getUserData = (params = {}) => {
    return this._adapter.getUserDataFromToken();
    // return MOCK_USER_DATA;
  };
}
