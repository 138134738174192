import { makeStyles } from '@material-ui/core/styles';

export const useGlobalStyle = makeStyles(({ fontFamilies, fontSizes, mixins }) => ({
  '@global': {
    '*': {
      outline: 'none',
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      width: '100%',
      fontFamily: fontFamilies.secondary,
      height: '100vh',
      overflowY: 'hidden',
      fontWeight: '400',
      overscrollBehaviorX: 'none',
      fontSize: fontSizes.md,
    },
    '#app': {
      ...mixins.flexColumn,
      width: '100%',
      height: '100%',
      minWidth: 1280,
    },

    // we have to specify material ui components as those, who reacts to dark mode
    '.MuiDrawer-paper, .MuiDialog-paper, .MuiPopover-paper': {
      filter: ({ darkMode }) => darkMode && 'invert(92%)' || 'none',
    },
  },
}));
