import React, { memo, Fragment, useState, useEffect } from 'react';
import { bool, number, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import cx from 'classnames';

import { Label } from '../../../components/label';
import { Counter, COUNTER_MODES } from '../../../components/counter';
import { Button, buttonTypes } from '../../../components/button';
import { Paginator } from '../../../components/paginator';
import { AddNewLine } from '../../../components/add-new-line';
import { RuleLineControlLabel } from '../../../components/rule-line-control-label';
import {
  selectedItemsCounter,
  totalItemsCounterTestId,
  enableRulesBtnTestId,
  disableRulesBtnTestId,
} from '../../../test-data/dataTestIds';
import { getFromToIndexes } from '../../../utils/pagination';
import { RT_ITEMS_PER_PAGE } from '../../../constants/ruleLines';

const useStyles = makeStyles(({ mixins, colors, spaces, fontSizes }) => ({
  tableHeading: {
    ...mixins.tableHeadingWrapper,
    display: 'flex',
    justifyContent: 'space-between',
  },
  countersAndActions: {
    ...mixins.flexRowStartCenter,
  },
  draftPageContainer: {
    ...mixins.flexRowStartCenter,
  },
  tableHeadingCounters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
  },
  itemsCounter: {
    marginLeft: spaces.lg,
    color: colors.darkestGray,
    fontSize: fontSizes.md,
  },
  selectionCounter: {
    marginLeft: spaces.lg,
    fontSize: fontSizes.md,
    width: 111,
  },
  activation: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    marginRight: 50,
    padding: '10px 24px',
    marginLeft: 25,
  },
  filterAndPagiantorContainer: {
    ...mixins.flexRow,
  },
  noCheckboxesTableHeading: {
    paddingLeft: 0,
  },
  paginator: {
  },
}));

export const RuleLineTableHeading = memo((props) => {
  const {
    isDataLoading,
    itemsCount,
    isDraftExists,
    isDraft,
    selectedItemsCount,
    selectedItems,
    removedRuleLineItemsCount,
    addedLinesCount,
    pagesCount,
    pageIndex,
    openAddDialog,
    onNextPageClick,
    onPrevPageClick,
    onSaveAsNewClick,
    onRemoveClick,
    onDuplicateClick,
    url,
    createDraftUrl,
    isUpdated,
    isUserEditor,
    createDraft,
    ruleTypeName,
    ruleTypeVersion,
    hasModifications,
    params,
    setFiltersData,
    isInvalidFieldsPresent,
  } = props;

  const classes = useStyles(props);
  const history = useHistory();
  const [disabledState, setDisabledState] = useState(true);
  const { from: minIndex, to: maxIndex } = getFromToIndexes(pageIndex, RT_ITEMS_PER_PAGE, itemsCount);
  const redirectToDraft = (isCreateDraft) => {
    if (isCreateDraft) {
      history.push(createDraftUrl);
    } else {
      history.push(url);
    }
  };

  const createUrlForDraft = () => {
    const draftUrl = '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: ruleTypeName, ruleTypeVersion: 'Draft', isDraft: true, basedOnVersion: ruleTypeVersion });
    return draftUrl;
  };

  const handleOnClickCreateDraft = async () => {
    const result = await createDraft(ruleTypeName, ruleTypeVersion).then(({ status }) => status);
    if (result === 200) {
      delete params.filter;
      setFiltersData([]);
      history.push(createUrlForDraft());
    }
  };

  useEffect(() => {
    let state = true;
    if (hasModifications) {
      state = false;
    }
    if (removedRuleLineItemsCount > 0 || addedLinesCount > 0 || isUpdated) {
      state = false;
    }
    if (isInvalidFieldsPresent) {
      state = true;
    }
    setDisabledState(state);
  }, [isInvalidFieldsPresent, hasModifications, removedRuleLineItemsCount, addedLinesCount, isUpdated]);

  return (
    <div className={isDraftExists ? cx(classes.tableHeading, classes.noCheckboxesTableHeading) : classes.tableHeading}>
      <div className={isDraft ? classes.draftPageContainer : classes.countersAndActions}>
        <div className={classes.tableHeadingCounters}>
          <Label text="Results" size="lg" />
          <Counter
            dataTestId={totalItemsCounterTestId}
            count={itemsCount}
            classes={{ selectionCounter: classes.itemsCounter }}
          />
          {!isDraftExists && isUserEditor ? (
            <Counter
              dataTestId={selectedItemsCounter}
              count={selectedItemsCount}
              mode={COUNTER_MODES.SELECTED}
              classes={{ selectionCounter: classes.selectionCounter }}
            />
          ) : null}
          {isDraft && isUserEditor ? <AddNewLine id="addNewLineRulePageId" isDisabled={selectedItemsCount > 0} onClick={openAddDialog} /> : null}
          {selectedItemsCount > 0 && isUserEditor ? (
            <Fragment>
              <RuleLineControlLabel id="removeRulePageId" iconName="trash" text="Remove" onClick={() => onRemoveClick(selectedItems)} />
              <RuleLineControlLabel id="duplicateRulePageId" iconName="duplicate" text="Duplicate" onClick={() => onDuplicateClick(selectedItems)} />
            </Fragment>
          )
            : null}
        </div>

        {isUserEditor && isDraftExists && !isDraft ? (
          <Button
            type={buttonTypes.PRIMARY}
            isRounded
            dataTestId={enableRulesBtnTestId}
            label="Edit draft"
            classes={{ button: classes.button }}
            onClick={() => redirectToDraft(false)}
            height={32}
          />
        ) : null}
        {isUserEditor && !isDraftExists && !isDraft ? (
          <Button
            type={buttonTypes.PRIMARY}
            isRounded
            dataTestId={enableRulesBtnTestId}
            label="Create draft"
            classes={{ button: classes.button }}
            onClick={handleOnClickCreateDraft}
            height={32}
          />
        ) : null}
      </div>
      <div className={classes.filterAndPagiantorContainer}>
        {isDraft ? (
          <div className={classes.activation}>
            {isUserEditor ? (
              <Button
                type={buttonTypes.PRIMARY}
                isRounded
                disabled={disabledState}
                dataTestId={disableRulesBtnTestId}
                label="Save as new version"
                classes={{ button: classes.button }}
                onClick={onSaveAsNewClick}
                height={32}
              />
            ) : null}
          </div>
        ) : null}
        <Paginator
          id="rulePagePaginatorId"
          className={classes.paginator}
          itemsCount={itemsCount}
          pagesCount={pagesCount}
          currentPageIndex={pageIndex}
          minIndex={itemsCount === 0 ? 0 : minIndex}
          maxIndex={itemsCount === 0 ? 0 : maxIndex}
          onPrevPageClick={onPrevPageClick}
          onNextPageClick={onNextPageClick}
          isDataLoading={isDataLoading}
        />
      </div>
    </div>
  );
});

RuleLineTableHeading.propTypes = {
  isDataLoading: bool,
  isReadOnly: bool,
  itemsCount: number,
  selectedItemsCount: number,
  pagesCount: number,
  pageIndex: number,
  onActivate: func,
  onCancel: func,
  onNextPageClick: func,
  onPrevPageClick: func,
};

RuleLineTableHeading.defaultProps = {
  isDataLoading: false,
  itemsCount: 0,
  selectedItemsCount: 0,
  pagesCount: 0,
  pageIndex: 0,
};
