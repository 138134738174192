import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, func, string, number } from 'prop-types';

import { Dialog } from '../dialog';
import { TextInput } from '../text-input/TextInput';

const useStyles = makeStyles(({ fontSizes }) => ({
  buttonWrapper: {
    display: 'flex',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',
  },
  button: {
    flexGrow: 1,
    height: 40,
    fontFamily: 'Helvetica, Neue Helvetica, Arial, sans-serif',
    fontSize: fontSizes.lg,
  },
  dialogBody: {
    width: 400,
  },
}));

export const ActivateRuleTypeVersionDialog = (props) => {
  const classes = useStyles(props);
  const {
    isDialogOpen,
    onActivate,
    closeDialog,
    ruleTypeName,
    activateLabel,
    cancelLabel,
    ruleTypeVersion,
    onCrossCancel,
    children,
    header,
    correctActivationWord,
  } = props;

  const onApplyClick = useCallback((name, version) => {
    onActivate(name, version);
    closeDialog();
  }, []);

  const [activateWord, setActivateWord] = useState('');

  return (
    <Dialog
      isOpen={isDialogOpen}
      header={header}
      applyLabel={activateLabel}
      cancelLabel={cancelLabel}
      onApply={onApplyClick}
      onCancel={closeDialog}
      onCrossCancel={onCrossCancel}
      ruleTypeName={ruleTypeName}
      ruleTypeVersion={ruleTypeVersion}
      activateWord={activateWord}
      setActivateWord={setActivateWord}
      correctActivationWord={correctActivationWord}
      classes={{
        buttonWrapper: classes.buttonWrapper,
        button: classes.button,
        buttons: classes.buttons,
        body: classes.dialogBody,
      }}
      withSwoosh
      withCross
    >
      {children}
      <TextInput focus value={activateWord} onChange={setActivateWord} classes={classes.textField} />
    </Dialog>
  );
};

ActivateRuleTypeVersionDialog.displayName = 'ActivateRuleTypeVersionDialog';

ActivateRuleTypeVersionDialog.propTypes = {
  isDialogOpen: bool.isRequired,
  onActivate: func.isRequired,
  closeDialog: func.isRequired,
  ruleTypeName: string,
  ruleTypeVersion: number,
};

ActivateRuleTypeVersionDialog.defaultProps = {};
