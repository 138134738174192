import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { object, func, node, oneOfType, arrayOf, string } from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { SvgIcon } from '../svg-icon';
import { Button, buttonTypes } from '../button';

const useStyles = makeStyles(({ colors, fontSizes, fontFamilies, boxShadows, mixins }) => ({
  filterRoot: {
    ...mixins.flexRowStartCenter,
    flexWrap: 'nowrap',
    cursor: ({ readOnly }) => (readOnly ? 'initial' : 'pointer'),
    position: ({ readOnly }) => (readOnly ? 'static' : 'relative'),
    transition: '.2s',
    overflow: 'hidden',
    paddingLeft: 0,
  },
  trigger: {},
  panel: {
    ...mixins.flexColumn,
    padding: 10,
    minWidth: 245,
    backgroundColor: colors.white,
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, .16)',
    zIndex: 1,
    fontSize: fontSizes.md,
  },
  header: {
    ...mixins.flexSpaceBetween,
    fontFamily: fontFamilies.primary,
    textTransform: 'uppercase',
    marginBottom: 20,
  },

  title: {
    fontSize: 18,
    lineHeight: '18px',
    letterSpacing: -0.41,
    color: colors.black,
  },
  closeIcon: {
    width: 16,
    height: 16,
    fill: colors.darkGray,
    cursor: 'pointer',
  },
  applyButton: {
    width: '100%',
    height: 30,
    padding: 0,
    marginTop: 20,
  },
  paper: {
    borderRadius: 0,
    boxShadow: boxShadows.commonShadow,
  },
}));

const placementVertical = 'top';
const placementHorizontal = 'left';

const anchorOrigin = {
  vertical: placementVertical,
  horizontal: placementHorizontal,
};

const transformOrigin = {
  vertical: placementVertical,
  horizontal: placementHorizontal,
};

export const FilterFrame = (props) => {
  const { triggerComponent: Trigger, triggerComponentOptions, label, children, onApply, onClose } = props;
  const classes = useStyles(props);
  const triggerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const eventHandlers = {
    onOpen: () => setIsOpen(true),
    onClose: () => {
      setIsOpen(false);
      onClose && onClose();
    },
    onApply: () => {
      onApply && onApply();
      setIsOpen(false);
    },
  };

  return (
    <div
      data-testid="filterFrame"
      className={classes.filterRoot}
    >
      <div
        data-testid="filterFrameTriggerWrapper"
        className={classes.trigger}
        ref={triggerRef}
        onClick={eventHandlers.onOpen}
      >
        <Trigger {...triggerComponentOptions} />
      </div>
      <Popover
        open={isOpen}
        anchorEl={triggerRef.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{
          paper: classes.paper,
        }}
        onClose={eventHandlers.onClose}
      >
        <div
          className={classes.panel}
          data-testid="filters-wrapper"
        >
          <div className={classes.header}>
            <div className={classes.title}>{label}</div>
            <SvgIcon
              name="close"
              classes={{ svgIconRoot: classes.closeIcon }}
              onClick={eventHandlers.onClose}
            />
          </div>
          {children}
          <Button
            type={buttonTypes.PRIMARY}
            classes={{ button: classes.applyButton }}
            onClick={eventHandlers.onApply}
            label="Apply"
          />
        </div>
      </Popover>
    </div>
  );
};

FilterFrame.propTypes = {
  label: string,
  triggerComponentOptions: object,
  triggerComponent: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  onApply: func.isRequired,
  onClose: func.isRequired,
};

FilterFrame.defaultProps = {
  label: '',
  triggerComponentOptions: {},
};
