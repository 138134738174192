import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { func, string } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SvgIcon } from '../svg-icon';

import {
  TYPE_ERROR,
  IN_PROGRESS_STATUS,
  styleSetting,
  TYPE_SUCCESS,
  TYPE_WARNING,
} from '../../constants/notification';

const useStyles = makeStyles(({ colors, fontFamilies, mixins, fontSizes }) => ({
  snackBar: {
    ...mixins.flexColumnStart,
    width: 420,
    minHeight: 70,
    fontFamily: fontFamilies.secondary,
    position: 'relative',
    color: colors.black,
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: colors.white,
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.16)',
    padding: '10px 10px 10px 16px',
    marginLeft: 'auto',
    '&:before': {
      content: '""',
      display: 'block',
      height: '100%',
      width: 6,
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: ({ type }) => type && styleSetting[type].color,
    },
  },
  head: {
    ...mixins.flexRowBetweenCenter,
    width: '100%',
    marginBottom: 6,
    fontSize: 18,
  },
  message: {
    width: 365,
    lineHeight: '18px',
    fontSize: 14,
  },
  heading: {
    fontSize: fontSizes.lg,
    lineHeight: '22px',
  },
  closer: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    alignSelf: 'flex-start',
    fill: colors.darkGray,
  },
  generalInfo: {
    ...mixins.flexRowStartCenter,
    color: ({ type }) => type && styleSetting[type].color,
  },
  icon: {
    ...mixins.flexCenterCenter,
    fill: colors.white,
    width: 20,
    height: 20,
    borderRadius: 100,
    marginRight: 6,
    backgroundColor: ({ type }) => type && styleSetting[type].color,
  },
  spinner: {
    marginRight: 10,
    color: colors.blue,
  },
  warningMessageLink: {
    fontSize: 40,
  },
}));

export const Snackbar = forwardRef((props, ref) => {
  const classes = useStyles(props);
  const {
    type,
    message = '',
    onClose,
  } = props;

  const titles = {
    [TYPE_ERROR]: 'Something Went Wrong',
    [TYPE_SUCCESS]: 'Success',
    [TYPE_WARNING]: 'Warning!',
  };

  const title = titles[type] || 'Success';

  const onCloseHandler = () => onClose && onClose();

  return (
    <div ref={ref} className={classes.snackBar}>
      <div className={classes.head}>
        <div className={classes.generalInfo}>
          {type === IN_PROGRESS_STATUS
            ? (
              <CircularProgress size={20} thickness={7} classes={{ root: classes.spinner }} />
            ) : (
              <SvgIcon
                name={styleSetting[type].iconName}
                classes={{ svgIconRoot: classes.icon }}
              />
            )}
          <div className={classes.heading}>{title}</div>
        </div>
        <SvgIcon
          onClick={onCloseHandler}
          name="close"
          classes={{ svgIconRoot: classes.closer }}
        />
      </div>
      <div className={classes.message}>
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  );
});

Snackbar.propTypes = {
  customTitle: string,
  message: string,
  type: string.isRequired,
  onClose: func.isRequired,
  contentRenderer: func,
};
