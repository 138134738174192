import { observable, action, runInAction, computed } from 'mobx';
import arraySort from 'array-sort';
import services from '../../services';
import { RuleLineItemStore } from './RuleLineItemStore';
import { PageStore } from '../abstractStores/PageStore';

const { ruleLineService } = services;

export class RuleLineStore extends PageStore {

  constructor() {
    super();
    this.sorting.sortableFields = [];
  }

  @observable ruleLines = null;

  @observable ruleLineRowsHeadings = [];

  @observable ruleLinesCount = 0;

  @observable isDraftExists = false;

  @observable pageIndex = 0;

  @observable pagesCount = 0;

  @observable removedRuleLineItems = [];

  @observable addedLines = [];

  @observable finalizedVersion = null;

  @observable changedRowsIds = [];

  @observable countOfLinesWithMetaTypeDraft = 0;

  @observable isUpdated = false;

  @observable isInvalidFieldsPresent = false;

  @observable editCell = {
    rowId: null,
    fieldName: null,
  };

  @observable invalidFields = [];

  @observable params = {
    count: 50,
  };

  @observable ruleHeadings = {};

  @observable jsonData = null;

  @action.bound
  async getRuleLine(anchor, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params = {}, isSort = false) {
    this.isDataLoading = true;

    let result = true;
    try {
      const ruleLines = await ruleLineService.getRuleLines(anchor, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params);
      const ruleRowsHeadings = await ruleLineService.getRuleLineRowHeadings(ruleTypeName);
      runInAction(() => {
        if (!isSort) {
          if (ruleRowsHeadings.fields) {
            this.ruleHeadings = ruleRowsHeadings;
          } else {
            const inParamsArr = ruleRowsHeadings.requestFields.map((el) => {
              return {
                name: el.name,
                type: el.type,
                inOut: 'IN',
                required: el.required,
                enumValues: el.enumValues,
              };
            });
            const outParamsArr = ruleRowsHeadings.responseFields.map((el) => {
              return {
                name: el.name,
                type: el.type,
                inOut: 'OUT',
                required: el.required,
                enumValues: el.enumValues,
              };
            });
            this.ruleHeadings.fields = [...inParamsArr, ...outParamsArr];
          }
        }
        this.ruleHeadings.fields = arraySort(this.ruleHeadings.fields, 'name');
        this.ruleHeadings.fields = arraySort(this.ruleHeadings.fields, 'inOut');
        const columnSize = 1 / this.ruleHeadings.fields.length;
        if (ruleTypeVersion !== 'Draft') {
          this.isDraftExists = ruleLines.draftExists;
        } else {
          this.isDraftExists = false;
        }
        this.ruleLineRowsHeadings = this.ruleHeadings.fields.map((el) => {
          const isSkip = el.skip;
          return {
            field: el.name,
            label: el.name,
            columnSize: `minmax(200px, ${columnSize}fr)`,
            inOrOut: el.inOut,
            required: el.required,
            type: el.type,
            values: el.enumValues ? el.enumValues : el.name,
            skip: isSkip,
          };
        });
        const names = this.ruleLineRowsHeadings.map((el) => el.field);
        this.sorting.sortableFields = [...names];
        this.ruleLines = ruleLines.items.map((el) => {
          const isEdited = el.meta.action && el.meta.action === 'UPSERT';
          const isRemoved = el.meta.action && el.meta.action === 'DELETE';
          const isDraftLine = el.meta.type && el.meta.type === 'DRAFT';
          return new RuleLineItemStore({ item: el, fields: names, id: el.meta.id, isRemoved: isRemoved, isEdited: isEdited, isDraftLine: isDraftLine });
        });
        this.ruleLinesCount = ruleLines.pages.totalResources;
        this.pagesCount = ruleLines.pages.totalPages;
        this.pageIndex = Math.ceil(anchor / 50);
      });
    } catch (err) {
      result = false;
      console.error(err);
    }

    runInAction(() => {
      this.isDataLoading = false;
    });

    return result;
  }

  @action.bound
  async addDraftLine(ruleTypeName, ruleTypeVersion, user, data) {
    const addedRuleId = await ruleLineService.addDraftLine(ruleTypeName, ruleTypeVersion, user, data);
    runInAction(() => {
      this.addedLines.push(addedRuleId.data.ruleId);
      try {
        localStorage.setItem('addedLines', JSON.stringify(this.addedLines));
      } catch (e) {
        console.error(e);
      }
    });
    return addedRuleId;
  }

  @action.bound
  async uploadFile(ruleTypeName, file, setProgress) {
    const formData = new FormData();
    formData.append('file', file);
    const res = await ruleLineService.uploadFile(ruleTypeName, formData, setProgress);
    return res;
  }

  @action.bound
  deleteDraftLine(ruleTypeName, ruleTypeVersion, user, ruleIds) {
    const actions = [];
    ruleIds.forEach((el) => {
      this.removedRuleLineItems.push(el);
      actions.push({
        user: user,
        action: 'DELETE',
        ruleId: el.id,
      });
    });
    const response = ruleLineService.deleteDraftLine(ruleTypeName, ruleTypeVersion, actions);
    return response;
  }

  @action.bound
  removeDraftLine(ruleTypeName, ruleTypeVersion, ruleIds) {
    const response = ruleLineService.removeDraftLine(ruleTypeName, ruleTypeVersion, {
      ruleId: [
        ...ruleIds,
      ],
    });
    return response;
  }

  @action.bound
  async finalizeDraft(ruleTypeName, ruleTypeVersion) {
    const response = await ruleLineService.finalizeDraft(ruleTypeName, ruleTypeVersion);
    let isSavedAsNew = true;
    let errMessage = '';
    runInAction(() => {
      this.finalizedVersion = response.data.version;
      this.removedRuleLineItems = [];
      if (response.status === 200) {
        this.isUpdated = false;
      }
      if (response.status === 500) {
        isSavedAsNew = false;
      }
      if (response.status === 400) {
        isSavedAsNew = false;
        errMessage = response.data.errors[0].code;
      }
    });
    return {
      newVersion: this.finalizedVersion,
      isSaved: isSavedAsNew,
      message: errMessage,
    };
  }

  @action.bound
  revertSelectionStateForAllItems() {
    this.ruleLines.forEach(ruleType => ruleType.setSelectionState(!ruleType.isSelected));
  }

  @action.bound
  clearTable() {
    this.ruleLines = [];
  }

  @action.bound
  clearAddRuleLines() {
    this.addedLines = [];
  }

  @action.bound
  clearCountOfDraftLines() {
    this.countOfLinesWithMetaTypeDraft = 0;
  }

  @action.bound
  onSortApply(sortBy, sortOrder, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft) {
    this.sorting.sortOrder = sortOrder;
    this.sorting.sortBy = sortBy;
    if (sortOrder === 'NONE') {
      delete this.params.sort;
    } else {
      this.params.sort = this.sorting.sortBy + this.sorting.sortOrder;
    }
    this.getRuleLine((this.pageIndex * 50), ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, this.params);
  }

  @action.bound
  clearPageIndexes() {
    this.pageIndex = 0;
    this.pagesCount = 0;
  }

  @action.bound
  setEditModeById(rowId, fieldName) {
    this.editCell.fieldName = fieldName;
    this.editCell.rowId = rowId;
    const ruleLine = this.ruleLines.find(item => item.id === rowId);
    ruleLine.isEdited = true;
  }

  @action.bound
  cancelEditModeById(rowId) {
    const ruleLine = this.ruleLines.find(item => item.id === rowId);
    ruleLine.isEditMode = false;
    ruleLine.isEdited = false;
    this.editCell.rowId = null;
    this.editCell.fieldName = null;
  }

  @action.bound
  changeValueByFieldName(fieldName, value, id, isInvalid) {
    const ruleLine = this.ruleLines.find(item => item.id === id);
    if (isInvalid) {
      this.invalidFields.push(fieldName);
      this.isInvalidFieldsPresent = true;
      ruleLine.isChanged = false;
      ruleLine.isInvalid = true;
      ruleLine.changedFields[fieldName] = value;
      ruleLine.mergeChanges();
    } else {
      if (this.invalidFields.includes(fieldName)) {
        const index = this.invalidFields.indexOf(fieldName);
        this.invalidFields.splice(index, 1);
      }
      ruleLine.changedFields[fieldName] = value;
      ruleLine.mergeChanges();
      ruleLine.isChanged = true;
      if (this.invalidFields.length === 0) {
        this.isInvalidFieldsPresent = false;
        ruleLine.isInvalid = false;
      }
      this.changedRowsIds.push(id);
    }
  }

  @action.bound
  clearEditCell() {
    this.editCell.rowId = null;
    this.editCell.fieldName = null;
    this.ruleLines.forEach((el) => el.isEdited = false);
  }

  @action.bound
  async updateLine(ruleTypeName, ruleTypeVersion, ruleId, data) {
    if (this.invalidFields.length === 0) {
      const rowData = data;
      this.ruleLineRowsHeadings.forEach((el) => {
        if (rowData[el.field] === undefined) {
          rowData[el.field] = '';
        }
      });
      const response = await ruleLineService.updateDraftLine(ruleTypeName, ruleTypeVersion, ruleId, rowData);
      runInAction(() => {
        if (response.status === 200) {
          this.editCell.fieldName = null;
          this.editCell.rowId = null;
          this.changedRowsIds = [];
          this.isUpdated = true;
          this.ruleLines.forEach((el) => el.isEdited = false);
        }
      });
      return response;
    }
    return { status: 400 };
  }

  @action.bound
  toggleItem({ id }) {
    const ruleLine = this.ruleLines.find(item => item.id === id);
    const prevItemState = ruleLine.isSelected;
    ruleLine.setSelectionState(!prevItemState);
  }

  @action.bound
  removeItem(items) {
    items.forEach((el) => {
      const ruleLine = this.ruleLines.find(item => item.id === el.id);
      ruleLine.isRemoved = true;
      ruleLine.setSelectionState(false);
    });
  }

  @action.bound
  async hasModifications(ruleTypeName, ruleTypeVersion) {
    const result = await ruleLineService.hasModifications(ruleTypeName, ruleTypeVersion);
    return result;
  }

  @action.bound
  async getUrlFromYaml(ruleTypeName) {
    const result = await ruleLineService.getUrlFromYaml(ruleTypeName);
    return result;
  }


  @action.bound
  async downloadFile(ruleTypeName, ruleTypeVersion, basedOnVersion) {
    await ruleLineService.downloadFile(ruleTypeName, ruleTypeVersion, basedOnVersion);
  }

  @action.bound
  async downloadYamlFile(ruleTypeName) {
    await ruleLineService.downloadYamlFile(ruleTypeName);
  }

  @action.bound
  async createDraft(ruleTypeName, ruleTypeVersion) {
    const result = await ruleLineService.createDraft(ruleTypeName, ruleTypeVersion);
    return result;
  }

  @action.bound
  async getJsonData(ruleTypeName, ruleTypeVersion, basedOnVersion) {
    const result = await ruleLineService.getJsonDataForRuleType(ruleTypeName, ruleTypeVersion, basedOnVersion);
    runInAction(() => {
      let formattedString = '';
      if (this.ruleLinesCount > 10000) {
        formattedString = 'Can\'t display this file because of large count of lines. Please download rule file to see json';
      } else {
        formattedString = result.split ? result.split('\n').filter((el) => el) : result;
      }
      this.jsonData = formattedString;
    });
  }

  @action.bound
  clearJsonData() {
    this.jsonData = null;
  }

  @computed
  get selectedItems() {
    return this.ruleLines ? this.ruleLines.filter(el => el.isSelected) : [];
  }
}
