import { action, observable } from 'mobx';

const SETTING_STORE = 'settingStore';

export class SettingStore {
  constructor() {
    this.initializeFromLocalStorage();
  }

  @observable settingStore = {};

  @action.bound initializeFromLocalStorage() {
    const storeJSON = localStorage.getItem(SETTING_STORE);

    if (storeJSON && storeJSON.length) {
      this.settingStore = JSON.parse(storeJSON);
    }
  }

  @action.bound updateLocalStorage() {
    setTimeout(() => {
      localStorage.setItem(SETTING_STORE, JSON.stringify(this.settingStore));
    }, 0);
  }

  @action.bound setSetting(key, setting) {
    this.settingStore = { ...this.settingStore, [key]: setting };
    this.updateLocalStorage();
  }

  @action.bound getSetting(key) {
    return this.settingStore[key];
  }
}
