import React from 'react';
import { observer } from 'mobx-react';

import { TableRow } from '../table-row';

export const VirtualTableRow = observer(({ data, index, style }) => {
  const {
    isEmpty,
    withCheckbox,
    minValue,
    maxValue,
    items,
    highlightedItems,
    rowFields,
    rowTooltips,
    tooltipEditingBehavior,
    columnsSizing,
    classes,
    onCheckboxClick,
    onRowClick,
    onCellClick,
    isRulePage,
    onFieldChange,
    isDraftExists,
    editCell,
    onKeyDown,
    headerRowFields,
    addError,
    invalidFields,
    isDraft,
    cancelEditModeById,
    lastItem,
  } = data;

  const { rowItem, isSelected, isActive, isRemoved, isEdited, isInvalid, isChanged, isPending, editableFields, fieldsAlignedRight, highlightedFields, changeField, id } = items[index];
  const addedLinesFromLocalStorage = localStorage.getItem('addedLines');
  return (
    <div id={id} key={id} style={style}>
      <TableRow
        withCheckbox={withCheckbox}
        isSelected={isSelected}
        isRemoved={isRemoved}
        isAdded={addedLinesFromLocalStorage && addedLinesFromLocalStorage.includes(id)}
        isEditMode={editCell && editCell.rowId && id === editCell.rowId}
        editCell={editCell}
        isHighlighted={highlightedItems.includes(items[index])}
        isActive={isActive}
        isEdited={isEdited && (invalidFields && invalidFields.length === 0)}
        isChanged={isChanged && (invalidFields && invalidFields.length === 0)}
        isInvalid={isInvalid}
        index={index}
        minValue={minValue}
        maxValue={maxValue}
        id={id || rowItem.ID}
        item={rowItem}
        headerRowFields={headerRowFields}
        tooltipEditingBehavior={tooltipEditingBehavior}
        fieldPlaceholder={isEmpty ? '' : undefined}
        editableFields={editableFields}
        fieldsAlignedRight={fieldsAlignedRight}
        highlightedFields={highlightedFields}
        rowTooltips={rowTooltips}
        rowFields={rowFields}
        columnsSizing={columnsSizing}
        classes={classes}
        onCheckboxClick={onCheckboxClick}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        onFieldChange={onFieldChange || changeField}
        isRulePage={isRulePage}
        isDraftExists={isDraftExists}
        onKeyDown={onKeyDown}
        addError={addError}
        invalidFields={invalidFields}
        isDraft={isDraft}
        cancelEditModeById={cancelEditModeById}
        lastItem={lastItem}
        uniqIdForHtml={isRulePage ? 'RulePage' + index : 'HomePage' + index}
        isPending={isPending}
      />
    </div>
  );
});
