import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router';
import { useAuth, AegisClient } from '@nike/aegis-auth-react';
import { makeStyles } from '@material-ui/core/styles';
import { RuleTypes } from '../containers/ruletypes';
import { AddNewVersion } from '../containers/add-new-version';
import { RuleLine } from '../containers/rule-line';
import { AddRuleType } from '../containers/add-rule-type/AddRuleType';
import { PageLayout } from './page-layout';
import { Label } from './label';

import {
  RULE_TYPES_SCREEN,
  ADD_NEW_VERSION_SCREEN,
  RULE_LINE_SCREEN,
  ADD_RULE_TYPE_SCREEN,
} from '../constants/ui';

import { useGlobalStyle } from '../styles/globals';

import { useStores } from '../custom-hooks/store/use-stores';

const useStyles = makeStyles((mixins) => ({
  label: {
    ...mixins.flexRowStartCenter,
    userSelect: 'none',
    alignSelf: 'center',
    height: '100%',
  },
}));

export const App = observer(() => {
  const oAuthClient = AegisClient;
  const classes = useStyles();
  const { loginError } = useAuth(oAuthClient);
  const {
    uiStore: {
      getUserData,
      routes,
      isLoading,
    },
  } = useStores();
  useGlobalStyle();

  useEffect(() => {
    getUserData();
  }, []);

  const components = {
    [RULE_TYPES_SCREEN]: RuleTypes,
    [ADD_NEW_VERSION_SCREEN]: AddNewVersion,
    [RULE_LINE_SCREEN]: RuleLine,
    [ADD_RULE_TYPE_SCREEN]: AddRuleType,
  };

  const renderPlaceholder = () => {
    // This condition could be simplified, but so it is more clear what is going on
    let message = routes && !routes.length ? 'No permissions found' : '';
    if (loginError) {
      message = 'User is not logged in';
    } else if (isLoading) {
      message = 'User data is loading...';
    }
    if (isLoading) {
      message = 'User data is loading...';
    }

    return <Label classes={{ labelRoot: classes.label }} text={message} size="xl" />;
  };

  return (
    <PageLayout>
      <Switch>
        {routes && !!routes.length && routes.map((routeConfig) => {
          const { path, resource } = routeConfig;
          const Component = components[resource];
          return (
            <Route
              path={path}
              key={resource}
            >
              <Component routeConfig={routeConfig} />
            </Route>
          );
        }) || renderPlaceholder()}

        {routes && !!routes.length && <Redirect to={routes[0].path} />}
      </Switch>
    </PageLayout>
  );
});
