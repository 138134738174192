import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { string, bool, object, func } from 'prop-types';
import cx from 'classnames';

import { SvgIcon } from '../svg-icon';
import { CustomTooltip } from '../custom-tooltip';

import '../../assets/icons/download.svg';
import '../../assets/icons/download-file.svg';
import '../../assets/icons/upload-file.svg';
import '../../assets/icons/refresh.svg';
import '../../assets/icons/hide.svg';

const useStyles = makeStyles(({ mixins, colors, fontFamilies, fontSizes }) => ({
  linkButton: {
    ...mixins.transition,
    fontFamily: fontFamilies.secondary,
    fontSize: fontSizes.sm,
    color: ({ disabled }) => (disabled ? colors.darkGray : colors.blue),
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    pointerEvents: ({ disabled }) => (disabled && 'none'),
  },
  loadingButton: {
    pointerEvents: 'none',
  },
  icon: {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    fill: colors.blue,
    margin: '0 5px',
  },
  disabledIcon: {
    fill: colors.darkGray,
  },
  spinner: {
    margin: '0 6px',
    color: colors.blue,
  },
  childrenClass: {
    marginLeft: 'auto',
  },
}));

export const LinkButton = memo((props) => {
  const classes = useStyles(props);
  const {
    isLoading,
    disabled,
    isHintDisabled,
    label,
    dataTestId,
    iconName,
    placement,
    title,
    onClick,
    onMouseDown,
  } = props;

  return (
    <CustomTooltip
      isHintDisabled={isHintDisabled}
      placement={placement}
      title={title}
      classes={{ childrenClass: classes.childrenClass }}
    >
      <div
        data-testid={dataTestId}
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={cx(classes.linkButton, { [classes.loadingButton]: isLoading })}
      >
        {!isLoading && iconName && <SvgIcon name={iconName} classes={{ svgIconRoot: cx(classes.icon, { [classes.disabledIcon]: disabled }) }} />}
        {isLoading && (<CircularProgress disableShrink size={14} thickness={5} classes={{ root: classes.spinner }} />)}
        {label}
      </div>
    </CustomTooltip>
  );
});

LinkButton.propTypes = {
  disabled: bool,
  isLoading: bool,
  isHintDisabled: bool,
  title: string,
  placement: string,
  iconName: string,
  label: string.isRequired,
  dataTestId: string,
  classes: object,
  onClick: func.isRequired,
  onMouseDown: func,
};

LinkButton.defaultProps = {
  disabled: false,
  isLoading: false,
  dataTestId: '',
  classes: {},
};
