import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, object, array, string, func, shape, arrayOf, oneOf } from 'prop-types';
import cx from 'classnames';

import { SortFilterField } from '../sort-filter-field';
import { ASC, DESC, NONE } from '../../constants/common';
import { Checkbox } from '../checkbox';

const useStyles = makeStyles(({ colors, borders, mixins, fontSizes }) => ({
  headerRow: {
    pointerEvents: ({ disabled }) => disabled && 'none',
    display: 'flex',
    minHeight: 37,
    width: '100%',
  },
  rowItems: {
    display: 'grid',
    gridTemplateColumns: ({ columnsSizing }) => columnsSizing,
    cursor: 'pointer',
    gridColumnGap: 2,
    flex: 1,
  },
  field: {
    ...mixins.flexRowStartCenter,
    background: colors.blueOpacity1,
    overflow: 'hidden',
    position: 'relative',
    fontSize: fontSizes.md,
  },
  fieldOut: {
    background: colors.whiteOrange,
  },
  textField: {
    background: colors.white,
    textAlign: 'right',
    height: '100%',
    border: borders.blue,
  },
  stickyField: {
    position: 'sticky',
    left: 20,
    zIndex: 2,
    ...mixins.flexRowStartCenter,
    background: '#edf4fc',
    overflow: 'hidden',
    fontSize: fontSizes.md,
  },
  stickyFieldWithDraftExists: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    ...mixins.flexRowStartCenter,
    background: '#edf4fc',
    overflow: 'hidden',
    fontSize: fontSizes.md,
  },
  checkbox: {
    width: 22,
    position: 'sticky',
    top: 0,
    left: 0,
    background: colors.white,
    zIndex: 1,
  },
  checkboxInput: {},
}));

export const HeaderRow = observer((props) => {
  const classes = useStyles(props);
  const {
    rowFields,
    filtersData,
    filtering: { appliedFilters, currentlySelectedFilters },
    sorting: {
      sortableFields,
      sortBy,
      sortOrder,
    },
    onFiltersApply,
    onSetCurrentFilters,
    onSortApply,
    isSelected,
    setIsSelected,
    onCheckboxClick,
    isRulePage,
    isDraftExists,
    isDraft,
    isUserEditor,
  } = props;

  const handleOnCheck = () => {
    onCheckboxClick();
    setIsSelected(!isSelected);
  };

  const renderField = ({ field, label, inOrOut }) => {
    const sortOrderValue = sortableFields.includes(field)
      ? sortBy === field ? sortOrder : NONE
      : null;
    return (
      <div
        key={field}
        className={inOrOut ? inOrOut === 'IN' ? cx(field === 'ID' ? isDraftExists ? classes.stickyFieldWithDraftExists : classes.stickyField : classes.field) : cx(classes.field, classes.fieldOut) : classes.field}
        title={label}
      >
        <SortFilterField
          label={label}
          fieldKey={field}
          filterOptions={filtersData[field]}
          currentlySelectedFilters={currentlySelectedFilters[field]}
          selectedFilters={appliedFilters[field]}
          sortOrder={sortOrderValue}
          onFiltersApply={onFiltersApply}
          onSetCurrentFilters={onSetCurrentFilters}
          onSortApply={onSortApply}
          isRulePage={isRulePage}
        />
      </div>
    );
  };

  return (
    <div className={classes.headerRow}>
      {isRulePage && isDraft && isUserEditor ? (
        <Checkbox
          checked={isSelected}
          classes={{
            checkbox: classes.checkbox,
            input: classes.checkboxInput,
          }}
          onCheck={handleOnCheck}
        />
      ) : null}
      <div className={classes.rowItems}>
        {rowFields.map(renderField)}
      </div>
    </div>
  );
});

HeaderRow.propTypes = {
  isSelected: bool,
  withCheckbox: bool,
  columnsSizing: string.isRequired,
  classes: object,
  filtersData: object,
  sorting: shape({
    sortableFields: arrayOf(string).isRequired,
    sortBy: string.isRequired,
    sortOrder: oneOf([NONE, ASC, DESC]).isRequired,
  }),
  filtering: shape({
    appliedFilters: object.isRequired,
    currentlySelectedFilters: object.isRequired,
  }),
  rowFields: array,
  onFiltersApply: func,
  onSortApply: func,
};

HeaderRow.defaultProps = {
  isSelected: false,
  withCheckbox: false,
  rowFields: [],
  classes: {},
  filtersData: {},
  sorting: {
    sortableFields: [],
    sortBy: '',
    sortOrder: NONE,
  },
  filtering: {
    appliedFilters: {},
    currentlySelectedFilters: {},
  },
};
