export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';

export const SIZES_ENUM = {
  big: 'big',
  mid: 'mid',
  small: 'small',
};

export const DEFAULT_VIEW = 'default-view';
export const RULE_TYPES_SCREEN = 'RULE_TYPES_SCREEN';
export const ADD_NEW_VERSION_SCREEN = 'ADD_NEW_VERSION_SCREEN';
export const RULE_LINE_SCREEN = 'RULE_LINE_SCREEN';
export const ADD_RULE_TYPE_SCREEN = 'ADD_RULE_TYPE_SCREEN';
