import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { string, bool, object, oneOf, number, func, oneOfType } from 'prop-types';
import cx from 'classnames';

import { CustomTooltip } from '../custom-tooltip';
import { PRIMARY, SECONDARY } from '../../constants/ui';

export const buttonTypes = {
  PRIMARY,
  SECONDARY,
};

const useStyles = makeStyles(({ mixins, colors, fontFamilies, fontSizes, borders, spaces }) => ({
  buttonWrapper: {},
  button: {
    ...mixins.flexCenterCenter,
    width: ({ width }) => width || 140,
    height: 30,
    position: 'relative',
    fontFamily: fontFamilies.primary,
    fontWeight: 'bold',
    boxSizing: 'border-box',
    transition: '0.2s',
    borderRadius: 1,
    backgroundClip: 'padding-box !important',
    textTransform: 'uppercase',
    userSelect: 'none',
    lineHeight: '20px',
    pointerEvents: ({ disabled, isLoading }) => ((disabled || isLoading) && 'none' || 'auto'),
    border: ({ disabled, isLoading }) => {
      if (disabled || isLoading) {
        return borders.midblack;
      }
      return borders.black;
    },
    background: ({ disabled, type, isLoading }) => {
      if (disabled || isLoading) {
        return type === buttonTypes.PRIMARY ? colors.blackOpacity5 : colors.white;
      }
      return type === buttonTypes.PRIMARY ? colors.black : colors.white;
    },
    color: ({ disabled, type, isLoading }) => {
      if (disabled || isLoading) {
        return type === buttonTypes.PRIMARY ? colors.white : colors.blackOpacity5;
      }
      return type === buttonTypes.PRIMARY ? colors.white : colors.black;
    },
    '&:hover': {
      border: ({ disabled, isLoading }) => {
        if ((disabled || isLoading)) {
          return colors.midblack;
        }
        return borders.black;
      },
      background: ({ disabled, type, isLoading }) => {
        if ((disabled || isLoading)) {
          return colors.blackOpacity5;
        }
        return type === buttonTypes.PRIMARY ? colors.white : colors.black;
      },
      color: ({ disabled, type, isLoading }) => {
        if ((disabled || isLoading)) {
          return colors.darkGray;
        }
        return type === buttonTypes.PRIMARY ? colors.black : colors.white;
      },
    },
  },
  primary: {},
  secondary: {},
  roundedButton: {
    width: ({ width }) => width || 'unset',
    height: ({ height }) => height || 22,
    ...mixins.transition,
    fontFamily: fontFamilies.secondary,
    fontSize: fontSizes.sm,
    textTransform: 'none',
    borderRadius: ({ height }) => {
      return height ? height / 2 : 11;
    },
    padding: `0 ${spaces.md}`,
  },
  loadingButton: {
    padding: '0 20px',
  },
}));

export const Button = memo((props) => {
  const classes = useStyles(props);
  const {
    disabled,
    isLoading,
    isRounded,
    isTooltipInteractive,
    isHintDisabled,
    label,
    hint,
    dataTestId,
    tooltipPosition,
    type,
    onClick,
    onMouseDown,
    id,
  } = props;

  const onClickHandler = () => {
    if (disabled) return;

    onClick && onClick();
  };

  const onMouseDownHandler = () => {
    if (disabled) return;

    onMouseDown && onMouseDown();
  };

  return (
    <CustomTooltip
      interactive={isTooltipInteractive}
      isHintDisabled={isHintDisabled}
      placement={tooltipPosition}
      title={hint}
      classes={{ childrenClass: classes.buttonWrapper }}
    >
      <button
        id={id}
        disabled={disabled || isLoading}
        data-testid={dataTestId}
        type="button"
        className={cx(classes.button,
          { [classes[type]]: type },
          { [classes.roundedButton]: isRounded },
          { [classes.loadingButton]: isLoading })}
        onClick={onClickHandler}
        onMouseDown={onMouseDownHandler}
      >
        {!isLoading && label}
        {isLoading && (<CircularProgress size={14} thickness={5} classes={{ root: classes.spinner }} />)}
      </button>
    </CustomTooltip>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  isRounded: bool,
  isLoading: bool,
  disabled: bool,
  isHintDisabled: bool,
  isTooltipInteractive: bool,
  dataTestId: string,
  label: string.isRequired,
  tooltipPosition: string,
  classes: object,
  hint: string,
  width: oneOfType([number, string]),
  type: oneOf([...Object.values(buttonTypes)]),
  onClick: oneOfType([func, bool]),
  onMouseDown: func,
};

Button.defaultProps = {
  isLoading: false,
  type: buttonTypes.SECONDARY,
  dataTestId: 'button',
  tooltipPosition: 'top',
  classes: {},
};
