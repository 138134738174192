import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, object, arrayOf, func, number, string, oneOfType } from 'prop-types';
import cx from 'classnames';

import { Checkbox } from '../checkbox';
import { TextInput } from '../text-input';
import { CustomTooltip } from '../custom-tooltip';
import { SvgIcon } from '../svg-icon';

import { INPUT_TYPES, ROW_HEIGHT } from '../../constants/common';
import { Dropdown } from '../dropdown';
import { invalidRedOpacity6 } from '../../styles/colors';

const useStyles = makeStyles(({ colors, mixins, spaces, fontSizes }) => ({
  tableRow: {
    pointerEvents: ({ disabled }) => disabled && 'none',
    padding: '1px 0',
    display: 'flex',
    fontSize: fontSizes.md,
    height: ROW_HEIGHT,
  },
  rowItems: {
    display: 'grid',
    gridTemplateColumns: ({ columnsSizing }) => columnsSizing,
    cursor: 'pointer',
    gridColumnGap: 2,
    flex: 1,

    '&:hover > *': {
      background: ({ isSelected }) => !isSelected && colors.blueOpacity05,
    },
  },
  field: {
    ...mixins.flexRowStartCenter,
    background: ({ isSelected, isHighlighted, isActive, isRemoved, isChanged, isAdded, isRulePage, isEdited, isInvalid, isPending, invalidFields, fieldName }) => {
      if (isHighlighted) {
        return 'none';
      }
      if (isInvalid && isRulePage) {
        return colors.invalidRedOpacity3;
      }
      if (isRulePage && (invalidFields && invalidFields.includes(fieldName))) {
        return colors.invalidRedOpacity6;
      }
      if (isSelected) {
        return colors.yellowOpacity1;
      }
      if (isPending) {
        return colors.whiteOrange;
      }
      if (isActive) {
        return colors.subtleGreen;
      }
      if (isRemoved && isRulePage) {
        return colors.removedGrey;
      }
      if (isAdded && isRulePage || isChanged && isRulePage || isEdited) {
        return colors.addedBlue;
      }
      return colors.grayBackground;
    },
    position: 'relative',
    color: colors.black,
  },
  removedField: {
    background: colors.removedGrey,
  },
  addedField: {
    background: colors.addedBlue,
  },
  fieldOut: {
    background: colors.whiteOrange,
  },
  highlightedField: {
    boxShadow: `inset 0px 0px 0px 1px ${colors.yellow}`,
  },
  invalidField: {
    background: colors.invalidRedOpacity6,
  },
  checkbox: {
    width: 22,
    position: 'sticky',
    top: 0,
    left: 0,
    background: colors.white,
    zIndex: 1,
  },
  fieldContent: {
    padding: '0 12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'max-content',
  },
  fieldContentRight: {
    marginLeft: 'auto',
  },
  stickyField: {
    position: 'sticky',
    left: 20,
    zIndex: 2,
  },
  stickyFieldWithDraftExists: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
  },
  textField: {
    background: colors.white,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 500,
    height: '100%',
    border: '1px solid #82A2F6',
  },
  checkboxInput: {},
  tooltipHolder: {
    alignSelf: 'stretch',
    width: '100%',
  },
  warningIcon: {
    display: 'block',
    fill: colors.yellow,
    width: 16,
    height: 16,
    margin: `0 ${spaces.sm} 0 auto`,
  },
  downloadIcon: {
    width: 50,
    height: 30,
    color: 'blue',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    width: '100%',
    marginTop: 2,
  },
  dropdownTableWrapper: {
    top: -222,
    left: 0,
    right: 0,
  },
}));

export const TableRow = observer((props) => {
  const classes = useStyles(props);
  const {
    isEditMode,
    isEdited,
    isSelected,
    isRemoved,
    isAdded,
    isInvalid,
    withCheckbox,
    index,
    id,
    minValue,
    maxValue,
    item,
    fieldsAlignedRight,
    highlightedFields,
    tooltipEditingBehavior,
    rowFields,
    rowTooltips,
    onRowClick,
    onCellClick,
    onCheckboxClick,
    onKeyDown,
    onFieldChange,
    isRulePage,
    isDraftExists,
    editCell = {},
    headerRowFields,
    addError,
    invalidFields,
    isDraft,
    cancelEditModeById,
    lastItem,
    uniqIdForHtml,
    isPending,
    isActive,
  } = props;

  const [targetText, setTargetText] = useState('');
  const eventHandlers = {
    onClick: (event) => {
      event.stopPropagation();
      onRowClick && onRowClick('/mpruleengineui/view', item.ruleTypeName, item.version, item.basedOn, isActive);
    },
    onCheck: (event) => {
      event.stopPropagation();
      onCheckboxClick && onCheckboxClick({ index, item, id });
    },
    onChange: (fieldName) => (value) => {
      const foundColumn = headerRowFields.find(el => el.field === fieldName);
      if (foundColumn.required && value.length === 0) {
        onFieldChange && onFieldChange(fieldName, value, id, true);
      } else {
        /* eslint-disable no-lonely-if */
        if (foundColumn.type === 'integer') {
          /* eslint-disable no-lonely-if */
          const filteredValue = value.match(/\d/g);
          const isValid = filteredValue ? filteredValue.length === value.length : false;
          if (isValid) {
            onFieldChange && onFieldChange(fieldName, value, id);
          } else {
            addError('Wrong value type');
          }
        } else {
          onFieldChange && onFieldChange(fieldName, value, id);
        }
      }
    },
    onKeyDown: (event) => {
      if (event === 'dropdown') {
        onKeyDown && onKeyDown(13);
      } else {
        onKeyDown && onKeyDown(event.keyCode);
      }
    },
    onBlur: (e, fieldName) => {
      if (targetText !== e.target.value) {
        const foundColumn = headerRowFields.find(el => el.field === fieldName);
        if (foundColumn.required && e.target.value.length === 0) {
          onFieldChange && onFieldChange(fieldName, e.target.value, id, true);
          addError('This parameter can\'t be empty');
        } else {
          /* eslint-disable no-lonely-if */
          if (foundColumn.type === 'integer') {
            /* eslint-disable no-lonely-if */
            const filteredValue = e.target.value.match(/\d/g);
            const isValid = filteredValue ? filteredValue.length === e.target.value.length : false;
            if (isValid) {
              onFieldChange && onFieldChange(fieldName, e.target.value, id);
              onKeyDown && onKeyDown(13);
            } else {
              addError('Wrong value type');
            }
          } else {
            onFieldChange && onFieldChange(fieldName, e.target.value, id);
            onKeyDown && onKeyDown(13);
          }
        }
        cancelEditModeById(id);
      }
    },
  };
  const renderers = {
    checkbox: () => {
      if (!withCheckbox || isRulePage && !isDraft) return null;

      return (
        <Checkbox
          checked={isSelected}
          classes={{
            checkbox: classes.checkbox,
            input: classes.checkboxInput,
          }}
          disabled={isPending}
          onCheck={eventHandlers.onCheck}
        />
      );
    },
    field: (fieldName, idx) => {
      if (isRulePage) {
        fieldName = fieldName.field;
      }
      const dropdownOnSelect = (value) => {
        eventHandlers.onChange(fieldName)(value);
        eventHandlers.onKeyDown('dropdown');
      };
      const tooltipMessage = `${rowTooltips[fieldName] ? rowTooltips[fieldName](item) : item[fieldName]}`;
      const isFieldHighlighted = highlightedFields.includes(fieldName);
      const isAlignedRight = fieldsAlignedRight && fieldsAlignedRight.includes(fieldName);
      return (
        <div
          id={`${uniqIdForHtml + idx + fieldName}`}
          key={`${uniqIdForHtml + idx + fieldName}`}
          onClick={(e) => {
            setTargetText(e.currentTarget.getElementsByTagName('div')[0].innerText);
            onCellClick(id, fieldName);
          }}
          style={(invalidFields && invalidFields.includes(fieldName)) && isInvalid ? { background: invalidRedOpacity6 } : null}
          className={cx({ [classes.highlightedField]: isFieldHighlighted, [classes.removedField]: isRemoved, [classes.addedField]: isAdded, [classes.isInvalidField]: isInvalid, [classes.isEditedField]: isEdited, [classes.pendingField]: isPending, [classes.fieldName]: fieldName }, classes.field, fieldName === 'ID' ? isDraftExists ? classes.stickyFieldWithDraftExists : classes.stickyField : null)}
        >
          {isEditMode && editCell.fieldName === fieldName ? (
            <CustomTooltip
              placement="top"
              classes={{ childrenClass: classes.tooltipHolder }}
              title={tooltipMessage}
              {...!!tooltipEditingBehavior[fieldName] && tooltipEditingBehavior[fieldName]}
            >
              {rowFields.find(el => el.field === fieldName).type === 'enum'
                ? (
                  <Dropdown
                    withSearch
                    disabled={rowFields.find(el => el.field === fieldName).values.length === 0}
                    placeholder={item[fieldName]}
                    classes={(index === (lastItem - 1)) && lastItem > 49 ? { wrapper: classes.dropdownTableWrapper } : classes}
                    selected={item[fieldName]}
                    options={rowFields.find(el => el.field === fieldName).values}
                    nameOrVersion="rulePage"
                    onSelect={dropdownOnSelect}
                  />
                )
                : (
                  <TextInput
                    autoFocus
                    key={id}
                    type={INPUT_TYPES.TEXT}
                    min={minValue}
                    max={maxValue}
                    name={fieldName}
                    value={item[fieldName]}
                    classes={{ textField: classes.textField }}
                    onChange={eventHandlers.onChange(fieldName)}
                    onKeyDown={(event) => eventHandlers.onKeyDown(event)}
                    onBlur={(event) => eventHandlers.onBlur(event, fieldName)}
                  />
                )}

            </CustomTooltip>
          ) : (
            <div
              title={tooltipMessage}
              className={cx({ [classes.fieldContentRight]: isAlignedRight }, classes.fieldContent)}
            >
              {item[fieldName] || item[fieldName] === 0 ? (typeof (item[fieldName]) === 'string' && item[fieldName].includes('Version') && fieldName === 'version') ? item[fieldName] : item[fieldName] : item[fieldName]}
            </div>
          )}
          {isFieldHighlighted && (
            <SvgIcon name="exclamation" classes={{ svgIconRoot: classes.warningIcon }} />
          )}
        </div>
      );
    },
  };

  return (
    <div
      data-testid="tableRowWrapper"
      className={classes.tableRow}
      onClick={eventHandlers.onClick}
    >
      {renderers.checkbox()}
      <div className={classes.rowItems}>
        {rowFields.map(renderers.field)}
      </div>

    </div>
  );
});

TableRow.propTypes = {
  disabled: bool,
  withCheckbox: bool,
  isEditMode: bool,
  isSelected: bool,
  isHighlighted: bool,
  isActive: bool,
  minValue: number,
  maxValue: number,
  index: number.isRequired,
  fieldPlaceholder: string,
  id: oneOfType([string, number]).isRequired,
  item: object.isRequired,
  rowTooltips: object,
  tooltipEditingBehavior: object,
  rowFields: arrayOf(string).isRequired,
  columnsSizing: string.isRequired,
  classes: object,
  onRowClick: func,
  onCheckboxClick: func,
  onFieldChange: func,
};

TableRow.defaultProps = {
  disabled: false,
  withCheckbox: false,
  isSelected: false,
  isHighlighted: false,
  isActive: false,
  isEditMode: false,
  fieldPlaceholder: 'N/A',
  rowTooltips: {},
  tooltipEditingBehavior: {},
  classes: {},
};
