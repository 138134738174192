/* eslint-disable */
import axios from 'axios';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import jwtDecode from 'jwt-decode';
import { errorStore } from '../../stores/errorStore/ErrorStore';
import globalHeaders from "../../config.js";
if (process.env.NODE_ENV !== 'development') {
  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
  };
}

export class ApiAdapter {
  constructor({ api: { url }, flexApi: { flexUrl } }) {
    console.log(process);
    console.log(process.env.NODE_ENV)
    console.log(window.location)
    console.log(process.env.REACT_APP_PATH)

    if (process.env.NODE_ENV == 'development' && window.location.origin.includes("dev")) {
      this._url = "https://rule-engine-dev.consumer-om-test.nikecloud.com/ruleengine/manage/v1/ui/";
      this._flexUrl = "https://rule-engine-dev.consumer-om-test.nikecloud.com/ruleengine/manage/v2/ui/";
    } if (process.env.NODE_ENV == 'development' && window.location.origin.includes("test")) {
      this._url = "https://rule-engine-test.consumer-om-test.nikecloud.com/ruleengine/manage/v1/ui/";
      this._flexUrl = "https://rule-engine-test.consumer-om-test.nikecloud.com/ruleengine/manage/v2/ui/";
    } else if(process.env.NODE_ENV === 'production') {
      this._url = "https://rule-engine-prod.consumer-om-prod.nikecloud.com/ruleengine/manage/v1/ui/";
      this._flexUrl = "https://rule-engine-prod.consumer-om-prod.nikecloud.com/ruleengine/manage/v2/ui/";
    }
  }

  catchError = (error) => errorStore.handleError(error);

  composeUrl = path => `${this._url}${path}`;
  composeFlexUrl = path => `${this._flexUrl}${path}`;
  getUserDataFromToken = headers => {
    const username = jwtDecode(globalHeaders["accessToken"]).sub;
    const groups = jwtDecode(globalHeaders["accessToken"]).groups;
    console.log(username);
    console.log(groups);
    const cid = jwtDecode(globalHeaders["accessToken"]).cid;
    console.log(cid);
    var obj = new Object();
    obj.email = username
    obj.firstname  = username.substring(0, username.indexOf('.'));
    obj.lastname = username.substring(username.indexOf('.') + 1, username.length);
    obj.groups = groups;
    obj.username = username;
    obj.roles = ["VIEWER","EDITOR","METADATA_EDITOR"];
    var jsonString= JSON.stringify(obj);
    console.log(jsonString);
    headers = []
    headers['x-current-user'] = jsonString;
    return headers };

  get = (req) => {
    const {path, params = {}, headers = {}, isFlex = false} = req;
    let endpoint = '';
    console.log(globalHeaders);
    console.log(globalHeaders["Authorization"]);
    headers["Authorization"] = globalHeaders["Authorization"];

    try {
      const username = jwtDecode(globalHeaders["accessToken"]).sub;
      const groups = jwtDecode(globalHeaders["accessToken"]).groups;
      console.log(username);
      console.log(groups);
      const cid = jwtDecode(globalHeaders["accessToken"]).cid;
      console.log(cid);
      var obj = new Object();
      obj.email = username
      obj.firstname  = username.substring(0, username.indexOf('.'));
      obj.lastname = username.substring(username.indexOf('.') + 1, username.length);
      obj.groups = groups;
      obj.username = username;
      var jsonString= JSON.stringify(obj);
      console.log(jsonString);
      headers['x-current-user'] = jsonString;
      console.log(this.parseJwt(globalHeaders["Authorization"]));
    } catch (error) {
      console.log(error);
    }

    headers["sensitive"] = true;
    if (isFlex) {
      endpoint = this.composeFlexUrl(path);
    } else {
      endpoint = this.composeUrl(path);
    }
    const request = axios.get(endpoint, {
      params,
      headers,
      paramsSerializer: function (params) {
        let queryStr = qs.stringify(params, { arrayFormat: 'comma' });
        while(queryStr.includes('%3D') || queryStr.includes('%26')) {
          queryStr = queryStr.replace('%26', '&').replace('%3D', '=');
        }
        return queryStr;
      },
    })
      .then(response => response)
      .catch(this.catchError);
    return request;
  };

  parseJwt (token) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  }

  post = (req) => {
    const { path, body = {}, params = {}, headers = {}, onUploadProgress, responseType = '', isFlex = false } = req;
    let endpoint = '';
    if (isFlex) {
      endpoint = this.composeFlexUrl(path);
    } else {
      endpoint = this.composeUrl(path);
    }
    headers["Authorization"] = globalHeaders["Authorization"];
    try {
      const username = jwtDecode(globalHeaders["accessToken"]).sub;
      const groups = jwtDecode(globalHeaders["accessToken"]).groups;
      console.log(username);
      console.log(groups);
      const cid = jwtDecode(globalHeaders["accessToken"]).cid;
      console.log(cid);
      var obj = new Object();
      obj.email = username
      obj.firstname  = username.substring(0, username.indexOf('.'));
      obj.lastname = username.substring(username.indexOf('.') + 1, username.length);
      obj.groups = groups;
      obj.username = username;

      var jsonString= JSON.stringify(obj);
      console.log(jsonString);
      headers['x-current-user'] = jsonString;
      console.log(this.parseJwt(globalHeaders["Authorization"]));
    } catch (error) {
      console.log(error);
    }
    const request = axios.post(endpoint, body, {
      params,
      headers: headers,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
      onUploadProgress: onUploadProgress,
    })
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  patch = (path, body = {}, params = {}) => {
    const endpoint = this.composeUrl(path);

    const config = isEmpty(params) ? {} : {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
    };

    const request = axios.patch(endpoint, body, config)
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  put = (path, body = {}) => {
    const endpoint = this.composeUrl(path);

    const request = axios.put(endpoint, body)
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  delete = (path, body = {}, params = {}) => {
    const endpoint = this.composeUrl(path);

    const headers = isEmpty(params) ? {} : {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
    };

    const config = {
      headers,
      data: body,
    }

    const request = axios.delete(endpoint, config)
      .then(response => response)
      .catch(this.catchError);

    return request;
  };
}
