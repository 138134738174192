import React, { memo } from 'react';
import { bool, func, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ colors, borders, mixins }) => ({
  checkbox: {
    ...mixins.flexRowStartCenter,
    lineHeight: '17px',
    color: colors.darkGray,
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  input: {
    height: '100%',
    margin: 0,
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    top: 0,
    left: 0,
    zIndex: 2,

    '&:checked + label::after': {
      width: 5,
      height: 8,
      position: 'absolute',
      content: '\'\'',
      top: 0,
      left: 5,
      transform: 'rotate(45deg)',
      boxShadow: `2px 2px 0px 0px ${colors.black}`,
    },
  },
  label: {
    ...mixins.flexRowStartCenter,
    color: colors.black,
    position: 'relative',
    width: '100%',
    height: 16,

    '&::before': {
      content: '\'\'',
      marginRight: 10,
      display: 'inline-block',
      verticalAlign: 'sub',
      width: 15,
      height: 15,
      flexShrink: 0,
      border: ({ disabled }) => {
        return disabled ? borders.grayOpacity : borders.black;
      },
    },
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export const Checkbox = memo((props) => {
  const classes = useStyles(props);

  const { checked, label, onCheck, disabled } = props;
  return (
    <div
      data-testid="checkboxWrapper"
      className={classes.checkbox}
      onClick={onCheck && !disabled ? onCheck : null}
    >
      <input
        className={classes.input}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        readOnly
      />
      <label className={classes.label}>
        <span className={classes.text}>{label}</span>
      </label>
    </div>
  );
});

Checkbox.propTypes = {
  checked: bool,
  label: string,
  onCheck: func,
  className: string,
};

Checkbox.defaultProps = {
  checked: false,
  label: '',
  className: '',
};
