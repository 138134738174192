import React, { memo } from 'react';
import { bool, number, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Label } from '../../../components/label';
import { Counter, COUNTER_MODES } from '../../../components/counter';
import { Divider, HORIZONTAL } from '../../../components/divider';
import { Button, buttonTypes } from '../../../components/button';
import { Paginator } from '../../../components/paginator';
import { FilterLabel } from '../../../components/filter-label';
import {
  selectedItemsCounter,
  totalItemsCounterTestId,
  enableRulesBtnTestId,
  disableRulesBtnTestId,
} from '../../../test-data/dataTestIds';
import { getFromToIndexes } from '../../../utils/pagination';
import { RT_ITEMS_PER_PAGE } from '../../../constants/ruleTypes';

const useStyles = makeStyles(({ mixins, colors, spaces, fontSizes }) => ({
  tableHeading: {
    ...mixins.tableHeadingWrapper,
    display: 'flex',
    justifyContent: 'space-between',
  },
  countersAndActions: {
    ...mixins.flexRowStartCenter,
  },
  tableHeadingCounters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 4,
  },
  itemsCounter: {
    marginLeft: spaces.lg,
    color: colors.darkestGray,
    fontSize: fontSizes.md,
  },
  selectionCounter: {
    marginLeft: spaces.lg,
    fontSize: fontSizes.md,
  },
  activation: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    marginRight: spaces.md,
    padding: '10px 24px',
  },
  filterAndPagiantorContainer: {
    ...mixins.flexRow,
  },
  paginator: {},
  archiveButton: {
    marginRight: spaces.md,
    padding: '10px 24px',
    background: '#F9F9F9',
    color: colors.black,
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
}));

export const RuleTypesTableHeading = memo((props) => {
  const {
    isDataLoading,
    isReadOnly,
    itemsCount,
    selectedItemsCount,
    pagesCount,
    pageIndex,
    onActivate,
    onCancel,
    onNextPageClick,
    onPrevPageClick,
    isUnderline,
    getRuleTypesWithViewAll,
    setIsViewVersionsActive,
    icon,
    activateLabel,
    isSelectedItemVersionActive,
    isViewVersionsActive,
    isUserEditor,
    isSelectedDraft,
    onArchive,
  } = props;
  const classes = useStyles(props);
  const { from: minIndex, to: maxIndex } = getFromToIndexes(pageIndex, RT_ITEMS_PER_PAGE, itemsCount);
  return (
    <div className={classes.tableHeading}>
      <div className={classes.countersAndActions}>
        <div className={classes.tableHeadingCounters}>
          <Label text="Results" size="lg" />
          <Counter
            dataTestId={totalItemsCounterTestId}
            count={itemsCount}
            classes={{ selectionCounter: classes.itemsCounter }}
          />
          <Counter
            dataTestId={selectedItemsCounter}
            count={selectedItemsCount}
            mode={COUNTER_MODES.SELECTED}
            classes={{ selectionCounter: classes.selectionCounter }}
          />
        </div>
        {selectedItemsCount > 0 && isUserEditor ? (
          <div className={classes.activation}>
            <Divider variant={HORIZONTAL} />
            {!isSelectedItemVersionActive && !isSelectedDraft ? (
              <Button
                type={buttonTypes.PRIMARY}
                isRounded
                label="Archive"
                classes={{ button: classes.archiveButton }}
                onClick={onArchive}
                height={32}
              />
            ) : null}
            <Button
              type={buttonTypes.PRIMARY}
              isRounded
              disabled={isSelectedItemVersionActive || !selectedItemsCount || isReadOnly}
              dataTestId={enableRulesBtnTestId}
              label={activateLabel}
              classes={{ button: classes.button }}
              onClick={onActivate}
              height={32}
            />
            <Button
              type={buttonTypes.SECONDARY}
              isRounded
              disabled={!selectedItemsCount || isReadOnly}
              dataTestId={disableRulesBtnTestId}
              label="Cancel"
              classes={{ button: classes.button }}
              onClick={onCancel}
              height={32}
            />
          </div>
        ) : null}
      </div>
      <div className={classes.filterAndPagiantorContainer}>
        <FilterLabel id="viewOlderVersionsId" label="View older versions" icon={icon} onClick={() => { getRuleTypesWithViewAll(); setIsViewVersionsActive(!isViewVersionsActive); }} isUnderline={isUnderline} />
        <Paginator
          className={classes.paginator}
          itemsCount={itemsCount}
          pagesCount={pagesCount}
          currentPageIndex={pageIndex}
          minIndex={itemsCount === 0 ? 0 : minIndex}
          maxIndex={itemsCount === 0 ? 0 : maxIndex}
          onPrevPageClick={onPrevPageClick}
          onNextPageClick={onNextPageClick}
          isDataLoading={isDataLoading}
          id="homePagePaginatorId"
        />
      </div>

    </div>
  );
});

RuleTypesTableHeading.propTypes = {
  isDataLoading: bool,
  isReadOnly: bool,
  itemsCount: number,
  selectedItemsCount: number,
  pagesCount: number,
  pageIndex: number,
  onActivate: func,
  onCancel: func,
  onNextPageClick: func,
  onPrevPageClick: func,
};

RuleTypesTableHeading.defaultProps = {
  isDataLoading: false,
  itemsCount: 0,
  selectedItemsCount: 0,
  pagesCount: 0,
  pageIndex: 0,
};
