import React, { memo } from 'react';
import { string, func, array } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ASC, DESC, NONE } from '../../constants/common';
import { FilterFrame } from '../filter-frame';
import { MultiSelectFilter } from '../multi-select-filter';
import { Label } from '../label';

const useStyles = makeStyles(({ colors, fontSizes, fontFamilies, mixins }) => ({
  sortFilterFieldRoot: {
    ...mixins.flexRowStartCenter,
    flexWrap: 'nowrap',
    cursor: ({ readOnly }) => (readOnly ? 'initial' : 'pointer'),
    position: ({ readOnly }) => (readOnly ? 'static' : 'relative'),
    transition: '.2s',
    overflow: 'hidden',
    paddingLeft: 0,
    marginRight: '5px',
    width: '100%',
  },
  arrowBox: {
    ...mixins.flexCenterCenter,
    flexShrink: 0,
    width: 12,
    height: 12,
    marginLeft: 10,
    visibility: ({ readOnly }) => readOnly && 'hidden',
  },
  arrowSort: {
    width: 6,
    height: 6,
    transform: 'rotate(45deg)',
  },
  [`arrow${ASC}`]: {
    borderLeft: `2px solid ${colors.black}`,
    borderTop: `2px solid ${colors.black}`,
    marginTop: 4,
  },
  [`arrow${DESC}`]: {
    borderRight: `2px solid ${colors.black}`,
    borderBottom: `2px solid ${colors.black}`,
    marginBottom: 1,
  },
  [`arrow${NONE}`]: {
    marginTop: 4,
    borderLeft: `2px solid ${colors.darkGray}`,
    borderTop: `2px solid ${colors.darkGray}`,
  },
  filterWrapper: {
    ...mixins.flexRowStartCenter,
    marginLeft: 'auto',
    height: '100%',
    width: 20,
    justifyContent: 'flex-end',
  },
  filterCounter: {
    ...mixins.flexCenterCenter,
    width: 19,
    height: 19,
    lineHeight: '19px',
    fontSize: fontSizes.sm,
    color: colors.white,
    borderRadius: '50%',
    backgroundColor: colors.blue,
    cursor: 'pointer',
    fontFamily: fontFamilies.primary,
    marginRight: 5,
  },
  filterOpener: {
    display: 'block',
    transform: 'rotate(90deg)',
    fontWeight: 'bold',
    color: colors.blackOpacity5,
    fontSize: fontSizes.lg,
    letterSpacing: '.8px',
    cursor: 'pointer',
    userSelect: 'none',
    marginRight: -8,
  },
  label: {
    fontSize: fontSizes.md,
    letterSpacing: '.2px',
    lineHeight: '16px',
    fontFamily: fontFamilies.primary,
    userSelect: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: '14px',
  },
  fieldContent: {
    padding: '0 8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'max-content',
  },
}));

export const SortFilterField = memo((props) => {
  const {
    sortOrder,
    label,
    fieldKey,
    filterOptions,
    selectedFilters,
    currentlySelectedFilters,
    onFiltersApply,
    onSetCurrentFilters,
    onSortApply,
    isRulePage,
  } = props;

  const classes = useStyles(props);

  const eventHandlers = {
    onSortClick: () => {
      const nextOrder = {
        [NONE]: ASC,
        [ASC]: DESC,
        [DESC]: NONE,
      };
      onSortApply && onSortApply(fieldKey, nextOrder[sortOrder]);
    },
    onFilterClick: (e) => e.stopPropagation(),
    applyFilters: () => onFiltersApply && onFiltersApply({ filter: fieldKey }),
    onFilterClose: () => onSetCurrentFilters({ filter: fieldKey, options: null }),
    onSelect: (selectedItems) => onSetCurrentFilters({ filter: fieldKey, options: selectedItems }),
  };

  const renderers = {
    arrow: () => sortOrder && (
      <div
        data-testid="sortFilterFieldArrow"
        onClick={eventHandlers.onSortClick}
        className={classes.arrowBox}
      >
        <div className={clsx(classes.arrowSort, classes[`arrow${sortOrder}`])} />
      </div>
    ),
    triggerComponent: () => {
      return selectedFilters.length
        ? (<div className={classes.filterCounter}>{selectedFilters.length}</div>)
        : (<span className={classes.filterOpener}>...</span>);
    },
    filterController: () => {
      if (!filterOptions.length) return null;

      return (
        <div
          onClick={eventHandlers.onFilterClick}
          className={classes.filterWrapper}
        >
          <FilterFrame
            onApply={eventHandlers.applyFilters}
            onClose={eventHandlers.onFilterClose}
            label="Filters"
            triggerComponent={renderers.triggerComponent}
          >
            <MultiSelectFilter
              label={label}
              currentlySelectedFilters={currentlySelectedFilters}
              selected={selectedFilters}
              options={filterOptions}
              onSelect={eventHandlers.onSelect}
            />
          </FilterFrame>
        </div>
      );
    },
  };

  return (
    <div className={classes.sortFilterFieldRoot}>
      {renderers.arrow()}
      <Label
        onClick={eventHandlers.onSortClick}
        text={label}
        size="md"
        color="black"
        upperCase={!isRulePage}
        classes={{ labelRoot: classes.fieldContent }}
      />
      {renderers.filterController()}
    </div>
  );
});

SortFilterField.propTypes = {
  sortOrder: string,
  label: string,
  fieldKey: string.isRequired,
  filterOptions: array,
  selectedFilters: array,
  currentlySelectedFilters: array,
  onFiltersApply: func,
};

SortFilterField.defaultProps = {
  label: '',
  sortOrder: NONE,
  filterOptions: [],
  selectedFilters: [],
};
