export const flexRow = {
  display: 'flex',
  flexDirection: 'row',
};

export const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
};

export const flexRowBetweenStart = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const flexRowBetweenCenter = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const flexCenterCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const flexVerticalCenter = {
  display: 'flex',
  alignItems: 'center',
};

export const flexSpaceBetween = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const flexCenterEnd = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
};

export const flexRowStartCenter = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const flexColumnCenter = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const flexColumnStart = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
};

export const flexColumnEnd = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
};

export const flexColumnGreedy = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

export const tableHeadingWrapper = {
  ...flexRowStartCenter,
  marginTop: 20,
  marginBottom: 5,
  height: 40,
};

export const searchBarWrapper = {
  ...flexRowStartCenter,
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  paddingTop: 25,
};

export const transition = {
  transition: '.2s',
};
