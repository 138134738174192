import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '../svg-icon';

import '../../assets/icons/trash.svg';
import '../../assets/icons/duplicate.svg';

const useStyles = makeStyles(() => ({
  removeIconContainer: {
    marginLeft: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  svgIconRoot: {
    marginRight: 10,
  },
  textContainer: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const RuleLineControlLabel = ({ iconName, text, onClick, id }) => {
  const classes = useStyles();
  return (
    <div id={id} className={classes.removeIconContainer}>
      <SvgIcon data-testid="RuleLineControlIconTestId" classes={classes} name={iconName} width={20} height={20} onClick={onClick} />
      <p data-testid="RuleLineControlTextTestId" className={classes.textContainer} onClick={onClick}>{text}</p>
    </div>
  );
};
