import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { white } from '../../styles/colors';
import { Roles } from '../../constants/common';

const useStyles = makeStyles(() => ({
  userName: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Helvetica Neue',
    color: white,
  },
  userRole: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Helvetica Neue',
    color: white,
  },
  userRoleContainer: {
    marginRight: 10,
    textAlign: 'end',
  },
}));

const getHighestRole = (rolesArr) => {
  if (rolesArr.includes('METADATA_EDITOR')) {
    return Roles.METADATA_EDITOR;
  }
  if (rolesArr.includes('EDITOR')) {
    return Roles.EDITOR;
  }
  return Roles.VIEWER;
};

export const UserRole = (userInfo) => {
  const classes = useStyles();
  return (
    <div className={classes.userRoleContainer}>
      <p className={classes.userName}>{userInfo.userData && userInfo.userData.firstName} {userInfo.userData && userInfo.userData.lastName}</p>
      <p className={classes.userRole}>{userInfo.roles && getHighestRole(userInfo.roles)}</p>
    </div>
  );
};
