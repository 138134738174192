import { fade } from '@material-ui/core/styles/colorManipulator';

/*
  here we are following next naming pattern for colors with opacity
  e.g. yellowOpacity3
  yellow - should be yellow constant in hex format
  Opacity - indicator, that this color is transparent
  3 - floating value after 0. in css prop opacity (opacity: 0.3)
 */

export const black = '#000';
export const white = '#fff';
export const darkGray = '#9B9B9B';
export const darkerGray = '#777';
export const darkestGray = '#111';
export const lightGray = '#ececec';
export const lighterGray = '#CCCCCC';
export const removedGrey = '#EBEBEB';
export const gray = '#E2E2E2';
export const grayBackground = '#FAFAFA';
export const grayBorder = '#D8D8D8';
export const blue = '#4A90E2';
export const addedBlue = '#D7E2FF';
export const yellow = '#FFD102';
export const orange = '#DC4E42';
export const red = '#DB4D41';
export const invalidRed = '#BF1919';
export const darkRed = '#D0021A';
export const subtleGreen = '#E7FFEF';
export const accentGreen = '#7ED321';
export const lightGreen = '#519A00';
export const moderateGreen = '#80BA40';
export const asphalt = '#605E5C';
export const whiteOrange = '#FFE7D3';
export const yellowWarning = '#FBAE6E';

export const whiteOpacity5 = fade(white, 0.5);
export const whiteOpacity2 = fade(white, 0.2);
export const blueOpacity1 = fade(blue, 0.1);
export const blueOpacity05 = fade(blue, 0.05);
export const blueOpacity25 = fade(blue, 0.25);
export const yellowOpacity3 = fade(yellow, 0.3);
export const yellowOpacity1 = fade(yellow, 0.1);
export const redOpacity1 = fade(red, 0.1);
export const invalidRedOpacity3 = fade(invalidRed, 0.3);
export const invalidRedOpacity6 = fade(invalidRed, 0.6);
export const accentGreenOpacity1 = fade(accentGreen, 0.1);
export const grayOpacity2 = fade(gray, 0.2);
export const grayOpacity05 = fade(gray, 0.05);
export const darkGrayOpacity25 = fade(darkGray, 0.25);
export const darkGrayOpacity5 = fade(darkGray, 0.5);
export const darkestGrayOpacity5 = fade(darkestGray, 0.5);
export const blackOpacity9 = fade(black, 0.9);
export const blackOpacity5 = fade(black, 0.5);
