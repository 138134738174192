import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, func, object, oneOfType, string } from 'prop-types';
import cx from 'classnames';

import { Popup } from '../popup';
import { Button, buttonTypes } from '../button';
import { SvgIcon } from '../svg-icon';
import { dialogPopup, dialogCancelBtnTestId } from '../../test-data/dataTestIds';
import { lighterGray } from '../../styles/colors';

import '../../assets/icons/cross.svg';

const useStyles = makeStyles(({ colors, fontFamilies, fontSizes, spaces }) => ({
  dialog: {},
  swoosh: {
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 70,
  },
  swooshImage: {
    width: 150,
    height: 59,
  },
  header: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    padding: '14px 0',
    color: colors.black,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    letterSpacing: -0.55,
    lineHeight: '29px',
    marginBottom: spaces.lg,
    textAlign: 'center',
    borderBottom: `1px solid ${lighterGray}`,
  },
  body: {
    margin: `0 0 ${spaces.md} 0`,
    color: colors.darkGray,
    fontSize: fontSizes.md,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  cross: {
    cursor: 'pointer',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 48,
    paddingRight: 17,
  },
  headerText: {},
  button: {},
  primary: {},
  secondary: {},
  buttonWrapper: {},
}));

export const Dialog = memo((props) => {
  const {
    isOpen,
    header,
    children,
    applyLabel,
    dataTestId,
    cancelLabel,
    transitionComponent,
    activateWord = '',
    setActivateWord,
    onApply,
    onCancel,
    onCrossCancel,
    isRulePage,
    onBackgroundClick,
    withSwoosh,
    withCross,
    ruleTypeName,
    ruleTypeVersion,
    BackdropProps,
    isReversed,
    correctActivationWord,
    isApplyDisabled,
  } = props;

  const classes = useStyles(props);

  const handleOnCrossClick = () => {
    onCrossCancel();
    if (setActivateWord) {
      setActivateWord('');
    }
  };

  const handleOnCancelClick = () => {
    onCancel();
    if (setActivateWord) {
      setActivateWord('');
    }
  };

  const handleOnApplyClick = () => {
    onApply(ruleTypeName, ruleTypeVersion);
    if (setActivateWord) {
      setActivateWord('');
    }
  };

  const headerComponent = !!header && (
    <div className={classes.header}>
      <div className={classes.headerContent}>
        <span className={classes.headerText}>{header}</span>
        {withCross ? (
          <SvgIcon
            width={20}
            height={20}
            classes={{ svgIconRoot: classes.cross }}
            onClick={handleOnCrossClick}
            name="cross"
          />
        ) : null}
      </div>
    </div>
  );
  const logo = !!withSwoosh && (
    <div className={classes.swoosh}>
      <SvgIcon name="nike-logo" classes={{ svgIconRoot: classes.swooshImage }} />
    </div>
  );
  const headerComponents = isReversed ? [logo, headerComponent] : [headerComponent, logo];

  // eslint-disable-next-line no-unneeded-ternary
  const applyState = isApplyDisabled ? isApplyDisabled : isRulePage ? !isRulePage : activateWord.toLowerCase().trim() !== correctActivationWord;
  return (
    <Popup
      isOpen={isOpen}
      header={header}
      classes={classes}
      transitionComponent={transitionComponent}
      onBackgroundClick={onBackgroundClick}
      BackdropProps={BackdropProps}
    >
      <div
        data-testid={dataTestId}
        className={classes.dialog}
      >
        {headerComponents}
        <div className={classes.body}>{children}</div>
        <div className={classes.buttons}>
          {!!cancelLabel && (
            <Button
              disabled={!onCancel}
              label={cancelLabel}
              dataTestId={dialogCancelBtnTestId}
              classes={{ button: cx(classes.button, classes.secondary), buttonWrapper: classes.buttonWrapper }}
              onClick={handleOnCancelClick}
            />
          )}
          {!!applyLabel && (
            <Button
              type={buttonTypes.PRIMARY}
              disabled={applyState}
              dataTestId="dialogApplyBtnTestId"
              label={applyLabel}
              classes={{ button: cx(classes.button, classes.primary), buttonWrapper: classes.buttonWrapper }}
              onClick={handleOnApplyClick}
            />
          )}
        </div>
      </div>
    </Popup>
  );
});

Dialog.displayName = 'Dialog';

Dialog.propType = {
  isOpen: bool.isRequired,
  header: string,
  isReversed: bool,
  dataTestId: string,
  transitionComponent: object,
  applyLabel: string,
  cancelLabel: string,
  onBackgroundClick: func,
  onApply: oneOfType([func, bool]),
  onCancel: func,
  withSwoosh: bool,
  BackdropProps: object,
};

Dialog.defaultProps = {
  dataTestId: dialogPopup,
  withSwoosh: false,
};
