/**
 * Converts primitive value to dropdown option
 * @param {String | Number} val - 'some_str'

 * @returns {Object.<{field: <Number | String>, label: <Number | String>}>} dropdownOption - Configured for dropdown option.
 */
export const buildSingleDropdownOption = (val) => {
  const formattedText = (val !== 0 && val) || 'N/A';

  return {
    field: val,
    label: formattedText,
  };
};

/**
 * Converts array of primitive values to dropdown option
 * @param {Array.<String | Number>} arr - ['some_str1', 'some_str2']

 * @returns {Array.<{field: <Number | String>, label: <Number | String>}>} dropdownOptions - Configured for dropdown options array.
 */
export const buildSingleDropdownOptions = (arr) => arr.map(buildSingleDropdownOption);

/**
 * Converts object to dropdown options using key as field and value as label
 * @param {Object} obj - {node: 1152_2132, type: 'EXPRESS'}

 * @returns {Array.<{field: <Number | String>, label: <Number | String>}>} dropdownOptions - Configured for dropdown options array.
 */
export const convertObjToDropdownOptions = (obj) => {
  const entries = Object.entries(obj);

  return entries.map(([key, value]) => ({
    field: key,
    label: value,
  }));
};

/**
 * Converts Array of objects to dropdown options allowing to select which key is field and which - value
 * @param {Array<Object>} arr - [{node: 1152_2132, type: 'EXPRESS'}, {node: 1992_9932, type: 'TWO_DAY'}]
 * @param {String | Number} fieldKey - 'node'
 * @param {String | Number} labelKey - 'type'

 * @returns {Array.<{field: <Number | String>, label: <Number | String>}>} dropdownOptions - Configured for dropdown options array.
 */
export const convertArrayToDropdownOption = (arr, fieldKey = '', labelKey = '') => {
  return arr.map(el => ({
    label: el[labelKey],
    field: el[fieldKey],
  }));
};
