import React, { useState } from 'react';
import { Box, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Button, buttonTypes } from '../button';
import { white, black } from '../../styles/colors';

const useStyles = makeStyles(() => ({
  ruleInfoContainer: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    fontWeight: 700,
    fontSize: 18,
    color: '#757575',
    rowGap: '5px',
  },
  ruleRowInfoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
  },
  draftLinkContainer: {
    color: '#5A90DC',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  basedOnVersionContainer: {
    color: '#5A90DC',
    textDecoration: 'underline',
  },
  columnName: {
    width: 165,
  },
  columnValue: {
    width: 220,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  controlButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectButton: {
    height: 28,
    fontSize: 12,
    fontWeight: 500,
    border: 'none',
    justifyContent: 'flex-start',
    fontFamily: 'Helvetica Neue',
    textTransform: 'none',
  },
  muiSelectRoot: {
    height: 24,
  },
  boxContainer: {
    backgroundColor: black,
  },
  dropPadding: {
    padding: 0,
  },
  labelFormControl: {
    color: white,
    fontSize: 12,
    fontWeight: 700,
    fontFamily: 'Helvetica Neue',
    top: -17,
    left: 10,
  },
  menuItemRoot: {
    '&:hover': {
      backgroundColor: white,
    },
  },
  formControlRoot: {
    '& > label + .MuiInput-formControl': {
      margin: 0,
    },
    '& > .MuiInput-underline:after': {
      border: 'none',
    },
  },
  metaDataContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  labelAnimated: {
    transition: 'none',
  },
  labelWhiteColor: {
    '&.MuiFormLabel-root.Mui-focused': {
      color: white,
    },
    '&.MuiInputLabel-shrink': {
      transform: 'none',
    },
  },
}));

const createUrlForBasedOn = (ruleTypeName, ruleTypeVersion) => {
  return '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: ruleTypeName, ruleTypeVersion: ruleTypeVersion.match(/\d/g).join(''), isDraft: false, basedOnVersion: 'Original' });
};

export const RuleLineInfo = ({ ruleTypeName, ruleTypeVersion, isDraft, isDraftExists, url, ruleTypeDescription, isFlexRT, downloadYamlFile, isUserMetaDataAdmin, getRuleTypeUrl, addSuccess }) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);

  const redirectToEditPage = () => {
    const addUrl = '/mpruleengineui/add?' + new URLSearchParams({
      name: ruleTypeName,
      version: ruleTypeVersion,
      description: ruleTypeDescription,
      isEdit: true,
    });
    history.push(addUrl);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClickOnCopyUrl = async () => {
    const ruleTypeUrl = await getRuleTypeUrl(ruleTypeName);
    if (ruleTypeUrl) {
      navigator.clipboard.writeText(ruleTypeUrl);
      addSuccess(`<span>URL <strong style="font-weight: 700">${ruleTypeUrl}</strong> was successfuly copied.</span>`);
    }
  };

  return (
    <div className={classes.ruleInfoContainer}>
      <div className={classes.ruleRowInfoContainer}>
        <div id="ruleTypeRulePageNameId" className={classes.columnName}>RULE TYPE</div>
        <div id="ruleTypeRulePageValueId" className={classes.columnValue}>{ruleTypeName}</div>
        <div className={classes.metaDataContainer}>
          {isFlexRT ? (
            <Box width={80} height={24} className={classes.boxContainer}>
              <FormControl
                fullWidth
                classes={{
                  root: classes.formControlRoot,
                }}
              >
                <InputLabel
                  id="simple-select-label"
                  shrink={false}
                  focused={false}
                  classes={{
                    formControl: classes.labelFormControl,
                    animated: classes.labelAnimated,
                    shrink: classes.labelShrink,
                    focused: classes.labelWhiteColor,
                  }}
                >
                  Meta Data
                </InputLabel>
                <Select
                  labelId="simple-select-label"
                  IconComponent={() => <div />}
                  id="simple-select"
                  value={selectedOption}
                  placeholder="Meta Data"
                  onFocus={null}
                  label="Meta Data"
                  onChange={handleChange}
                  classes={{
                    root: classes.muiSelectRoot,
                    select: classes.dropPadding,
                  }}
                >
                  {isUserMetaDataAdmin ? (
                    <MenuItem
                      classes={{
                        root: classes.menuItemRoot,
                      }}
                    >
                      <Button
                        id="rulePageSearchButtonId"
                        type={buttonTypes.SECONDARY}
                        label="Edit Meta Data"
                        classes={{
                          button: classes.selectButton,
                          buttonWrapper: classes.buttonWrapper,
                        }}
                        onClick={redirectToEditPage}
                        height={24}
                      />
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                    }}
                  >
                    <Button
                      id="rulePageYamlButtonId"
                      type={buttonTypes.SECONDARY}
                      label="Download .YAML"
                      classes={{
                        button: classes.selectButton,
                        buttonWrapper: classes.buttonWrapper,
                      }}
                      onClick={() => downloadYamlFile(ruleTypeName)}
                      height={24}
                    />
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                    }}
                  >
                    <Button
                      id="rulePageYamlButtonId"
                      type={buttonTypes.SECONDARY}
                      label="Copy URL"
                      classes={{
                        button: classes.selectButton,
                        buttonWrapper: classes.buttonWrapper,
                      }}
                      onClick={handleClickOnCopyUrl}
                      height={24}
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </div>
      </div>
      <div className={classes.ruleRowInfoContainer}>
        <div id={isDraft ? 'draftRulePageNameId' : 'versionRulePageNameId'}>{isDraft ? 'DRAFT' : 'VERSION'}</div>
        {isDraft ? <div id="draftRulePageValueId">Based on <a href={createUrlForBasedOn(ruleTypeName, ruleTypeVersion)} className={classes.basedOnVersionContainer}>Version {ruleTypeVersion.match(/\d/g).join('')}</a></div> : <div id="versionRulePageValueId">{ruleTypeVersion}</div>}
      </div>
      {isDraftExists && !isDraft
        ? (
          <div className={classes.ruleRowInfoContainer}>
            <div id="draftRulePageLabelId" className={classes.columnName}>DRAFT</div>
            <a id="draftRulePageLinkId" className={classes.draftLinkContainer} href={url}>link</a>
          </div>
        ) : null}
    </div>
  );
};
