import React, { memo, cloneElement, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Box } from '@material-ui/core';
import { bool, func, object, oneOfType, string } from 'prop-types';
import cx from 'classnames';

import { Popup } from '../popup';
import { Button, buttonTypes } from '../button';
import { SvgIcon } from '../svg-icon';
import { dialogPopup, dialogCancelBtnTestId } from '../../test-data/dataTestIds';
import { black, lighterGray } from '../../styles/colors';

import '../../assets/icons/cross.svg';
import '../../assets/icons/file-json.svg';

const useStyles = makeStyles(({ colors, fontFamilies, fontSizes, spaces }) => ({
  dialog: {},
  swoosh: {
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 70,
  },
  swooshImage: {
    width: 150,
    height: 59,
  },
  header: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    padding: '14px 0',
    color: colors.black,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    letterSpacing: -0.55,
    lineHeight: '29px',
    marginBottom: spaces.lg,
    textAlign: 'center',
    borderBottom: `1px solid ${lighterGray}`,
  },
  body: {
    margin: `0 0 ${spaces.md} 0`,
    color: colors.darkGray,
    fontSize: fontSizes.md,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  cross: {
    cursor: 'pointer',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 48,
    paddingRight: 17,
  },
  headerText: {},
  button: {},
  primary: {},
  secondary: {},
  buttonWrapper: {},
  fileNameContainer: {
    position: 'relative',
    marginTop: 36,
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  },
  selectedFileName: {},
  deleteUploadFile: {
    position: 'absolute',
    right: 0,
    top: 8,
  },
  fileNameAndProgressBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 24,
  },
  progressBarRoot: {
    height: 8,
    backgroundColor: '#E9E9E9',
    borderRadius: 5.5,
  },
  colorPrimary: {
    backgroundColor: black,
  },
}));

export const UploadDialog = memo((props) => {
  const {
    isOpen,
    header,
    children,
    applyLabel,
    dataTestId,
    cancelLabel,
    transitionComponent,
    onApply,
    onCancel,
    applyButtonDisabled,
    onCrossCancel,
    onBackgroundClick,
    withSwoosh,
    withCross,
    BackdropProps,
    isReversed,
    setIsUploadFileApplyDisabled,
    selectedFile,
    setSelectedFile,
    isFilePicked,
    setIsFilePicked,
    setProgress,
    progress,
    isSaveClicked,
    setIsSaveClicked,
  } = props;

  const handleChangeFile = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsFilePicked(true);
      setIsUploadFileApplyDisabled(false);
    }
  }, [setSelectedFile, setIsFilePicked, setIsUploadFileApplyDisabled]);

  const handleOnCrossFileClick = () => {
    setSelectedFile(null);
    setIsFilePicked(false);
    setIsSaveClicked(false);
    setProgress(0);
    setIsUploadFileApplyDisabled(true);
  };

  const handleOnCancelClick = () => {
    setIsUploadFileApplyDisabled(true);
    onCancel();
  };

  const handleOnApplyClick = () => {
    setIsSaveClicked(true);
    setIsUploadFileApplyDisabled(true);
    onApply();
  };

  const classes = useStyles(props);
  const headerComponent = !!header && (
    <div className={classes.header}>
      <div className={classes.headerContent}>
        <span className={classes.headerText}>{header}</span>
        {withCross ? (
          <SvgIcon
            width={20}
            height={20}
            classes={{ svgIconRoot: classes.cross }}
            onClick={() => {
              onCrossCancel();
            }}
            name="cross"
          />
        ) : null}
      </div>
    </div>
  );
  const logo = !!withSwoosh && (
    <div className={classes.swoosh}>
      <SvgIcon name="nike-logo" classes={{ svgIconRoot: classes.swooshImage }} />
    </div>
  );

  const headerComponents = isReversed ? [logo, headerComponent] : [headerComponent, logo];
  return (
    <Popup
      isOpen={isOpen}
      header={header}
      classes={classes}
      transitionComponent={transitionComponent}
      onBackgroundClick={onBackgroundClick}
      BackdropProps={BackdropProps}
    >
      <div
        data-testid={dataTestId}
        className={classes.dialog}
      >
        {headerComponents}
        <div className={classes.body}>{cloneElement(children, { handleChangeFile: handleChangeFile, isFilePicked: isFilePicked })}</div>
        {isFilePicked ? (
          <div className={classes.fileNameContainer}>
            <SvgIcon name="file-json" width={32} height={30} />
            <div className={classes.fileNameAndProgressBar}>
              <span className={classes.selectedFileName}>{selectedFile.name}</span>
              {isSaveClicked ? (
                <Box width={280}>
                  <LinearProgress
                    classes={{
                      root: classes.progressBarRoot,
                      barColorPrimary: classes.colorPrimary,
                    }}
                    value={progress}
                    variant="determinate"
                  />
                </Box>
              ) : null}
              <SvgIcon
                name="cross"
                classes={{ svgIconRoot: classes.deleteUploadFile }}
                width={14}
                height={14}
                onClick={handleOnCrossFileClick}
              />
            </div>
          </div>
        ) : null}
        <div className={classes.buttons}>
          {!!cancelLabel && (
            <Button
              disabled={!onCancel}
              label={cancelLabel}
              dataTestId={dialogCancelBtnTestId}
              classes={{ button: cx(classes.button, classes.secondary), buttonWrapper: classes.buttonWrapper }}
              onClick={handleOnCancelClick}
            />
          )}
          {!!applyLabel && (
            <Button
              type={buttonTypes.PRIMARY}
              disabled={applyButtonDisabled}
              dataTestId="dialogApplyBtnTestId"
              label={applyLabel}
              classes={{ button: cx(classes.button, classes.primary), buttonWrapper: classes.buttonWrapper }}
              onClick={handleOnApplyClick}
            />
          )}
        </div>
      </div>
    </Popup>
  );
});

UploadDialog.displayName = 'Dialog';

UploadDialog.propType = {
  isOpen: bool.isRequired,
  header: string,
  isReversed: bool,
  dataTestId: string,
  transitionComponent: object,
  applyLabel: string,
  cancelLabel: string,
  onBackgroundClick: func,
  onApply: oneOfType([func, bool]),
  onCancel: func,
  withSwoosh: bool,
  BackdropProps: object,
};

UploadDialog.defaultProps = {
  dataTestId: dialogPopup,
  withSwoosh: false,
};
