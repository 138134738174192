export const dropdownOptionsContainerTestId = 'dropdownOptionsContainer';
export const selectFieldTestId = 'dropdownSelectField';
export const optionTestId = 'dropdownOption';
export const textareaTestId = 'textarea';
export const totalItemsCounterTestId = 'totalItemsCounterTestId';
export const selectedItemsCounter = 'selectedItemsCounter';
export const dialogPopup = 'dialogPopup';
export const enableRulesBtnTestId = 'enableRulesBtnTestId';
export const disableRulesBtnTestId = 'disableRulesBtnTestId';
export const paginationPrevTestId = 'paginationPrevTestId';
export const paginationNextTestId = 'paginationNextTestId';
export const dialogCancelBtnTestId = 'dialogCancelBtnTestId';
