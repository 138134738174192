import { AegisClient } from "@nike/aegis-auth-react"
import jwtDecode from "jwt-decode";

export const config = {
  clientId: "nike.consumeromrule.ui", // e.g. nike.niketech.cerberus-ui
  redirectUri: window.location.origin,
  qa: process.env == "prod" ? false : true, // this option is for calling DEV (QA) OKTA server (qa: true) or prod server (qa: false)
  scopes: [
    "order_management:ruleengineui::create:",
    "order_management:ruleengineui::read:",
    "order_management:ruleengineui::update:",
    "openid",
    "profile",
    "email"
  ],
  location: process.env.REACT_APP_PATH
}

export const globalHeaders = {}
export const setGlobalHeader = (name, value) => {
  globalHeaders[name] = value
}

export const oAuthClient = new AegisClient(config)

const handleLogin = ({ accessToken }) => {
  setGlobalHeader("Authorization", "Bearer " + accessToken.accessToken)
  setGlobalHeader("accessToken", accessToken.accessToken)
  const username = jwtDecode(accessToken.accessToken).sub;
  const groups = jwtDecode(accessToken.accessToken).groups;
  var obj = new Object();
  obj.email = username
  obj.firstname  = username.substring(0, username.indexOf('.'));
  obj.lastname = username.substring(username.indexOf('.') + 1, username.length);
  obj.groups = groups;
  obj.username = username;
  var jsonString= JSON.stringify(obj);
  setGlobalHeader("xcurrentuser", jsonString);

}

const getEnv = () => {
  if (process.env.NODE_ENV !== 'development') {
    return true;
  } else if(process.env.NODE_ENV === 'test') {
    return true;
  } else if(process.env.NODE_ENV === 'production') {
    return false;
  }
}
const getUri = () => {
  if (process.env.NODE_ENV !== 'development') {
    return "https://rule-engine-ui-dev.consumer-om-test.nikecloud.com/";
  } else if(process.env.NODE_ENV === 'test') {
    return "https://rule-engine-ui-test.consumer-om-test.nikecloud.com/";
  } else if(process.env.NODE_ENV === 'production') {
    return "https://rule-engine-ui-prod.consumer-om-prod.nikecloud.com/";
  }
}
const handleRenew = ({ accessToken }) => {
  setGlobalHeader("Authorization", "Bearer " + accessToken)
}

oAuthClient.on("login:success", handleLogin)
oAuthClient.on("renew:access:success", handleRenew)

export default globalHeaders;
