import React from 'react';
import { configure } from 'mobx';

import { UiStore } from './UiStore';
import { routingStore } from './RoutingStore';
import { errorStore } from './errorStore/ErrorStore';
import { notificationStore } from './notification';
import { RuleTypesStore } from './ruleTypes/RuleTypesStore';
import { SettingStore } from './setting/SettingStore';
import { RuleLineStore } from './ruleTypes/RuleLineStore';
import { RuleLineItemStore } from './ruleTypes/RuleLineItemStore';
import { AddRuleTypeStore } from './addRuleTypeStore/AddRuleTypeStore';

configure({ enforceActions: 'observed' });

const routing = routingStore;
const settingStore = new SettingStore();
const uiStore = new UiStore();
const ruleTypesStore = new RuleTypesStore();
const ruleLineStore = new RuleLineStore();
const ruleLineItemStore = new RuleLineItemStore();
const addRyleTypeStore = new AddRuleTypeStore();

export const storesContext = React.createContext({
  uiStore,
  routing,
  errorStore,
  notificationStore,
  settingStore,
  ruleTypesStore,
  ruleLineStore,
  ruleLineItemStore,
  addRyleTypeStore,
});
