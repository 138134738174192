import React, { useState, cloneElement, useCallback, useEffect } from 'react';
import { Collapse } from '@material-ui/core';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '../svg-icon';
import { TextInput } from '../text-input';
import { invalidRed } from '../../styles/colors';

import '../../assets/icons/chevron-down.svg';
import '../../assets/icons/chevron-up.svg';

const useStyles = makeStyles(() => ({
  collapseContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 50,
    paddingLeft: 12,
    paddingRight: 16,
  },
  headerText: {
    '& input': {
      fontSize: 14,
      fontWeight: 500,
      border: 'none',
    },
  },
  invalidTextFieldInput: {
    border: `1px solid ${invalidRed}`,
  },
}));

export const Collapsed = (props) => {
  const { children, header, setIsValid, id, removeParam, updateParameter, el, isFolded, inOrOut, inputParams, outputParams, addError } = props;
  const classes = useStyles(props);
  const [isOpen, setIsOpen] = useState(!isFolded);
  const [parameterName, setParameterName] = useState(el.name ? el.name : '');
  const [isParameterNameInvalid, setIsParameterNameInvalid] = useState(false);
  const [validParams, setValidParams] = useState({
    paramName: el.name ? el.name : '',
    paramDescr: el.description ? el.description : '',
    type: el.type ? el.type : '',
    enumValues: el.enumValues ? el.enumValues : [],
  });

  const confirmValidity = useCallback((isNameValid, isDescripionValid, isTypeValid, isTypeEnum, IsEnumValuesValid) => {
    let isValid = false;
    if (isTypeEnum) {
      isValid = isNameValid && isDescripionValid && isTypeValid && IsEnumValuesValid;
    } else {
      isValid = isNameValid && isDescripionValid && isTypeValid;
    }
    setIsValid(id, isValid);
  }, [setIsValid, id]);

  useEffect(() => {
    const isNameValid = !validParams.paramName.match(/\W/g) && validParams.paramName.length > 0;
    const isDescrValid = validParams.paramDescr.length <= 100 && validParams.paramDescr.length > 0;
    const isTypeValid = validParams.type.length > 0;
    const isTypeEnum = validParams.type === 'Enum';
    if (isTypeEnum) {
      const IsEnumValuesValid = validParams.enumValues && validParams.enumValues.length > 0 && validParams.enumValues.every && validParams.enumValues.every((enumEl) => !enumEl.match(/\W/g));
      confirmValidity(isNameValid, isDescrValid, isTypeValid, isTypeEnum, IsEnumValuesValid);
    } else {
      confirmValidity(isNameValid, isDescrValid, isTypeValid, isTypeEnum);
    }
  }, [validParams.paramName, validParams.paramDescr, validParams.type, validParams.enumValues && validParams.enumValues.length, validParams, confirmValidity]);

  useEffect(() => {
    if (validParams.enumValues && validParams.enumValues.length === 0) {
      setValidParams({ paramName: validParams.paramName, paramDescr: validParams.paramDescr, type: validParams.type });
    }
  }, [validParams.enumValues && validParams.enumValues.length]);

  const setDescr = useCallback((val) => {
    setValidParams({ ...validParams, paramDescr: val });
    updateParameter(id, 'description', val);
  }, [validParams, setValidParams, updateParameter]);

  const setType = useCallback((val) => {
    setValidParams({ ...validParams, type: val });
    updateParameter(id, 'type', val);
  }, [validParams, setValidParams, updateParameter]);

  const setEnums = useCallback((val) => {
    setValidParams((prev) => { return { ...prev, enumValues: val }; });
    updateParameter(id, 'enumValues', val);
  }, [validParams, setValidParams, updateParameter]);

  const setRequired = useCallback((val) => {
    updateParameter(id, 'required', val);
  }, [updateParameter]);

  const handleOnChange = useCallback((val) => {
    if (val.length <= 15) {
      setParameterName(val);
      if (!val.match(/\W/g)) {
        setIsParameterNameInvalid(false);
        setValidParams({ ...validParams, paramName: val });
        updateParameter(id, 'name', val);
      } else {
        setIsParameterNameInvalid(true);
        setValidParams({ ...validParams, paramName: val });
      }
    }
    if (val === '') {
      setIsParameterNameInvalid(true);
    }

    const mergedParams = [...inputParams.map(p => p.name), ...outputParams.map(p => p.name)];

    if (new Set(mergedParams).size !== mergedParams.length) {
      if (!isParameterNameInvalid) {
        addError('Parameters must have unique names');
      }
      setIsParameterNameInvalid(true);
    } else if ((new Set(mergedParams).size === mergedParams.length) && !val.match(/\W/g)) {
      setIsParameterNameInvalid(false);
    }
  }, [setParameterName, setIsParameterNameInvalid, setValidParams, validParams]);

  return (
    <div className={classes.collapseContainer} data-testid="collapsedTestId">
      <Collapse
        in={isOpen}
        timeout="auto"
      >
        {cloneElement(children, { setDescr: setDescr, setType: setType, setEnums: setEnums, setRequired: setRequired, removeParam: removeParam, item: el, paramName: parameterName, inOrOut: inOrOut, inputParams: inputParams, addError: addError })}
      </Collapse>
      <div className={isParameterNameInvalid ? cx(classes.invalidTextFieldInput, classes.headerContainer) : classes.headerContainer}>
        <TextInput
          focus
          value={parameterName}
          onChange={handleOnChange}
          placeholder={header}
          classes={{ textField: classes.headerText }}
        />
        <SvgIcon width={16} height={16} onClick={() => setIsOpen((prevState) => !prevState)} name={`chevron-${isOpen ? 'up' : 'down'}`} />
      </div>
    </div>

  );
};
