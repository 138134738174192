import React, { useState, Fragment, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TextInput } from '../text-input';
import { Dropdown } from '../dropdown';
import { black, darkerGray } from '../../styles/colors';

const useStyles = makeStyles(() => ({
  textField: {
    width: 400,
    height: 40,
    marginBottom: 12,
    '& input': {
      fontSize: 14,
      fontWeight: 500,
    },
    '& input::placeholder': {
      color: black,
    },
    '& input:focus::placeholder': {
      color: darkerGray,
    },
  },
  selectedField: {
    height: 40,
    marginBottom: 12,
    fontSize: 14,
    fontWeight: 500,
    color: black,
  },
  option: {
    fontSize: 14,
    fontWeight: 500,
    color: black,
  },
  selectedFieldPlaceholder: {
    height: 40,
    marginBottom: 12,
    fontSize: 14,
    fontWeight: 500,
    color: darkerGray,
  },
}));

export const AddRuleLineField = ({ el, updateDraftLineObject, setFocusedIndex, isFocused, index, id }) => {
  const ref = useRef();
  const placeholder = el.field.toUpperCase() + (!el.required ? ' (optional)' : '');
  const [value, setValue] = useState('');
  const classes = useStyles();
  const predifinedBoolValues = ['true', 'false'];
  const onEnterMoveFocus = (e) => {
    if (e.keyCode === 13) {
      setFocusedIndex((prev) => prev + 1);
    }
  };

  const onClickSetIndex = () => {
    setFocusedIndex(index);
  };

  const handleOnChangeString = (valueString) => {
    updateDraftLineObject(valueString, el.field, el.type, el.inOrOut);
    setValue(valueString);
  };

  const handleOnChangeInteger = (valueString) => {
    const filteredValue = valueString.match(/\d/g);
    const isValid = filteredValue ? filteredValue.length === valueString.length : false;
    if (isValid) {
      updateDraftLineObject(valueString, el.field, el.type, el.inOrOut);
      setValue(valueString);
    } else {
      updateDraftLineObject(valueString, el.field, el.type, el.inOrOut);
    }
  };

  const handleOnChangeArray = (valueString) => {
    updateDraftLineObject(valueString, el.field, el.type);
    setValue(valueString);
  };

  const handleOnSelect = (valueString) => {
    updateDraftLineObject(valueString, el.field, el.type, el.inOrOut); setValue(valueString);
  };

  const renderers = {
    string: <TextInput
      id={id}
      dataTestId="addRuleLineStringTestId"
      onClick={onClickSetIndex}
      ref={ref}
      onKeyDown={onEnterMoveFocus}
      focus={isFocused}
      type={el.type}
      value={value}
      onChange={handleOnChangeString}
      placeholder={placeholder}
      classes={classes}
    />,
    boolean: <Dropdown
      id={id}
      disabled={el.values && el.values.length === 0}
      width={400}
      height={40}
      placeholder={placeholder}
      classes={{
        option: classes.option,
        selectedField: classes.selectedField,
      }}
      selected={value}
      options={predifinedBoolValues}
      nameOrVersion="rulePage"
      onSelect={handleOnSelect}
    />,
    enum: <Dropdown
      id={id}
      withSearch
      disabled={el.values && el.values.length === 0}
      width={400}
      height={40}
      placeholder={placeholder}
      classes={{
        option: classes.option,
        selectedField: classes.selectedField,
      }}
      selected={value}
      options={el.values}
      nameOrVersion="rulePage"
      onSelect={handleOnSelect}
    />,
    integer: <TextInput
      id={id}
      type={el.type}
      dataTestId="addRuleLineIntegerTestId"
      value={value}
      onClick={onClickSetIndex}
      onChange={handleOnChangeInteger}
      onKeyDown={onEnterMoveFocus}
      focus={isFocused}
      placeholder={placeholder}
      classes={classes}
    />,
    array: <TextInput
      id={id}
      onClick={onClickSetIndex}
      onKeyDown={onEnterMoveFocus}
      focus={isFocused}
      type={el.type}
      value={value}
      onChange={handleOnChangeArray}
      placeholder={placeholder}
      classes={classes}
    />,
  };
  return <Fragment>{renderers[el.type]}</Fragment>;
};
