import get from 'lodash/get';

import { DESC, ALPHANUMERIC_FIELDS } from '../constants/common';

const commonCompare = (fieldA, fieldB) => {
  return (
    (fieldA === fieldB ? 0 : fieldA < fieldB ? -1 : 1)
  );
};

const naturalCompare = (fieldA, fieldB) => {
  const isFieldAString = isNaN(+fieldA);
  const isFieldBString = isNaN(+fieldB);

  if (isFieldAString && isFieldBString) return commonCompare(fieldA.toLowerCase(), fieldB.toLowerCase());
  if (!isFieldAString && !isFieldBString) return commonCompare(+fieldA, +fieldB);

  return isFieldAString ? 1 : -1;
};

export const applySort = (data, option, sortOrder, pathToOption) => { // eslint-disable-line
  const comparer = ALPHANUMERIC_FIELDS.includes(option) ? naturalCompare : commonCompare;
  return [...data].sort((a, b) => {
    let fieldA = null;
    let fieldB = null;
    if (option === 'version') {
      const aVersion = a.data.version.match(/\d/g);
      const bVersion = b.data.version.match(/\d/g);
      if (aVersion) {
        fieldA = aVersion.join('');
      }
      if (bVersion) {
        fieldB = bVersion.join('');
      }
    }

    fieldA = option === 'version' ? fieldA : (pathToOption ? get(a, pathToOption) : option === 'isActive' ? a : a.data)[option] || '';
    fieldB = option === 'version' ? fieldB : (pathToOption ? get(b, pathToOption) : option === 'isActive' ? b : b.data)[option] || '';

    return comparer(fieldA, fieldB) * (sortOrder === DESC ? -1 : 1);
  });
};
