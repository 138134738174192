import { observable, action } from 'mobx';
import { NONE } from '../../constants/common';

export class PageStore {
  @observable isDataLoading = false;

  @observable sorting = {
    sortableFields: [],
    sortBy: '',
    sortOrder: NONE,
  };

  @action.bound
  onSortApply(sortBy, sortOrder) {
    this.sorting.sortBy = sortBy;
    this.sorting.sortOrder = sortOrder;
  }
}
