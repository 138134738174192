import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ colors, borders, spaces, fontSizes, boxShadows, mixins }) => ({
  root: {
    width: ({ width }) => width || 190,
    position: 'relative',
    fontSize: fontSizes.sm,
    userSelect: 'none',
    pointerEvents: ({ disabled }) => disabled && 'none' || 'initial',
    opacity: ({ disabled }) => disabled && 0.5 || 1,
    cursor: ({ disabled }) => disabled && 'not-allowed',
  },
  selectedField: {
    ...mixins.flexRowBetweenCenter,
    height: 30,
    cursor: 'pointer',
    padding: `0 ${spaces.md}`,
    border: borders.gray,
    color: 'inherit',
  },
  message: {
    padding: spaces.md,
  },
  wrapper: {
    position: 'absolute',
    zIndex: 91,
    background: 'white',
    left: 0,
    right: 0,
    boxShadow: boxShadows.commonShadow,
    border: borders.gray,
    boxSizing: 'border-box',
    marginTop: -1,
  },
  options: {
    padding: `${spaces.sm} 0`,
    maxHeight: '198px',
    overflowY: 'auto',
    color: 'inherit',
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  option: {
    ...mixins.flexRowStartCenter,
    padding: `0 ${spaces.md}`,
    cursor: 'pointer',
    width: '100%',
    height: 30,

    '&:hover': {
      background: colors.grayOpacity2,
    },
  },
  selectedOption: {
    background: colors.grayOpacity2,
  },
  arrow: {
    fill: colors.black,
    height: '12px',
    width: '12px',
    flexShrink: 0,
  },
  textField: {
    borderBottom: `1px solid ${colors.gray}`,
  },
  bulkActionsContainer: {
    ...mixins.flexVerticalCenter,
    justifyContent: 'space-evenly',
    height: 32,
    borderBottom: `1px solid ${colors.gray}`,
  },
  bulkControl: {
    justifyContent: 'center',
    marginLeft: 'unset',
  },
  dividerWrapper: {
    margin: 0,
  },
}));
