import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { object, string, bool, oneOf } from 'prop-types';
import cx from 'classnames';

import { Label } from '../label';

export const VERTICAL = 'vertical';
export const HORIZONTAL = 'horizontal';
export const ROW = 'row';
export const COLUMN = 'column';

const useStyles = makeStyles(({ spaces }) => ({
  selectionWrapper: {
    display: 'flex',
  },
  userInput: {
    marginTop: ({ orientation }) => orientation === COLUMN && spaces.sm,
  },
  [ROW]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  [COLUMN]: {
    flexDirection: 'column',
  },
}));

export const SelectionBox = memo((props) => {
  const classes = useStyles(props);
  const { isRequired, label, orientation, children, size } = props;

  return (
    <div className={cx(classes.selectionWrapper, classes[orientation])}>
      <Label
        isRequired={isRequired}
        text={label}
        size={size}
        color="darkerGray"
      />
      <div className={classes.userInput}>
        {children}
      </div>
    </div>
  );
});

SelectionBox.propTypes = {
  disabled: bool,
  isRequired: bool,
  orientation: oneOf([ROW, COLUMN]),
  label: string.isRequired,
  classes: object,
};

SelectionBox.defaultProps = {
  disabled: false,
  isRequired: false,
  orientation: COLUMN,
  classes: {},
};
