import React, { memo, useState, useEffect, useRef, useMemo } from 'react';
import { string, bool, arrayOf, shape, oneOfType, instanceOf, func, object, number, array } from 'prop-types';
import xorBy from 'lodash/xorBy';
import cx from 'classnames';

import { Checkbox } from '../checkbox';
import { TextInput } from '../text-input';
import { useStyles } from './DropdownStyles';
import { SvgIcon } from '../svg-icon';
import { LinkButton } from '../link-button';
import { Divider, VERTICAL } from '../divider';
import { dropdownOptionsContainerTestId, optionTestId, selectFieldTestId } from '../../test-data/dataTestIds';

import '../../assets/icons/chevron-down.svg';
import '../../assets/icons/chevron-up.svg';
import { RULE_TYPE_NAME, VERSION } from '../../constants/common';


export const Dropdown = memo((props) => {
  const classes = useStyles(props);
  const {
    isMultiple,
    withSearch,
    placeholder,
    options,
    selected,
    blurExceptionNodes = [],
    onSelect,
    onClose,
    onSearch,
    onClick,
    withBulk,
    nameOrVersion,
    id,
    optionsId,
  } = props;
  const [optionsList, setOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const wrapperRef = useRef();

  useEffect(() => { setOptions(filterOptions(searchValue)); }, [options]);

  useEffect(() => {
    const dropdownCloser = ({ target }) => {
      const preventClosing = blurExceptionNodes.length && blurExceptionNodes.some(({ current }) => current.contains(target));

      if (isOpen && !wrapperRef.current.contains(target) && !preventClosing) {
        isOpen && setIsOpen(false);
        onClose && onClose();
      }
    };

    document.addEventListener('click', dropdownCloser, true);

    return () => document.removeEventListener('click', dropdownCloser, true);
  }, [isOpen, blurExceptionNodes, onClose]);


  const dropdownToggle = () => {
    setIsOpen((prevState) => !prevState);
    isOpen && onClose && onClose();
  };
  const generateSimpleValue = () => selected && (selected || 'N/A') || placeholder;

  const generateComplexValue = () => {
    if (!selected || selected.length === 0) return placeholder;
    let firstSelected = selected[0];
    if (!firstSelected) {
      firstSelected = options.filter((field) => field === firstSelected);
    }
    return firstSelected;
  };

  const renderDropdownArrow = () => {
    const arrowSvg = `chevron-${isOpen ? 'up' : 'down'}`;

    return (
      <SvgIcon name={arrowSvg} classes={{ svgIconRoot: classes.arrow }} />
    );
  };

  const renderSelectField = useMemo(() => {
    const displayedValue = isMultiple ? generateComplexValue() : generateSimpleValue();
    return (
      <div
        title={displayedValue}
        onClick={dropdownToggle}
        className={classes.selectedField}
        data-testid={selectFieldTestId}
      >
        <span className={classes.textEllipsis}>
          {displayedValue}
        </span>
        {renderDropdownArrow()}
      </div>
    );
  }, [selected, nameOrVersion, options, renderDropdownArrow]);

  const onOptionSelect = (e) => {
    const { currentTarget: { dataset: { optionField = null } } } = e;

    if (selected && selected === optionField) return null;
    const option = nameOrVersion === 'name' || nameOrVersion === 'rulePage';
    const selectedItem = optionsList.find(el => {
      return option ? el === optionField : +el === +optionField;
    });
    const optionForFilter = nameOrVersion === 'name' ? RULE_TYPE_NAME : VERSION;
    onSelect && onSelect(selectedItem, optionField, optionForFilter);
    onClick && onClick();
    setIsOpen(false);
  };

  const renderOption = (label, index) => {
    const text = label || 'N/A';
    return (
      <div
        title={label}
        key={label}
        data-option-field={label}
        onClick={onOptionSelect}
        className={cx(classes.option)}
        data-testid={optionTestId}
        id={optionsId + index}
      >
        <span className={classes.textEllipsis}>{text}</span>
      </div>
    );
  };

  const onCheckboxOptionSelect = (e) => {
    const { currentTarget: { dataset: { optionField = null } } } = e;
    const option = nameOrVersion === 'name' ? RULE_TYPE_NAME : VERSION;
    onSelect && onSelect(selected, optionField, option);
    onClick && onClick();
  };

  const renderCheckboxOption = (label, index) => {
    const text = label || 'N/A';
    const isSelected = selected && !!selected.find(el => el === label);
    return (
      <div
        title={text}
        onClick={onCheckboxOptionSelect}
        key={label}
        data-option-field={label}
        className={classes.option}
        id={optionsId + index}
        data-testid="dropdownCheckboxOption"
      >
        <Checkbox
          name={label}
          checked={isSelected}
          label={text}
        />
      </div>
    );
  };

  const renderOptions = useMemo(() => {
    if (!optionsList.length) {
      return (
        <div className={classes.message}>No options found...</div>
      );
    }

    const renderer = isMultiple ? renderCheckboxOption : renderOption;
    return optionsList.map(renderer);
  }, [optionsList, isMultiple, selected]);


  const filterOptions = (searchVal) => {
    return !searchVal ? options : options.filter((label) => searchVal.toLowerCase().split(' ')
      .every(val => `${label}`.toLowerCase().includes(val)));
  };

  const onSearchHandler = (inputVal) => {
    const filteredOptions = filterOptions(inputVal);

    setSearchValue(inputVal);
    setOptions(filteredOptions);
    // this is hack to allow parent component to select all filtered option
    // also, later it's possible to add some function as prop which will customly filter options in
    // parent component and will allow not to use setOptions in line above
    onSearch && onSearch(inputVal);
  };

  const renderSearch = () => {
    if (!withSearch) return null;

    return (
      <div className={classes.textField} data-testid="dropdownSearchInput">
        <TextInput
          focus
          name="Search"
          placeholder="Search"
          isSearch
          value={searchValue}
          onChange={onSearchHandler}
          autoComplete={false}
          bordered={false}
        />
      </div>
    );
  };

  const renderBulkActions = () => {
    return (
      <div className={classes.bulkActionsContainer}>
        <LinkButton
          label="Select All"
          classes={{ childrenClass: classes.bulkControl }}
          onClick={() => onSelect(xorBy(selected, optionsList, 'field'))}
        />
        <Divider type={VERTICAL} classes={{ dividerWrapper: classes.dividerWrapper }} />
        <LinkButton
          label="Clear All"
          classes={{ childrenClass: classes.bulkControl }}
          onClick={() => onSelect && onSelect(selected)}
        />
      </div>
    );
  };

  return (
    <div
      id={id}
      ref={wrapperRef}
      className={classes.root}
    >
      {renderSelectField}
      {
        isOpen && (
          <div className={classes.wrapper} data-testid={dropdownOptionsContainerTestId}>
            {renderSearch()}
            {withBulk && renderBulkActions()}
            <div className={classes.options}>
              {renderOptions}
            </div>
          </div>
        )
      }
    </div>
  );
});

Dropdown.propTypes = {
  options: array,
  selected: oneOfType([shape({
    field: string,
    label: string,
  }), arrayOf(shape({
    field: string,
    label: string,
  }))]),
  disabled: bool,
  withSearch: bool,
  placeholder: string,
  onSelect: func,
  classes: object,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  blurExceptionNodes: arrayOf(shape({ current: instanceOf(Element) })),
  isMultiple: bool,
  onClose: func,
  onSearch: func,
  optionContentRenderer: func,
};

Dropdown.defaultProps = {
  disabled: false,
  options: [],
  selected: [],
  placeholder: 'ALL',
  withSearch: false,
  isMultiple: false,
};
