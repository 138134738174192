import { action } from 'mobx';
import { RowItemStore } from '../abstractStores/RowItemStore';

export class RuleLineItemStore extends RowItemStore {

  @action.bound init(item = {}) {
    this.data = {};
    if (item.fields) {
      item.fields.forEach((el) => {
        if (el === 'ID') {
          this.data[el] = item.id;
        } else {
          this.data[el] = item.item[el];
        }
      });
    }
    this.id = item.id;
    this.isRemoved = item.isRemoved;
    this.isEdited = item.isEdited;
    this.isDraftLine = item.isDraftLine;
    this.isEditMode = false;
    this.isInvalid = false;
    this.defaultEditableFields = item.fields;
  }
}
