import { blue, darkRed, lightGreen, yellowWarning } from '../styles/colors';

export const TYPE_ERROR = 'TYPE_ERROR';
export const TYPE_SUCCESS = 'TYPE_SUCCESS';
export const TYPE_WARNING = 'TYPE_WARNING';
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const PARTIAL_SUCCESS_STATUS = 'PARTIAL_SUCCESS';
export const VERTICAL_POS = 'bottom';
export const HORIZONTAL_POS = 'right';
export const NOTIFICATION_TYPE_ERROR = 'error';
export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_WARNING = 'warn';
export const TYPE_LIFETIME = {
  [TYPE_ERROR]: 15000,
  [TYPE_SUCCESS]: 5000,
};

export const STACK_MAX = 5;

export const AUTO_HIDE_DELAYS = {
  error: 5 * 1000,
  success: 5 * 1000,
};

export const DEFAULT_ERROR_MESSAGE = {
  title: 'Something Went Wrong',
  message: 'There was a server error, try again later',
};

export const SUCCESS_MESSAGES = {
  rulesUploaded: {
    title: 'Success',
    message: 'Rules from file were successfully uploaded!',
  },
  rulesUpdated: {
    title: 'Success',
    message: 'Rules were updated  successfully!',
  },
  ruleCreated: {
    title: 'Success',
    message: 'Rules were created successfully!',
  },
  rulesDeleted: {
    title: 'Success',
    message: 'Rules were deleted successfully!',
  },
};

export const WARNING_MESSAGES = {
  rulesUploaded: {
    title: 'Warnings',
    message: 'Please check results for errors',
  },
};

export const DUPLICATE_MESSAGE = {
  title: 'Duplicate',
  message: 'Rule Entry is Duplicate',
};

export const styleSetting = {
  [PARTIAL_SUCCESS_STATUS]: {
    color: lightGreen,
    iconName: 'success-oval',
  },
  [TYPE_SUCCESS]: {
    color: lightGreen,
    iconName: 'success-oval',
  },
  [TYPE_WARNING]: {
    color: yellowWarning,
    iconName: 'warning-oval',
  },
  [TYPE_ERROR]: {
    color: darkRed,
    iconName: 'failed-oval',
  },
  [IN_PROGRESS_STATUS]: {
    color: blue,
    iconName: 'processing-oval',
  },
};
