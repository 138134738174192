import { observable, action } from 'mobx';
import { get } from 'lodash';
import { UNAUTHORIZED } from 'http-status';
import { API_ERROR_CODES } from '../../constants/common';
import { notificationStore } from '../notification';

export class ErrorStore {
  @observable errors = {};

  @action.bound
  handleError(errorsData) {
    const status = get(errorsData, 'response.status', '');
    const errors = get(errorsData, 'response.data.errors', []);
    if (status === UNAUTHORIZED) {
      this.errors[API_ERROR_CODES.UNAUTHORIZED] = true;
      return Promise.reject();
    }

    const tooManyItemsError = errors.find(({ code }) => code === API_ERROR_CODES.FOUND_TOO_MANY_INVENTORIES);
    const nodeAlreadyPresentError = errors.find(({ code }) => code === API_ERROR_CODES.SHIP_NODE_ALREADY_PRESENT_IN_DG);

    if (tooManyItemsError) {
      const { message } = tooManyItemsError;
      this.errors[API_ERROR_CODES.FOUND_TOO_MANY_INVENTORIES] = message;
    } else if (nodeAlreadyPresentError) {
      const { message } = nodeAlreadyPresentError;
      notificationStore.addError(message);
    } else {
      notificationStore.addError(errors && errors.length ? errors[0].message : errorsData.message);
      console.error(errorsData);
    }

    return Promise.resolve(errors);
  }
}

export const errorStore = new ErrorStore();
