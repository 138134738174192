import { observable, action, runInAction, computed } from 'mobx';
import arraySort from 'array-sort';

import { SORTABLE_FIELDS, RT_ITEMS_PER_PAGE, FIELDS_FOR_BACKEND_SORTING, SORT_ORDER_FOR_BACKEND } from '../../constants/ruleTypes';
import services from '../../services';
import { PageStore } from '../abstractStores/PageStore';
import { RuleItemStore } from './RuleItemStore';
import { getFromToIndexes } from '../../utils/pagination';
import { applySorting } from '../../utils/ruleTypesSortingCalls';
import { RULE_TYPE_NAME } from '../../constants/common';
import { transformRuleTypes } from '../../utils/ruleTypesTransformation';

const {
  ruleTypesService: {
    getRuleTypes,
    activateRuleType,
    removeDraft,
    getFlexRuleTypes,
    getActivationState,
    archiveRuleType,
    sendFeedback,
  },
} = services;

export class RuleTypesStore extends PageStore {
  @observable ruleTypes = null;

  @observable ruleTypesCount = 0;

  @observable pageIndex = 0;

  @observable pagesCount = 0;

  @observable selectedRuleType = null;

  @observable selectedRuleTypes = [];

  @observable selectedRuleTypeVersion = null;

  @observable selectedRuleTypeVersions = [];

  @observable isDraftOnly = false;

  @observable isViewOlderVerisonsChecked = false;

  @observable ruleTypesBackup = null;

  @observable allRuleTypes = [];

  @observable allRuleTypesBackup = [];

  @observable allRuleTypesNames = [];

  @observable allSelectedRuleTypesVersions = [];

  @observable versionsDropdown = [];

  @observable oldValue = null;

  @observable paramsObject = {
    draftOnly: false,
    viewAll: false,
    ruleTypeFilter: [],
    ruleVersionFilter: [],
  };

  @observable feedbackObj = {
    username: '',
    category: '',
    urgency: '',
    message: '',
    attachment: null,
  };

  constructor() {
    super();
    this.sorting.sortableFields = SORTABLE_FIELDS;
  }

  @action.bound
  async getRuleTypes({ anchor, sortBy = this.sorting.sortBy, sortOrder = this.sorting.sortOrder, params }) {
    this.isDataLoading = true;
    let result = true;

    try {
      this.clearTable();
      const legacyRules = await getRuleTypes(anchor, RT_ITEMS_PER_PAGE, sortBy, sortOrder, params);
      const flexRules = await getFlexRuleTypes(params);
      runInAction(() => {
        let flexAndLegacyRuleTypes = [];
        if (this.paramsObject.ruleTypeFilter.length > 0) {
          flexAndLegacyRuleTypes = legacyRules.rules;
        } else if (flexRules != undefined && flexRules.rules != undefined && flexRules.rules.length > 0) {
          flexAndLegacyRuleTypes = [...legacyRules.rules, ...flexRules.rules];
        } else {
          flexAndLegacyRuleTypes = legacyRules.rules;
        }
        const ruleTypesFromBackend = flexAndLegacyRuleTypes.map(ruleType => {
          return ruleType.versions.map(el => {
            return new RuleItemStore({
              ruleTypeName: ruleType.ruleType,
              displayVersion: el.displayVersion ? el.displayVersion : el.version === 'DRAFT' ? `Draft (based on v.${el.basedOnVersion})` : `Version ${el.version}`,
              version: el.version === 'DRAFT' ? 'Draft' : el.version,
              isActive: el.active,
              isPending: false,
              lastModified: el.modificationDateTime,
              editor: el.modifiedBy,
              basedOn: el.basedOnVersion,
              isDraft: el.version === 'DRAFT',
            });
          });
        });
        if (params && params.ruleTypeFilter) {
          this.allRuleTypes = transformRuleTypes(ruleTypesFromBackend);
          this.allRuleTypesNames = [...this.allRuleTypesNames, ...this.allRuleTypes.map((el) => el.data[RULE_TYPE_NAME])];
          this.allRuleTypesNames = [...new Set(this.allRuleTypesNames)].sort();
          if (this.paramsObject.sort) {
            this.ruleTypes = this.allRuleTypes;
          } else {
            this.ruleTypes = applySorting(this.allRuleTypes);
          }
        }
        if (params && params.viewAll) {
          this.allRuleTypes = transformRuleTypes(ruleTypesFromBackend, this.allRuleTypes);
          this.allRuleTypesNames = [...this.allRuleTypesNames, ...this.allRuleTypes.map((el) => el.data[RULE_TYPE_NAME])];
          this.allRuleTypesNames = [...new Set(this.allRuleTypesNames)].sort();
          if (this.paramsObject.sort) {
            this.ruleTypes = this.allRuleTypes;
          } else {
            this.ruleTypes = applySorting(this.allRuleTypes);
          }
        }
        if (this.allRuleTypes.length === 0) {
          this.allRuleTypes = transformRuleTypes(ruleTypesFromBackend, this.allRuleTypes);
          this.allRuleTypesNames = [...this.allRuleTypesNames, ...this.allRuleTypes.map((el) => el.data[RULE_TYPE_NAME])];
          this.allRuleTypesNames = [...new Set(this.allRuleTypesNames)].sort();
          if (this.paramsObject.sort) {
            this.ruleTypes = this.allRuleTypes;
          } else {
            this.ruleTypes = applySorting(this.allRuleTypes);
          }
        }
        if (this.selectedRuleTypes.length > 0) {
          this.pageIndex = 0;
          this.pagesCount = 0;
          const { from, to } = getFromToIndexes(anchor, RT_ITEMS_PER_PAGE, this.allRuleTypes.length);
          if (sortOrder === 'NONE') {
            this.ruleTypes = applySorting(this.allRuleTypes);
          }
          if (this.paramsObject.sort) {
            this.ruleTypes = this.allRuleTypes.slice(from, to + 1);
          } else {
            this.ruleTypes = this.ruleTypes.slice(from, to + 1);
          }
          this.ruleTypesCount = this.allRuleTypes.length;
          if (this.ruleTypesCount > 50) {
            this.pagesCount = Math.ceil(this.ruleTypesCount / RT_ITEMS_PER_PAGE);
            this.pageIndex = anchor;
          }
        } else {
          const { from, to } = getFromToIndexes(anchor, RT_ITEMS_PER_PAGE, this.allRuleTypes.length);
          this.ruleTypesCount = this.allRuleTypes.length;
          if (this.ruleTypes.length > 50) {
            this.allRuleTypes = this.ruleTypes;
          }
          this.ruleTypes = this.allRuleTypes.slice(from, to + 1);
          this.pagesCount = Math.ceil(this.ruleTypesCount / RT_ITEMS_PER_PAGE);
          this.pageIndex = anchor;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.sorting.sortOrder = 'NONE';
      });
      result = false;
      console.error(error);
    }

    runInAction(() => {
      this.isDataLoading = false;
    });

    return result;
  }

  @action.bound
  clearTable() {
    this.allRuleTypes = [];
    this.ruleTypes = [];
  }

  @action.bound
  clearHomePageFilters() {
    this.selectedRuleTypes = [];
    this.selectedRuleTypeVersions = [];
    this.selectedRuleTypeVersion = null;
    this.selectedRuleType = null;
  }

  @action.bound
  onSortApply(sortBy, sortOrder) {
    this.sorting.sortOrder = sortOrder;
    this.sorting.sortBy = sortBy;
    if (sortOrder === 'NONE') {
      delete this.paramsObject.sort;
    } else {
      this.paramsObject.sort = FIELDS_FOR_BACKEND_SORTING[this.sorting.sortBy] + SORT_ORDER_FOR_BACKEND[this.sorting.sortOrder];
    }
    this.getRuleTypes({
      anchor: this.pageIndex,
      params: this.paramsObject,
    });
  }

  @action.bound
  setVersionsDropdown(newValue) {
    if (newValue) {
      this.versionsDropdown = newValue;
    }
  }

  @action.bound
  deselectAllItems() {
    this.ruleTypes.forEach(ruleType => ruleType.setSelectionState(false));
  }

  @action.bound
  getRuleTypesWithViewAll() {
    if (this.paramsObject.viewAll) {
      this.allRuleTypes = [];
    }
    this.paramsObject.viewAll = !this.paramsObject.viewAll;
    this.getRuleTypes({
      anchor: this.pageIndex,
      params: this.paramsObject,
    });
  }

  @action.bound
  toggleItem({ id }) {
    const ruleType = this.ruleTypes.find(item => item.id === id);
    const prevItemState = ruleType.isSelected;
    this.deselectAllItems();
    ruleType.setSelectionState(!prevItemState);
  }

  @action.bound
  getRuleTypesWithFilters() {
    const names = this.selectedRuleTypes.map((el) => el).filter((item) => item !== 'ALL');
    this.paramsObject.ruleTypeFilter = names.join(',');
    if (this.paramsObject.ruleTypeFilter === '') {
      this.paramsObject.ruleTypeFilter = [];
    }
    if (this.selectedRuleTypeVersion) {
      if (this.selectedRuleTypeVersion === 'ALL') {
        this.paramsObject.ruleVersionFilter = this.versionsDropdown.map(el => el.match(/\d/g).join(''));
      } else {
        this.paramsObject.ruleVersionFilter = [this.selectedRuleTypeVersion.match(/\d/g).join('')];
      }
    }
    this.getRuleTypes({
      anchor: this.pageIndex,
      params: this.paramsObject,
    });
  }

  @action.bound
  setFilterOptions(arr, field, option) {
    this.pageIndex = 0;
    let found = null;
    if (field === 'ALL' && option !== 'version') {
      if (this.selectedRuleTypes.length > 0) {
        this.selectedRuleTypes = [];
        return;
      }
      this.selectedRuleTypes = this.allRuleTypesNames;
      return;
    }
    if (field === 'ALL' && option === 'version') {
      if (this.selectedRuleTypeVersion) {
        this.selectedRuleTypeVersion = 'ALL';
      }
      this.allSelectedRuleTypesVersions = this.versionsDropdown;
    }
    if (option === 'version') {
      found = this.allSelectedRuleTypesVersions.filter(item => {
        return item === field;
      });
    } else {
      found = this.allRuleTypesNames.filter(item => item === field);
    }
    if (found) {
      if (option === 'version') {
        found.forEach((foundRuleType) => {
          if (this.selectedRuleTypeVersion === foundRuleType) {
            this.selectedRuleTypeVersion = null;
          } else {
            this.selectedRuleTypeVersion = foundRuleType;
          }
        });
      } else {
        found.forEach((foundRuleType) => {
          if (this.selectedRuleTypes.find((el) => el === foundRuleType)) {
            this.selectedRuleTypes = this.selectedRuleTypes.filter((el) => el !== foundRuleType);
          } else {
            this.selectedRuleTypes.push(foundRuleType);
          }
        });
      }
    }
  }

  @action.bound
  updateRuleTypesState(newValue) {
    if (newValue) {
      this.ruleTypesBackup = this.ruleTypes;
      this.ruleTypes = newValue;
    }
  }

  @action.bound
  updateAllVersionsRuleTypeState(newValue) {
    if (newValue) {
      this.allSelectedRuleTypesVersions = newValue;
      this.versionsDropdown = newValue;
    }
  }

  @action.bound
  resetFiltersState() {
    this.selectedRuleTypes = [];
    this.selectedRuleTypeVersion = null;
    this.paramsObject.ruleTypeFilter = [];
    this.paramsObject.ruleVersionFilter = [];
    this.paramsObject.draftOnly = false;
    this.paramsObject.viewAll = false;
    delete this.paramsObject.sort;
    this.isDraftOnly = false;
    this.sorting.sortOrder = 'NONE';
    this.getRuleTypes({
      anchor: this.pageIndex,
      params: this.paramsObject,
      sortBy: 'ruleTypeName',
      sortOrder: 'NONE',
    });
    window.localStorage.clear();
  }

  @action.bound
  activateRuleType({ ruleType, versionNumber, isRulePage = false }) {
    activateRuleType({ ruleType, versionNumber });
    if (!isRulePage) {
      const found = this.allRuleTypes.find((el) => {
        return el.data.ruleTypeName === ruleType && el.data.version.match(/\d/g).join('') === versionNumber;
      });
      if (found) {
        found.isPending = true;
        found.data.version += 'Pending activation';
        this.ruleTypes = arraySort(this.ruleTypes, ['isActive', 'isPending']).reverse();
      }
      let isActivated = '';
      let interval = setInterval(async () => {
        isActivated = await getActivationState(ruleType);
        if (isActivated.state === 'ACTIVATED') {
          found.isPending = false;
          this.getRuleTypes({
            anchor: this.pageIndex,
            params: this.paramsObject,
            sortBy: 'ruleTypeName',
            sortOrder: 'NONE',
          });
          clearInterval(interval);
          interval = 0;
        }
      }, 2000);
    }
  }

  @action.bound
  async archiveRule(ruleTypeName, ruleTypeVersion) {
    const res = await archiveRuleType({ ruleTypeName, ruleTypeVersion });
    this.getRuleTypes({
      anchor: this.pageIndex,
      params: this.paramsObject,
      sortBy: 'ruleTypeName',
      sortOrder: 'NONE',
    });
    return res;
  }

  @action.bound
  removeDraft({ ruleType, versionNumber }) {
    removeDraft({ ruleType, versionNumber });
    setTimeout(() => {
      this.getRuleTypes({
        anchor: this.pageIndex,
        params: this.paramsObject,
        sortBy: 'ruleTypeName',
        sortOrder: 'NONE',
      });
    }, 2000);
  }

  @action.bound
  filterForDraftOnly() {
    this.isDraftOnly = !this.isDraftOnly;
    this.paramsObject.draftOnly = this.isDraftOnly;
    this.allRuleTypes = [];
    this.pageIndex = 0;
    this.getRuleTypes({
      anchor: this.pageIndex,
      params: this.paramsObject,
    });
  }

  @action.bound
  unselectItems() {
    this.ruleTypes.forEach((el) => {
      return el.isSelected ? el.isSelected = false : el.isSelected = false;
    });
  }

  @action.bound
  sendFeedback() {
    return sendFeedback(this.feedbackObj);
  }

  @computed
  get selectedItems() {
    return this.ruleTypes ? this.ruleTypes.filter(el => el.isSelected) : [];
  }
}
