import { action, computed, observable } from 'mobx';
import uniqueId from 'lodash/uniqueId';

export class RowItemStore {
  constructor(item) {
    this.init(item);
  }

  // this is created for testing purposes,
  // we cannot mock computed values
  defaultEditableFields = [];

  defaultHighlightedFields = [];

  @observable data = {};

  @observable changedFields = {};

  @observable isSelected = false;

  // This is here to make more obvious what api is common for row stores
  @computed get editableFields() {
    return this.defaultEditableFields;
  }

  @computed get highlightedFields() {
    return this.defaultHighlightedFields;
  }

  @computed get rowItem() {
    return { ...this.data, ...this.changedFields };
  }

  @computed get wasChanged() {
    return Object.keys(this.changedFields).some(el => this.editableFields.includes(el) && this.data[el] !== this.changedFields[el]);
  }

  @action.bound toggleSelect() {
    this.isSelected = !this.isSelected;
  }

  @action.bound setSelectionState(newState) {
    this.isSelected = newState;
  }

  @action.bound init(item) {
    this.data = item;
    this.id = uniqueId();
  }

  @action.bound resetChanges() {
    this.changedFields = {};
  }

  @action.bound mergeChanges() {
    this.data = { ...this.data, ...this.changedFields };
  }

  @action.bound changeField(fieldName, value) {
    if (!this.editableFields.includes(fieldName)) return;

    this.changedFields[fieldName] = value;
  }
}
