import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory, useLocation } from 'react-router';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '../../components/svg-icon';
import { useStores } from '../../custom-hooks/store/use-stores';
import { BackToRuletypesLabel } from '../../components/back-to-ruletypes-label';
import { RuleLineInfo } from '../../components/RuleLineInfo';
import { RuleLineTableHeading } from './rule-line-table-heading';
import { FilterLabel } from '../../components/filter-label';
import { ControlTabs } from '../../components/ControlTabs/ControlTabs';
import { Dialog } from '../../components/dialog';
import { TextInput } from '../../components/text-input';
import { Table } from '../../components/table';
import { getColumnSize } from '../../utils/columnSetting';
import { FilterBox } from '../../components/filter-box';
import { AddRuleLine } from '../../components/add-rule-line/AddRuleLine';
import { CONTINUE, CANCEL } from '../../constants/common';
import { Dropdown } from '../../components/dropdown';
import { Button, buttonTypes } from '../../components/button';
import { UploadFile } from '../../components/upload-file';
import { UploadDialog } from '../../components/upload-dialog/UploadDialog';
import { black, red, white, lighterGray } from '../../styles/colors';
import { ActivateRuleTypeVersionDialog } from '../../components/activate-rule-types-version';

import '../../assets/icons/upload.svg';

const useStyles = makeStyles(() => ({
  backContainer: {
    position: 'absolute',
    width: '100%',
    left: 0,
  },
  ruleLineInfoContainer: {
    marginTop: 80,
    marginLeft: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
  downloadFileContainer: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: 30,
    marginLeft: 18,
    width: 430,
    fontSize: 14,
    cursor: 'pointer',
  },
  tabsContainer: {
    width: 'calc(100% - 18px)',
    borderBottom: `2px solid ${lighterGray}`,
    marginTop: 20,
    marginLeft: 20,
  },
  parametersContainer: {
    marginTop: 26,
    marginLeft: 20,
    marginBottom: 10,
  },
  textSearchField: {
    height: 40,
    width: 270,
    '& input': {
      borderLeft: 'none',
      width: 260,
      height: 40,
      paddingLeft: 12,
      fontSize: 14,
    },
  },
  icon: {
    left: 230,
  },
  textInputLabel: {
    fontSize: 18,
    fontWeight: 700,
    color: '#757575',
    marginBottom: 20,
  },
  tableContainer: {
    height: '100%',
    marginLeft: 20,
  },
  buttonWrapper: {},
  button: {
    width: 192,
    height: 40,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '0 25px',
  },
  body: {
    maxWidth: 500,
    padding: '0 48px',
  },
  dialog: {
    maxWidth: 500,
  },
  popup: {
    padding: '55px 0',
  },
  header: {
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'static',
    padding: '24px 0 48px',
  },
  headerContent: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 18,
    fontWeight: 700,
  },
  swoosh: {
    marginTop: 0,
    marginBottom: 0,
  },
  dropdownWithFiltersContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  dropdownRoot: {
    width: 150,
    height: 40,
    fontSize: 14,
    color: '#757575',
  },
  dropdownRootBlack: {
    width: 150,
    height: 40,
    fontSize: 14,
    color: black,
  },
  dropdownSelectedField: {
    height: 40,
  },
  dropdownOption: {
    color: black,
  },
  dropdownArrow: {
    fill: '#757575',
  },
  dropdownArrowBlack: {
    fill: black,
  },
  searchButton: {
    height: 40,
    width: 116,
    textTransform: 'none',
  },
  uploadHeader: {
    color: 'white',
    border: 'none',
  },
  uploadFilePopupButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  uploadDialogButton: {
    width: 192,
    height: 40,
  },
  quitHeader: {
    color: white,
    border: 'none',
  },
  quitCross: {
    position: 'absolute',
    top: 29,
    right: 29,
  },
  quitSwoosh: {
    marginTop: 60,
    marginBottom: 48,
  },
  quitText: {
    fontSize: 18,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  quitPopup: {
    width: 500,
    padding: '15px 5px 36px',
  },
  errorText: {
    color: red,
    width: '100%',
  },
  errorCont: {
    fontSize: 18,
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'column',
    color: black,
  },
  archiveButton: {
    width: 150,
    height: 40,
    borderRadius: 30,
    border: 'none',
    background: '#F9F9F9',
    color: black,
  },
  activateButton: {
    width: 150,
    height: 40,
    borderRadius: 30,
  },
  controlButtonsContainer: {
    display: 'flex',
    width: 330,
    marginTop: 15,
    justifyContent: 'space-between',
  },
  textField: {
    '& input': {
      fontWeight: 700,
      fontSize: 14,
    },
  },
  infoContainer: {
    color: '#666666',
  },
  textDivider: {
    marginBottom: 30,
  },
  textAndInputDivider: {
    marginBottom: 24,
  },
  strongWords: {
    fontWeight: 500,
    color: black,
  },
}));

export const RuleLine = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const ruleTypeName = queryParams.get('ruleTypeName');
  const ruleTypeVersion = queryParams.get('ruleTypeVersion');
  const isDraft = queryParams.get('isDraft') === 'true';
  const basedOnVersion = queryParams.get('basedOnVersion');
  const isActiveRule = queryParams.get('isActive') === 'true';
  const [selectedTab, setSelectedTab] = useState('INPUTS');
  const [searchValue, setSearchValue] = useState('');
  const [filtersData, setFiltersData] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [newLineObject, setNewLineObject] = useState({});
  const [isAddButtonActive, setIsAddButtonActive] = useState(false);
  const [isSaveAsNewDialogOpen, setIsSaveAsNewDialogOpen] = useState(false);
  const [selectedParam, setSelectedParam] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isDraftHasModifications, setIsDraftHasModifications] = useState(true);
  const [isRulesLoaded, setIsRulesLoaded] = useState('');
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isUploadFileApplyDisabled, setIsUploadFileApplyDisabled] = useState(true);
  const [isQuitFromUploadOpen, setIsQuitFromUploadOpen] = useState(false);
  const [isErrorFromUploadOpen, setIsErrorFromUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [inputParams, setInputParams] = useState([]);
  const [outputParams, setOutputParams] = useState([]);
  const [isFlexRT, setIsFlexRt] = useState(false);
  const [isActivationDialogOpen, setIsActivationDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const isSearch = true;
  const isRulePage = true;

  const {
    ruleLineStore: {
      getRuleLine,
      ruleLineRowsHeadings,
      ruleLines,
      ruleLinesCount,
      toggleItem,
      removeItem,
      selectedItems,
      removedRuleLineItems,
      sorting,
      isDraftExists,
      isDataLoading,
      revertSelectionStateForAllItems,
      pageIndex,
      pagesCount,
      onSortApply,
      clearTable,
      clearPageIndexes,
      params,
      addDraftLine,
      deleteDraftLine,
      removeDraftLine,
      finalizeDraft,
      addedLines,
      clearAddRuleLines,
      finalizedVersion,
      countOfLinesWithMetaTypeDraft,
      clearCountOfDraftLines,
      setEditModeById,
      editCell,
      changeValueByFieldName,
      changedRowsIds,
      updateLine,
      isUpdated,
      clearEditCell,
      invalidFields,
      downloadFile,
      cancelEditModeById,
      createDraft,
      hasModifications,
      isInvalidFieldsPresent,
      uploadFile,
      downloadYamlFile,
      getUrlFromYaml,
      getJsonData,
      jsonData,
      clearJsonData,
    },
    ruleTypesStore: {
      clearHomePageFilters,
      activateRuleType,
      archiveRule,
    },
    notificationStore: {
      addWarning,
      addSuccess,
      addError,
    },
    uiStore: {
      userPersonalInfo,
      userPermissions,
    },
    addRyleTypeStore: {
      getRuleFlexTypes,
    },
  } = useStores();

  useEffect(() => {
    setInputParams(ruleLineRowsHeadings.filter((el) => el.inOrOut === 'IN'));
    setOutputParams(ruleLineRowsHeadings.filter((el) => el.inOrOut === 'OUT'));
  }, [ruleLineRowsHeadings && ruleLineRowsHeadings.length]);

  useEffect(() => {
    clearJsonData();
    const getJsonDataAsync = async () => {
      await getJsonData(ruleTypeName, ruleTypeVersion, basedOnVersion.match(/\d/g).join(''));
    };
    getJsonDataAsync();
    const getFlexRts = async () => {
      const rTypes = await getRuleFlexTypes();
      const index = ruleTypeName.indexOf('-');
      let rtName = '';
      if (index) {
        rtName = ruleTypeName.substr(0, index);
      } else {
        rtName = ruleTypeName;
      }
      if (rTypes != undefined && Object.keys(rTypes).includes(rtName)) {
        setIsFlexRt(true);
      }
    };
    getFlexRts();
  }, []);

  useEffect(() => {
    if (!isDraft) {
      const getJsonDataAsync = async () => {
        await getJsonData(ruleTypeName, ruleTypeVersion);
      };
      getJsonDataAsync();
    }
  }, [ruleTypeName, ruleTypeVersion, ruleLinesCount]);

  useEffect(() => {
    const getIsRuleLinesSuccesfullyLoaded = async () => {
      const res = await getRuleLine(pageIndex * 50, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, { count: 50 });
      setIsRulesLoaded(res ? 'yes' : 'no');
    };
    getIsRuleLinesSuccesfullyLoaded();
    if (isRulesLoaded === 'no') {
      window.location.reload();
    }
  }, [getRuleLine, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, setIsRulesLoaded]);

  const onCellClickIfDraftExists = useCallback(() => {
    if (isDraftExists) {
      addWarning(`<span>The draft of this version already exists <a style="color:#5A90DC; text-decoration: underline;" href=${createUrl()}>link</a></span>`);
    } else {
      addWarning('<span>Please create draft to change this rule type</span>');
    }
  }, [addWarning, createUrl, isDraftExists]);

  const onCheckboxClick = useCallback((rowData) => {
    toggleItem(rowData);
  }, [toggleItem]);

  const onBackToRuletypesClick = useCallback(() => {
    delete params.anchor;
    localStorage.clear();
    clearPageIndexes();
    clearAddRuleLines();
    clearCountOfDraftLines();
    clearHomePageFilters();
    history.push('/mpruleengineui/ruletypes');
  }, [history]);

  const paginateForth = () => {
    getRuleLine((pageIndex * 50) + 50, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params);
  };

  const paginateBack = () => {
    if (pageIndex === 1) {
      delete params.anchor;
    }
    getRuleLine((pageIndex * 50) - 50, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params);
  };

  const applySorting = (sortField, sortOrder) => {
    onSortApply(sortField, sortOrder, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft);
  };

  const createUrl = () => {
    const url = '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: ruleTypeName, ruleTypeVersion: 'Draft', isDraft: true, basedOnVersion: ruleTypeVersion });
    return url;
  };

  const createDraftUrl = () => {
    const url = '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: ruleTypeName, ruleTypeVersion: ruleTypeVersion, isDraft: true, basedOnVersion: basedOnVersion });
    return url;
  };

  const openUploadDialog = useCallback(() => {
    setIsFilePicked(false);
    setSelectedFile(null);
    setIsUploadDialogOpen(true);
  }, [setIsUploadDialogOpen, setIsFilePicked, setSelectedFile]);
  const closeUploadDialog = useCallback(() => setIsUploadDialogOpen(false), [setIsUploadDialogOpen]);

  const openQuitUploadDialog = useCallback(() => setIsQuitFromUploadOpen(true), [setIsQuitFromUploadOpen]);
  const closeQuitUploadDialog = useCallback(() => setIsQuitFromUploadOpen(false), [setIsQuitFromUploadOpen]);

  const openErrorUploadDialog = useCallback(() => setIsErrorFromUploadOpen(true), [setIsErrorFromUploadOpen]);
  const closeErrorUploadDialog = useCallback(() => {
    setIsErrorFromUploadOpen(false);
    setSelectedFile(null);
    setIsFilePicked(false);
    setIsSaveClicked(false);
    setProgress(0);
  }, [setIsErrorFromUploadOpen, setSelectedFile, setIsFilePicked, setProgress, setIsSaveClicked]);

  const openAddDialog = useCallback(() => setIsAddDialogOpen(true), [setIsAddDialogOpen]);

  const closeUploadAndQuitUploadDialogs = useCallback(() => {
    closeQuitUploadDialog();
    closeUploadDialog();
  }, [closeUploadDialog, closeQuitUploadDialog]);

  const closeAddDialog = useCallback(() => {
    setIsAddDialogOpen(false);
    setIsAddButtonActive(false);
    setNewLineObject({});
  }, [setIsAddDialogOpen, setIsAddButtonActive, setNewLineObject]);

  const onAddDraftLine = async () => {
    const response = await addDraftLine(ruleTypeName, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion, userPersonalInfo.email, newLineObject);
    setNewLineObject({});
    if (response.status === 200) {
      addSuccess('New line successfully added');
      setTimeout(() => getRuleLine(pageIndex * 50, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params), 1500);
    }
    if (response.status === 400) {
      addError(response.data.errors[0].code);
    }
  };

  const onDuplicateClick = async (items) => {
    items.forEach(async (item) => {
      const response = await addDraftLine(ruleTypeName, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion, userPersonalInfo.email, { ...item.data });
      if (response.status === 200) {
        addSuccess('Line was successfully duplicated');
        setTimeout(() => getRuleLine(pageIndex * 50, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params), 1500);
      } else if (response.status === 400) {
        addError(response.data.errors[0].code);
      }
    });
  };

  const setFilterParam = useCallback((e) => {
    if (e.keyCode === 13) {
      const parsedInput = e.target.value;
      if (selectedParam && parsedInput) {
        const name = selectedParam;
        const value = parsedInput.toUpperCase();
        const isFilterExist = filtersData.find((el) => el.name === name && el.value === value);
        if (isFilterExist) {
          addWarning('<span>Dont clone your parameters!</span>');
        } else {
          setFiltersData([
            ...filtersData,
            {
              name: name,
              value: value,
            },
          ]);
          setSelectedParam('');
          if (params.filter) {
            params.filter += `&filter=${name}(${value})`;
          } else {
            params.filter = `${name}(${value})`;
          }
          setSearchValue('');
          clearTable();
          getRuleLine(pageIndex * 50, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params);
        }
      } else {
        addWarning('Please choose name and enter value');
      }
    }
  }, [ruleTypeName, ruleTypeVersion, filtersData, setFiltersData, getRuleLine, selectedParam]);

  const clearFilters = useCallback((name, value) => {
    const filters = filtersData.filter((el) => {
      let result = true;
      if (el.name === name && el.value === value) {
        result = false;
      }
      return result;
    });
    setFiltersData(filters);
    if (filtersData.length === 1 && ruleLines.length === 0) {
      delete params.filter;
      delete params.sort;
    } else {
      params.filter = params.filter.split('&filter=').filter((el) => el !== `${name}(${value})`).join('&filter=');
      if (params.filter === '') {
        delete params.filter;
      }
    }
    getRuleLine(pageIndex * 50, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params);
  }, [setFiltersData, filtersData, filtersData.length, getRuleLine, ruleTypeName, ruleTypeVersion, basedOnVersion, isDraft, params]);

  const updateDraftLineObject = useCallback((newValue, field, type) => {
    const skipObj = ruleLineRowsHeadings.find((el) => el.label === field);
    if (!skipObj.skip) {
      if (type === 'integer') {
        const filteredValue = newValue.match(/\d/g);
        const isValid = filteredValue ? filteredValue.length === newValue.length : false;
        if (isValid) {
          setNewLineObject((prevState) => ({ ...prevState, [field]: newValue }));
        } else {
          addError('Invalid input');
        }
      } else {
        setNewLineObject((prevState) => ({ ...prevState, [field]: newValue }));
      }
    }
  }, [setNewLineObject, ruleLineRowsHeadings, addError]);

  useEffect(() => {
    if (ruleLineRowsHeadings.filter((el) => el.required)) {
      const requiredFields = ruleLineRowsHeadings.filter((el) => el.required);
      const isRequiredFieldsEntered = requiredFields.every((el) => newLineObject[el.label]);
      setIsAddButtonActive(isRequiredFieldsEntered);
    }
  }, [newLineObject, setIsAddButtonActive, ruleLineRowsHeadings]);

  useEffect(() => {
    if (ruleLineRowsHeadings.filter((el) => !el.required)) {
      const optionalFields = ruleLineRowsHeadings.filter((el) => !el.required);
      optionalFields.forEach((el) => {
        if (!newLineObject[el.label] && !el.skip) {
          newLineObject[el.label] = '';
        }
      });
    }
  }, [newLineObject, ruleLineRowsHeadings]);

  const onRemoveClick = async (items) => {
    items.forEach(async (item) => {
      let response = null;
      if (item.isDraftLine) {
        response = await removeDraftLine(ruleTypeName, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion, [item.id]);
      } else {
        response = await deleteDraftLine(ruleTypeName, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion, userPersonalInfo.email, [item]);
      }
      if (response.status === 200) {
        removeItem([item]);
        addSuccess('Line was successfully removed');
      }
    });
  };

  const onApplySaveAsNew = useCallback(async () => {
    const { newVersion, isSaved, message } = await finalizeDraft(ruleTypeName, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion);
    setIsSaveAsNewDialogOpen(false);
    localStorage.clear();
    clearAddRuleLines();
    if (newVersion) {
      addSuccess('New version was successfully created! You will be redirected to new version page in 10 sec.');
      setTimeout(() => {
        const url = '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: ruleTypeName, ruleTypeVersion: newVersion, isDraft: false, basedOnVersion: 'Original' });
        history.push(url);
      }, 10000);
    }
    if (!isSaved && message !== '') {
      addError(message);
    }
  }, [setIsSaveAsNewDialogOpen, finalizeDraft, ruleTypeVersion, ruleTypeName, finalizedVersion, clearAddRuleLines]);

  const openSaveAsNewDialog = useCallback(() => setIsSaveAsNewDialogOpen(true), [setIsSaveAsNewDialogOpen]);

  const closeSaveAsNewDialog = useCallback(() => setIsSaveAsNewDialogOpen(false), [setIsSaveAsNewDialogOpen]);

  const onCellClickToChangeValues = useCallback((id, fieldName) => setEditModeById(id, fieldName), [setEditModeById]);

  const openArchiveDialog = useCallback(() => setIsArchiveDialogOpen(true), [setIsArchiveDialogOpen]);
  const closeArchiveDialog = useCallback(() => setIsArchiveDialogOpen(false), [setIsArchiveDialogOpen]);
  const openActivationDialog = useCallback(() => setIsActivationDialogOpen(true), [setIsActivationDialogOpen]);
  const closeActivationDialog = useCallback(() => setIsActivationDialogOpen(false), [setIsActivationDialogOpen]);
  const closeOnCross = useCallback(() => setIsActivationDialogOpen(false), [setIsActivationDialogOpen]);

  useEffect(() => {
    if (isDraft) {
      let res = false;
      const getHasModifications = async () => {
        res = await hasModifications(ruleTypeName, basedOnVersion.match(/\d/g).join(''));
        setIsDraftHasModifications(res);
      };
      getHasModifications();
    }
  }, [ruleTypeName, basedOnVersion, isDraft]);

  const onKeyDownWhenChangeValuesInCell = async (keyCode) => {
    if (keyCode === 13) {
      if (changedRowsIds && changedRowsIds.length > 0) {
        const ruleLine = ruleLines.find((el) => el.id === changedRowsIds[0]);
        const response = await updateLine(ruleTypeName, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : ruleTypeVersion, ruleLine.id, ruleLine.rowItem);
        if (response.status === 200) {
          addSuccess('Your changes have been successfully applied');
        }
        if (response.status === 400) {
          addError('Invalid input');
        }
      } else {
        addWarning('No changes found');
      }
    } else if (keyCode === 27) {
      clearEditCell();
    }
  };

  const handleFileUpload = async () => {
    const res = await uploadFile(ruleTypeName, selectedFile, setProgress);
    if ((!!res[0] && res[0].code) || !!res[0] && res[0].message) {
      setUploadError(res[0].message);
      openErrorUploadDialog();
    } else if (res.status === 200) {
      addSuccess('New version is created!');
      setIsUploadDialogOpen(false);
      const { version } = res.data;
      setTimeout(() => {
        const url = '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: ruleTypeName, ruleTypeVersion: version, isDraft: false, basedOnVersion: 'Original' });
        history.push(url);
      }, 2000);
    }
  };

  const getCorrectVersionDisplay = (version) => {
    if (version) {
      if (version.match(/\d/g) === null) {
        return version;
      }
      return version.match(/\d/g).join('');
    }
  };

  const activateVersion = useCallback((name, version) => {
    activateRuleType({
      ruleType: name,
      versionNumber: version.match(/\d/g).join(''),
      isRulePage: true,
    });
  }, [activateRuleType]);

  return (
    <Fragment>
      <div className={classes.backContainer}>
        <BackToRuletypesLabel id="backToRuleTypesId" text="← Back to ruletypes" onClick={onBackToRuletypesClick} />
      </div>
      <div className={classes.ruleLineInfoContainer}>
        <RuleLineInfo
          ruleTypeName={ruleTypeName}
          ruleTypeVersion={ruleTypeVersion === 'Draft' ? basedOnVersion : ruleTypeVersion}
          basedOnVersion={basedOnVersion}
          isDraft={isDraft}
          isDraftExists={isDraftExists || isDraft}
          url={createUrl()}
          ruleTypeDescription=""
          inputParams={inputParams}
          outputParams={outputParams}
          isFlexRT={isFlexRT}
          downloadYamlFile={downloadYamlFile}
          isUserMetaDataAdmin={userPermissions && userPermissions.includes('METADATA_EDITOR')}
          getRuleTypeUrl={getUrlFromYaml}
          addSuccess={addSuccess}
        />
        {isActiveRule || isDraft ? null : (
          <div className={classes.controlButtonsContainer}>
            <Button
              type={buttonTypes.PRIMARY}
              label="Archive"
              isRounded
              classes={{ button: classes.archiveButton }}
              onClick={openArchiveDialog}
              height={32}
            />
            <Button
              type={buttonTypes.PRIMARY}
              label="Activate"
              isRounded
              classes={{ button: classes.activateButton }}
              onClick={openActivationDialog}
              height={32}
            />
          </div>
        )}
      </div>

      <div className={classes.downloadFileContainer}>
        <FilterLabel
          id="downloadFileId"
          isUnderline={false}
          label="Download Rule File"
          icon={(
            <SvgIcon
              onClick={() => downloadFile(ruleTypeName, ruleTypeVersion, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : basedOnVersion)}
              width={20}
              height={20}
              name="download"
            />
          )}
          onClick={() => downloadFile(ruleTypeName, ruleTypeVersion, ruleTypeVersion === 'Draft' ? basedOnVersion.match(/\d/g).join('') : basedOnVersion)}
        />
        {!isDraft ? (
          <FilterLabel
            id="uploadFileId"
            isUnderline={false}
            onClick={openUploadDialog}
            label="Upload Rule File"
            icon={(
              <SvgIcon
                onClick={openUploadDialog}
                width={20}
                height={20}
                name="upload"
              />
            )}
          />
        ) : null}
      </div>
      <div className={classes.tabsContainer}>
        <ControlTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
      {selectedTab === 'JSON VIEWER'
        ? (
          <ReactJson
            style={{
              maxHeight: 500,
              overflow: 'scroll',
              marginTop: 36,
              marginLeft: 48,
            }}
            src={JSON.parse(JSON.stringify({ data: jsonData }))}
            displayDataTypes={false}
            enableClipboard={() => navigator.clipboard.writeText(jsonData && jsonData.join('\n'))}
          />
        )
        : (
          <Fragment>
            <div className={classes.parametersContainer}>
              <p className={classes.textInputLabel}>PARAMETERS</p>
              <div className={classes.dropdownWithFiltersContainer}>
                <Dropdown
                  id="rulePageParamNameDropdownId"
                  withSearch
                  disabled={ruleLineRowsHeadings && ruleLineRowsHeadings.length === 0}
                  placeholder="Param name"
                  classes={{
                    root: selectedParam ? classes.dropdownRootBlack : classes.dropdownRoot,
                    selectedField: classes.dropdownSelectedField,
                    option: classes.dropdownOption,
                    arrow: selectedParam ? classes.dropdownArrowBlack : classes.dropdownArrow,
                  }}
                  selected={selectedParam}
                  options={ruleLineRowsHeadings.map((el) => el.field).filter((option) => {
                    let result = true;
                    filtersData.forEach((el) => {
                      if (el.name === option) {
                        result = false;
                      }
                    });
                    return result;
                  })}
                  nameOrVersion="rulePage"
                  onSelect={(e) => {
                    setSelectedParam(e);
                    setIsFocused(true);
                    setTimeout(() => {
                      setIsFocused(false);
                    }, 0);
                  }}
                />
                <TextInput
                  id="rulePageSearchInputId"
                  focus={isFocused}
                  value={searchValue}
                  disabled={selectedParam === ''}
                  onChange={setSearchValue}
                  onKeyDown={setFilterParam}
                  isSearch={isSearch}
                  classes={{
                    textField: classes.textSearchField,
                    icon: classes.icon,
                  }}
                  placeholder="Search"
                />
                <Button
                  id="rulePageSearchButtonId"
                  type={buttonTypes.PRIMARY}
                  label="Search"
                  classes={{ button: classes.searchButton }}
                  onClick={() => setFilterParam({ keyCode: 13, target: { value: searchValue } })}
                  disabled={!selectedParam || !searchValue}
                  height={32}
                />

              </div>
              {filtersData.map((el) => {
                return <FilterBox name={el.name} value={el.value} onClick={clearFilters} />;
              })}
            </div>
            <div className={classes.tableContainer}>
              <RuleLineTableHeading
                itemsCount={ruleLines && !ruleLines.length ? 0 : ruleLinesCount}
                selectedItemsCount={selectedItems.length}
                removedRuleLineItemsCount={removedRuleLineItems.length}
                addedLinesCount={addedLines ? addedLines.length : 0}
                selectedItems={selectedItems}
                isDraftExists={isDraftExists}
                isDraft={isDraft}
                onNextPageClick={paginateForth}
                openAddDialog={openAddDialog}
                onPrevPageClick={paginateBack}
                pagesCount={pagesCount}
                pageIndex={pageIndex}
                onRemoveClick={onRemoveClick}
                onDuplicateClick={onDuplicateClick}
                onSaveAsNewClick={openSaveAsNewDialog}
                url={createUrl()}
                createDraft={createDraft}
                createDraftUrl={createDraftUrl()}
                isReadOnly={false}
                isUpdated={isUpdated}
                hasModifications={isDraftHasModifications}
                countOfLinesWithMetaTypeDraft={countOfLinesWithMetaTypeDraft}
                isUserEditor={userPermissions && userPermissions.includes('EDITOR')}
                ruleTypeName={ruleTypeName}
                ruleTypeVersion={ruleTypeVersion}
                basedOnVersion={basedOnVersion}
                params={params}
                setFiltersData={setFiltersData}
                isInvalidFieldsPresent={isInvalidFieldsPresent}
              />
              <Table
                withCheckbox={isDraft && (userPermissions && userPermissions.includes('EDITOR'))}
                isDataLoading={isDataLoading}
                isDraftExists={isDraftExists}
                isDraft={isDraft}
                rowFields={ruleLineRowsHeadings}
                editCell={userPermissions && userPermissions.includes('EDITOR') ? editCell : null}
                headerRowFields={ruleLineRowsHeadings}
                items={ruleLines || []}
                placeholder="NO DATA TO DISPLAY. CONFIGURE YOUR SEARCH PARAMETERS."
                originalItemsCount={ruleLinesCount}
                columnsSizing={ruleLineRowsHeadings.reduce(getColumnSize, '')}
                classes={{ tableRoot: classes.table }}
                onCheckboxClick={onCheckboxClick}
                onCellClick={!isDraft && (userPermissions && userPermissions.includes('EDITOR')) ? onCellClickIfDraftExists : onCellClickToChangeValues}
                sorting={sorting}
                isRulePage={isRulePage}
                onSortApply={applySorting}
                onHeaderCheckboxClick={revertSelectionStateForAllItems}
                addedRuleLines={addedLines}
                onFieldChange={changeValueByFieldName}
                onKeyDown={onKeyDownWhenChangeValuesInCell}
                addError={addError}
                invalidFields={invalidFields}
                isUserEditor={userPermissions && userPermissions.includes('EDITOR')}
                cancelEditModeById={cancelEditModeById}
              />
            </div>
            {(userPermissions && userPermissions.includes('EDITOR')) ? (
              <AddRuleLine
                fields={ruleLineRowsHeadings.filter((el) => el.field !== 'ID')}
                isDialogOpen={isAddDialogOpen}
                onAdd={onAddDraftLine}
                closeDialog={closeAddDialog}
                updateDraftLineObject={updateDraftLineObject}
                isAddButtonActive={isAddButtonActive}
              />
            ) : null}
          </Fragment>
        )}
      <Dialog
        isOpen={isSaveAsNewDialogOpen}
        header="Save as new version"
        isRulePage={isRulePage}
        applyLabel={CONTINUE}
        cancelLabel={CANCEL}
        onApply={onApplySaveAsNew}
        onCancel={closeSaveAsNewDialog}
        classes={{
          buttonWrapper: classes.buttonWrapper,
          button: classes.button,
          buttons: classes.buttons,
          body: classes.body,
          popup: classes.popup,
          header: classes.header,
          headerContent: classes.headerContent,
          swoosh: classes.swoosh,
        }}
        withSwoosh
        isReversed
      >
        <div>
          After saving as version, you won&apos;t be able to edit this rule set. If you expect more changes, keep your draft, it auto-saves after every change.
        </div>
      </Dialog>
      <UploadDialog
        isOpen={isUploadDialogOpen}
        withCross
        header="a"
        classes={{
          header: classes.uploadHeader,
          buttons: classes.uploadFilePopupButtons,
          button: classes.uploadDialogButton,
        }}
        applyLabel="Save as new version"
        cancelLabel="Cancel"
        applyButtonDisabled={isUploadFileApplyDisabled}
        onApply={handleFileUpload}
        onCancel={isFilePicked ? openQuitUploadDialog : closeUploadDialog}
        onCrossCancel={isFilePicked ? openQuitUploadDialog : closeUploadDialog}
        setIsUploadFileApplyDisabled={setIsUploadFileApplyDisabled}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        isFilePicked={isFilePicked}
        setIsFilePicked={setIsFilePicked}
        progress={progress}
        setProgress={setProgress}
        isSaveClicked={isSaveClicked}
        setIsSaveClicked={setIsSaveClicked}
        isReversed
      >
        <UploadFile />
      </UploadDialog>
      <Dialog
        isOpen={isQuitFromUploadOpen}
        header="a"
        isRulePage={isRulePage}
        applyLabel="Back to upload"
        cancelLabel="Quit upload"
        onApply={closeQuitUploadDialog}
        onCancel={closeUploadAndQuitUploadDialogs}
        onCrossCancel={closeQuitUploadDialog}
        classes={{
          buttonWrapper: classes.buttonWrapper,
          button: classes.uploadDialogButton,
          buttons: classes.buttons,
          body: classes.body,
          header: classes.quitHeader,
          headerContent: classes.headerContent,
          swoosh: classes.quitSwoosh,
          cross: classes.quitCross,
          popup: classes.quitPopup,
        }}
        withSwoosh
        withCross
      >
        <div className={classes.quitText}>
          Quit uploading a new version?
        </div>
      </Dialog>
      <Dialog
        isOpen={isErrorFromUploadOpen}
        header="a"
        isRulePage={isRulePage}
        applyLabel="Upload another file"
        cancelLabel="Cancel upload"
        onApply={closeErrorUploadDialog}
        onCancel={closeErrorUploadDialog}
        onCrossCancel={closeErrorUploadDialog}
        classes={{
          buttonWrapper: classes.buttonWrapper,
          button: classes.uploadDialogButton,
          buttons: classes.buttons,
          body: classes.body,
          header: classes.quitHeader,
          headerContent: classes.headerContent,
          swoosh: classes.quitSwoosh,
          cross: classes.quitCross,
          popup: classes.quitPopup,
        }}
        withSwoosh
        withCross
      >
        <div className={classes.errorCont}>
          {!!selectedFile && selectedFile.name} cannot be used to create a new version.
          <p className={classes.errorText}>Error: {uploadError}</p>
        </div>
      </Dialog>
      <ActivateRuleTypeVersionDialog
        isDialogOpen={isActivationDialogOpen}
        onActivate={activateVersion}
        closeDialog={closeActivationDialog}
        ruleTypeName={ruleTypeName}
        ruleTypeVersion={ruleTypeVersion}
        activateLabel="ACTIVATE"
        cancelLabel="CANCEL"
        onCrossCancel={closeOnCross}
        header={`Activate “${ruleTypeName}” version ${getCorrectVersionDisplay(ruleTypeVersion)}?`}
        correctActivationWord="activate"
        withCross
      >
        <div className={classes.textField}>
          <div className={classes.infoContainer}>
            <p className={classes.textDivider}>
              If you <span className={classes.strongWords}>activate</span> “{ruleTypeName}” <span className={classes.strongWords}>version {getCorrectVersionDisplay(ruleTypeVersion)}</span> the previous version of this ruletype will be inactivated.
            </p>
            <p className={classes.textDivider}>
              Please note that the version activation may take up to <br /> <span className={classes.strongWords}>2 minutes</span>.
            </p>
            <p className={classes.textAndInputDivider}>
              To confirm, please type ‘activate’.
            </p>
          </div>
        </div>
      </ActivateRuleTypeVersionDialog>
      <ActivateRuleTypeVersionDialog
        isDialogOpen={isArchiveDialogOpen}
        onActivate={archiveRule}
        closeDialog={closeArchiveDialog}
        ruleTypeName={ruleTypeName}
        ruleTypeVersion={getCorrectVersionDisplay(ruleTypeVersion)}
        onCrossCancel={closeArchiveDialog}
        activateLabel="ARCHIVE"
        cancelLabel="CANCEL"
        header={`Archive “${ruleTypeName}” version ${getCorrectVersionDisplay(ruleTypeVersion)}?`}
        correctActivationWord="archive"
        withCross
      >
        <div className={classes.textField}>
          <div className={classes.infoContainer}>
            <p className={classes.textDivider}>
              After <span className={classes.strongWords}>archivation</span> “{ruleTypeName}” <span className={classes.strongWords}>version {getCorrectVersionDisplay(ruleTypeVersion)}</span> will not be available for viewing or editing
            </p>
            <p className={classes.textAndInputDivider}>
              To confirm, please type ‘archive’.
            </p>
          </div>
        </div>
      </ActivateRuleTypeVersionDialog>
    </Fragment>
  );
});
