import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, bool, object, oneOf } from 'prop-types';

import * as themeColors from '../../styles/colors';
import * as themeFontSizes from '../../styles/fontSizes';

const useStyles = makeStyles(({ colors, fontSizes, fontFamilies }) => ({
  labelRoot: {
    userSelect: 'none',
  },
  text: {
    color: ({ color }) => colors[color] || colors.darkGray,
    fontSize: ({ size }) => fontSizes[size] || fontSizes.md,
    textTransform: ({ upperCase }) => upperCase && 'uppercase',
    fontFamily: fontFamilies.primary,
  },
  required: {
    color: colors.orange,
    marginLeft: 2,
  },
}));

export const Label = memo((props) => {
  const classes = useStyles(props);
  const { text, isRequired, onClick } = props;

  return (
    <div className={classes.labelRoot} onClick={onClick}>
      <span className={classes.text}>
        {text}
      </span>
      {isRequired && (
        <span className={classes.required}>
          *
        </span>
      )}
    </div>
  );
});

Label.propTypes = {
  upperCase: bool,
  text: string.isRequired,
  isRequired: bool,
  color: oneOf(Object.keys(themeColors)),
  size: oneOf(Object.keys(themeFontSizes)),
  classes: object,
};

Label.defaultProps = {
  isRequired: false,
  upperCase: true,
  color: 'black',
  classes: {},
};
