import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '../../custom-hooks/store/use-stores';
import { Dropdown } from '../dropdown';
import { TextInput } from '../text-input';
import { SvgIcon } from '../svg-icon';
import { black, darkestGray } from '../../styles/colors';

import '../../assets/icons/addphoto.svg';
import '../../assets/icons/cross.svg';

const useStyles = makeStyles(() => ({
  dropdownRoot: {
    width: 404,
    height: 42,
    borderRadius: 3,
    border: `1px solid ${darkestGray}`,
  },
  dropdownSelectedField: {
    fontSize: 14,
    fontWeight: 400,
    border: 'none',
    marginTop: 6,
  },
  dropdownOption: {
    fontSize: 14,
    fontWeight: 400,
  },
  activeSelectedField: {
    color: black,
  },
  divider: {
    width: '100%',
    height: 34,
  },
  feedbackHeader: {
    color: black,
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 24,
    textAlign: 'center',
  },
  feedbackTextField: {
    marginTop: 24,
    borderRadius: 3,
    border: `1px solid ${darkestGray}`,
    '& textarea': {
      height: '104px !important',
      width: 402,
    },
  },
  addPhotoContainer: {
    width: 72,
    height: 56,
    borderRadius: 3,
    border: `1px solid ${darkestGray}`,
    padding: '16px 12px 16px 22px',
    marginTop: 12,
    marginBottom: 8,
  },
  addPhotoIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'none',
  },
  fileName: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    color: black,
  },
  deleteUploadFile: {
    position: 'absolute',
    right: 0,
    top: 6,
  },
}));

const categoryOptions = ['Report a bug', 'Get Support', 'Feature Request', 'Other'];
const urgencyOptions = ['Blocker', 'Major', 'Regular', 'Minor'];

export const FeedbackForm = observer((props) => {
  const {
    setIsSubmitFeedbackDisabled,
  } = props;

  const {
    ruleTypesStore: {
      feedbackObj,
    },
    uiStore: {
      userPersonalInfo,
    },
    notificationStore: {
      addWarning,
    },
  } = useStores();

  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedUrgency, setSelectedUrgency] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [messageText, setMessageText] = useState('');

  useEffect(() => {
    if (selectedCategory && selectedUrgency && messageText) {
      setIsSubmitFeedbackDisabled(false);
      feedbackObj.username = userPersonalInfo.username;
      feedbackObj.category = selectedCategory;
      feedbackObj.urgency = selectedUrgency;
      feedbackObj.message = messageText;
      if (selectedFile) {
        feedbackObj.attachment = selectedFile;
      }
    } else {
      setIsSubmitFeedbackDisabled(true);
    }
  }, [selectedCategory, selectedUrgency, messageText, setIsSubmitFeedbackDisabled, selectedFile, feedbackObj]);

  const handleOnChangeFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1048576 * 20) {
        addWarning('Please choose file that is no larger than 20 MB');
      } else {
        setSelectedFile(file);
      }
    }
  };

  const handleOnChangeMessage = (value) => {
    if (value.length <= 1000) {
      setMessageText(value);
    }
  };

  return (
    <div>
      <p className={classes.feedbackHeader}>Any ideas on how to make Rule Engine better?</p>
      <Dropdown
        placeholder="Choose category"
        classes={{
          root: classes.dropdownRoot,
          selectedField: selectedCategory ? cx(classes.dropdownSelectedField, classes.activeSelectedField) : classes.dropdownSelectedField,
          option: cx(classes.dropdownOption, classes.activeSelectedField),
          arrow: classes.dropdownArrow,
        }}
        selected={selectedCategory}
        options={categoryOptions}
        nameOrVersion="rulePage"
        onSelect={setSelectedCategory}
      />
      <div className={classes.divider} />
      <Dropdown
        placeholder="Choose urgency"
        classes={{
          root: classes.dropdownRoot,
          selectedField: selectedUrgency ? cx(classes.dropdownSelectedField, classes.activeSelectedField) : classes.dropdownSelectedField,
          option: cx(classes.dropdownOption, classes.activeSelectedField),
          arrow: classes.dropdownArrow,
        }}
        selected={selectedUrgency}
        options={urgencyOptions}
        nameOrVersion="rulePage"
        onSelect={setSelectedUrgency}
      />
      <TextInput
        multiline
        value={messageText}
        onChange={handleOnChangeMessage}
        classes={{
          textField: classes.feedbackTextField,
        }}
        placeholder="Write a message"
      />
      {selectedFile ? (
        <div className={classes.fileName}>
          <span className={classes.selectedFileName}>{selectedFile.name}</span>
          <SvgIcon
            name="cross"
            classes={{ svgIconRoot: classes.deleteUploadFile }}
            width={14}
            height={14}
            onClick={() => {
              setSelectedFile(null);
            }}
          />
        </div>
      ) : (
        <div>
          <div title="Attach screenshot" data-testid="screenshotIconTestId" className={classes.addPhotoContainer}>
            <SvgIcon
              width={24}
              height={24}
              onClick={() => {
                document.getElementById('uploadfile').click();
              }}
              name="addphoto"
              classes={{
                svgIconRoot: classes.addPhotoIcon,
              }}
            />
          </div>
          <div className={classes.wrapper}>
            <input
              value={selectedFile}
              type="file"
              id="uploadfile"
              accept="image/png, image/jpeg, image/jpg"
              onClick={(event) => { event.currentTarget.value = null; }}
              onChange={handleOnChangeFile}
            />
          </div>
        </div>

      )}

    </div>
  );
});
