import React, { useMemo } from 'react';
import { bool, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { Dropdown } from '../dropdown';
import { SelectionBox } from '../selection-box';
import { Divider } from '../divider';
import { useStores } from '../../custom-hooks/store/use-stores';
import * as themeColors from '../../styles/colors';

const useStyles = makeStyles(({ mixins }) => ({
  searchWrapper: {
    ...mixins.searchBarWrapper,
    paddingTop: 0,
  },
  selectedField: {
    height: 40,
    width: 225,
    fontSize: 14,
    color: themeColors.darkestGray,
    fontWeight: 500,
  },
  option: {
    fontSize: 14,
    fontWeight: 500,
    color: themeColors.darkestGray,
  },
  divider: {
    background: themeColors.white,
  },
  dividerWrapper: {
    margin: '0px 11px',
  },
}));

export const RuleFilters = observer((props) => {
  const classes = useStyles(props);
  const isMultiple = true;
  const {
    ruleTypesStore: {
      ruleTypesCount,
      setFilterOptions,
      selectedRuleTypes,
      selectedRuleTypeVersion,
      getRuleTypesWithFilters,
    },
  } = useStores();

  const selectedRuleTypeOptions = useMemo(() => {
    return selectedRuleTypes.map((el) => el);
  }, [selectedRuleTypes.length, selectedRuleTypes]);

  const { options } = props;

  return (
    <div className={classes.searchWrapper}>
      <SelectionBox
        label="RULE TYPE"
        size="lg"
      >
        <Dropdown
          id="ruleTypeNameDropdown"
          optionsId="ruleTypeNameDropdownOption"
          withSearch
          disabled={ruleTypesCount <= 0}
          width={225}
          height={40}
          classes={classes}
          placeholder="ALL"
          selected={selectedRuleTypeOptions}
          options={options.names}
          nameOrVersion="name"
          onSelect={setFilterOptions}
          onClick={getRuleTypesWithFilters}
          isMultiple={isMultiple}
        />
      </SelectionBox>

      <Divider classes={classes} />

      <SelectionBox
        label="VERSION"
        size="lg"
      >
        <Dropdown
          id="ruleTypeVersionDropdown"
          optionsId="ruleTypeVersionDropdownOption"
          withSearch
          disabled={selectedRuleTypes.length === 0 || selectedRuleTypes.length > 1}
          width={225}
          height={40}
          placeholder="ALL"
          classes={classes}
          selected={selectedRuleTypeVersion}
          options={options.versions}
          nameOrVersion="version"
          onSelect={setFilterOptions}
          onClick={getRuleTypesWithFilters}
        />
      </SelectionBox>

    </div>
  );
});

RuleFilters.propTypes = {
  disableSearch: bool,
  onSearch: func,
};

RuleFilters.defaultProps = {
  disableSearch: false,
};
