import React, { useState, useCallback, Fragment } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from '../dropdown/Dropdown';
import { TextInput } from '../text-input';
import { FilterCheckbox } from '../filter-checkbox/FilterCheckbox';
import { SvgIcon } from '../svg-icon';
import { FilterBox } from '../filter-box';
import { Button, buttonTypes } from '../button';
import { Dialog } from '../dialog';
import { invalidRed, white, black } from '../../styles/colors';

import '../../assets/icons/plus.svg';

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 12,
  },
  mainInputsContainer: {
    display: 'flex',
  },
  dropdownRoot: {
    marginRight: 12,
    width: 215,
    height: 40,
  },
  dropdownSelectedField: {
    width: 215,
    height: 40,
    fontFamily: 'Helvetica Neue',
    fontSize: 14,
    fontWeight: 500,
  },
  descriptionTextField: {
    fontFamily: 'Helvetica Neue',
    fontSize: 14,
    fontWeight: 500,
    height: 40,
    '& input': {
      height: '40px !important',
    },
  },
  addEnumValueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  enumValueContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    flexWrap: 'wrap',
  },
  enumValueBox: {
    margin: 0,
    marginRight: 12,
    marginBottom: 10,
    marginTop: 10,
    '& span': {
      marginRight: 5,
    },
  },
  addValueText: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 8,
    color: '#808080',
  },
  requiredSign: {
    color: invalidRed,
  },
  addInput: {
    width: 150,
  },
  invalidTextFieldInput: {
    border: `1px solid ${invalidRed}`,
  },
  buttonRemove: {
    width: 105,
    height: 32,
    borderRadius: 20,
  },
  buttonRemoveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    marginBottom: 24,
  },
  removeHeader: {
    color: white,
    border: 'none',
  },
  removeButton: {
    width: 192,
    height: 40,
  },
  removeButtons: {
    flexDirection: 'row',
  },
  removeText: {
    fontSize: 18,
    fontWeight: 700,
    color: black,
    textAlign: 'center',
  },
  removeBody: {
    maxWidth: 500,
  },
}));

export const AddParameterForm = (props) => {
  const classes = useStyles(props);
  const { setDescr, setType, setEnums, setRequired, removeParam, item, paramName, inOrOut, inputParams, addError } = props;
  const [parameterDescription, setParameterDesciption] = useState(item.description ? item.description : '');
  const [isInvalidDescription, setIsInvalidDescription] = useState(false);
  const [selected, setSelected] = useState(item.type ? item.type : null);
  const [isRequiredChecked, setIsRequiredChecked] = useState(item.required ? item.required : false);
  const [enumValues, setEnumValues] = useState(item.enumValues ? item.enumValues : []);
  const [addInputEnumValue, setAddInputEnumValue] = useState('');
  const [isInvalidEnumValue, setIsInvalidEnumValue] = useState(false);
  const [isInputDisplayed, setIsInputDisplayed] = useState(false);
  const [isRemoveParamPopupOpen, setIsRemoveParamPopupOpen] = useState(false);
  const isRulePage = true;

  const openRemovePopup = useCallback(() => setIsRemoveParamPopupOpen(true), [setIsRemoveParamPopupOpen]);
  const closeRemovePopup = useCallback(() => setIsRemoveParamPopupOpen(false), [setIsRemoveParamPopupOpen]);

  const handleOnAddValueClick = useCallback((val) => {
    if (val.length > 0) {
      const obj = val;
      setEnumValues((prev) => {
        const result = [...prev, obj];
        if (!isInvalidEnumValue) {
          setEnums(result);
        }
        return result;
      });
    }
  }, [setEnumValues, isInvalidEnumValue, setEnums]);

  const setAndClearInputValues = useCallback(() => {
    if (!isInvalidEnumValue) {
      handleOnAddValueClick(addInputEnumValue);
      setIsInputDisplayed(false);
      setAddInputEnumValue('');
    }
  }, [handleOnAddValueClick, setIsInputDisplayed, setAddInputEnumValue, addInputEnumValue, isInvalidEnumValue]);

  const deleteEnumValue = useCallback((enumName) => {
    const filteredValues = enumValues.filter((el) => el !== enumName);
    setEnumValues(filteredValues);
    setEnums(filteredValues);
  }, [enumValues && enumValues.length, enumValues, setEnumValues, setEnums]);

  const handleOnChangeDescription = useCallback((val) => {
    if (val.length <= 100) {
      setParameterDesciption(val);
      setIsInvalidDescription(false);
      setDescr(val);
    }
    if (val === '') {
      setIsInvalidDescription(true);
    }
  }, [setParameterDesciption, setIsInvalidDescription, setDescr]);

  const onDropdownSelect = useCallback((val) => {
    setType(val);
    setSelected(val);
  }, [setType, setSelected]);

  const handleOnChangeEnumValue = useCallback((val) => {
    if (val === '' || val.length <= 10) {
      setAddInputEnumValue(val);
    }
    if (val.match(/\W/g)) {
      setIsInvalidEnumValue(true);
    } else if (val === '') {
      setIsInvalidEnumValue(true);
    } else {
      setIsInvalidEnumValue(false);
    }
    if (val.length === 0) {
      setIsInvalidEnumValue(false);
    }
  }, [setAddInputEnumValue, addInputEnumValue, setIsInvalidEnumValue]);

  const handleOnKeyDownOnEnumInput = useCallback((e) => {
    if (e.keyCode === 13) {
      if (addInputEnumValue.length === 0) {
        setIsInputDisplayed(false);
        setAddInputEnumValue('');
      } else {
        setAndClearInputValues();
      }
    }
  }, [addInputEnumValue, setIsInputDisplayed, setAddInputEnumValue, setAndClearInputValues]);

  return (
    <Fragment>
      <div className={classes.formContainer}>
        <div className={classes.mainInputsContainer}>
          <Dropdown
            withSearch
            disabled={false}
            placeholder="Type"
            classes={{
              root: classes.dropdownRoot,
              selectedField: classes.dropdownSelectedField,
              option: classes.dropdownOption,
            }}
            selected={selected}
            options={['String', 'Integer', 'Enum', 'Boolean']}
            nameOrVersion="rulePage"
            onSelect={onDropdownSelect}
          />
          <TextInput
            value={parameterDescription}
            onChange={handleOnChangeDescription}
            placeholder="* Description"
            classes={{
              textField: isInvalidDescription ? cx(classes.invalidTextFieldInput, classes.descriptionTextField) : classes.descriptionTextField,
            }}
          />
        </div>
        {selected === 'Enum' ? (
          <div className={classes.enumValueContainer}>
            {enumValues.map((el) => {
              return (
                <FilterBox
                  classes={{
                    filterBoxContainer: classes.enumValueBox,
                  }}
                  name="ENUM"
                  value={el}
                  key={el}
                  onClick={() => deleteEnumValue(el)}
                />
              );
            })}
            {isInputDisplayed ? (
              <TextInput
                focus
                dataTestId="addEnumValueInputTestId"
                classes={{
                  textField: isInvalidEnumValue ? cx(classes.invalidTextFieldInput, classes.addInput) : classes.addInput,
                }}
                value={addInputEnumValue}
                onChange={handleOnChangeEnumValue}
                onBlur={setAndClearInputValues}
                onKeyDown={handleOnKeyDownOnEnumInput}
              />
            ) : (
              <div className={classes.addEnumValueContainer}>
                <SvgIcon classes={classes} width={15} height={24} name="plus" onClick={() => setIsInputDisplayed(true)} />
                <p className={classes.addValueText} onClick={() => setIsInputDisplayed(true)}><span className={classes.requiredSign}>*</span> Add value</p>
              </div>
            )}
          </div>
        ) : null}
        {inOrOut === 'IN' ? (
          <FilterCheckbox
            text="Required parameter"
            checked={isRequiredChecked}
            onCheck={() => {
              setIsRequiredChecked(!isRequiredChecked);
              setRequired(!isRequiredChecked);
              setTimeout(() => {
                if (inOrOut === 'IN' && inputParams.every((el) => !el.required)) {
                  addError('Rule type must have at least one required input parameter');
                }
              }, 0);
            }}
          />
        ) : null}
        <Button
          type={buttonTypes.PRIMARY}
          label="Remove"
          isRounded
          classes={{ button: classes.buttonRemove, buttonWrapper: classes.buttonRemoveWrapper }}
          onClick={openRemovePopup}
        />
      </div>
      <Dialog
        isOpen={isRemoveParamPopupOpen}
        header="a"
        isRulePage={isRulePage}
        applyLabel="Continue"
        cancelLabel="Cancel"
        onApply={removeParam}
        onCancel={closeRemovePopup}
        onCrossCancel={closeRemovePopup}
        classes={{
          buttonWrapper: classes.buttonWrapper,
          button: classes.removeButton,
          buttons: classes.removeButtons,
          body: classes.removeBody,
          header: classes.removeHeader,
        }}
        withSwoosh
        withCross
      >
        <p className={classes.removeText}>You are going to remove parameter “{paramName}” and all its data. Continue?</p>
      </Dialog>
    </Fragment>
  );
};
