import React, { forwardRef } from 'react';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { number, oneOfType, string } from 'prop-types';

import { Snackbar } from '../snackbar';

import { useStores } from '../../custom-hooks/store/use-stores';

// We need this adapter due to the notistack's limitation.
// At the moment you can get ID and Message only via `SnackbarProvider.content` attribute.
// https://github.com/iamhosseindhv/notistack/pull/247
export const SnackbarAdapter = observer(forwardRef((props, ref) => {
  const { notificationStore } = useStores();
  const { closeSnackbar } = useSnackbar();

  const { id } = props;

  const {
    type,
    customTitle,
    message = '',
    contentRenderer,
  } = notificationStore.get(id);

  const getOnCloseHandler = (notificationId) => () => closeSnackbar(notificationId);

  return (
    <Snackbar
      ref={ref}
      id={id}
      type={type}
      customTitle={customTitle}
      message={message}
      contentRenderer={contentRenderer}
      onClose={getOnCloseHandler(id)}
    />
  );
}));

SnackbarAdapter.propTypes = {
  id: oneOfType([string, number]).isRequired,
};
