/* eslint-disable no-unused-vars */
import { NONE } from '../../constants/common';

export class RuleTypesService {
  constructor(adapter, { api: { ruleTypes: { path } } }) {
    this._adapter = adapter;
    this._ruleTypesPath = path;
  }

  getRuleTypes = (anchor, count, sortBy, sortOrder = NONE, params = {}) => {
    return this._adapter.get({
      path: `${this._ruleTypesPath}/versions`,
      params: { ...params },
    })
      .then((res) => {
        return res.data;
      });
  };

  getFlexRuleTypes = (params = {}) => {
    return this._adapter.get({
      path: `${this._ruleTypesPath}/versions`,
      params: { ...params },
      isFlex: true,
    }).then(({ data }) => data);
  }

  activateRuleType = ({ ruleType, versionNumber }) => {
    return this._adapter.post({
      path: `${this._ruleTypesPath}/${ruleType}/versions/${versionNumber}/switch`,
    }).then(({ data }) => data);
  };

  getActivationState = (ruleType) => {
    return this._adapter.get({
      path: `${this._ruleTypesPath}/${ruleType}/active`,
    }).then(({ data }) => data);
  };

  removeDraft = ({ ruleType, versionNumber }) => {
    return this._adapter.post({
      path: `${this._ruleTypesPath}/${ruleType}/versions/${versionNumber.match(/\d/g).join('')}/removeDraft`,
    }).then(({ data }) => data);
  };

  archiveRuleType = ({ ruleTypeName, ruleTypeVersion }) => {
    return this._adapter.post({
      path: `${this._ruleTypesPath}/${ruleTypeName}/versions/${ruleTypeVersion}/archive`,
    });
  };

  sendFeedback = (data) => {
    const formData = new FormData();
    formData.append('username', data.username);
    formData.append('category', data.category);
    formData.append('urgency', data.urgency);
    formData.append('message', data.message);
    if (data.attachment) {
      const file = data.attachment;
      formData.append('attachment', file);
    }
    return this._adapter.post({
      path: 'feedback',
      body: formData,
    });
  };
}
