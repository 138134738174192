export class AddRuleTypeServices {
  constructor(adapter, { api: { ruleTypes: { path } } }) {
    this._adapter = adapter;
    this._ruleLinesPath = path;
  }

  createRuleType(ruleTypeName, data) {
    return this._adapter.post({
      path: `updateRuleMeta/${ruleTypeName}`,
      body: data,
      isFlex: true,
    });
  }

  async getRuleTypeMetadata(ruleTypeName) {
    const ruleTypes = await this._adapter.get({
      path: 'getRuleTypes',
      isFlex: true,
    }).then(({ data }) => data.ruleTypes);
    const index = ruleTypeName.indexOf('-');
    let rtName = '';
    if (index) {
      rtName = ruleTypeName.substr(0, index);
    } else {
      rtName = ruleTypeName;
    }
    if (Object.keys(ruleTypes).includes(rtName)) {
      return this._adapter.get({
        path: `getRuleMeta/${rtName}-${ruleTypes[rtName][ruleTypes[rtName].length - 1]}`,
        isFlex: true,
      });
    }
    return this._adapter.get({
      path: `${this._ruleLinesPath}/${ruleTypeName}/meta`,
    });
  }

  async getFlexRuleTypes() {
    const ruleTypes = await this._adapter.get({
      path: 'getRuleTypes',
      isFlex: true,
    }).then(({ data }) => data.ruleTypes);
    return ruleTypes;
  }

  async getFlexRuleTypesWithVersions() {
    const ruleTypes = await this._adapter.get({
      path: `${this._ruleLinesPath}/versions`,
      isFlex: true,
    }).then(({ data }) => data);
    return ruleTypes;
  }
}
