import { RULE_TYPE_NAME, VERSION, LAST_MODIFIED, EDITOR } from './common';

export const HEADER_ROW_FIELDS = [
  {
    field: RULE_TYPE_NAME,
    label: 'Rule type name',
    columnSize: 'minmax(200px, 0.25fr)',
  },
  {
    field: VERSION,
    label: 'Version',
    columnSize: 'minmax(200px, 0.25fr)',
  },
  {
    field: LAST_MODIFIED,
    label: 'Last modified',
    columnSize: 'minmax(200px, 0.25fr)',
  },
  {
    field: EDITOR,
    label: 'Editor',
    columnSize: 'minmax(200px, 0.25fr)',
  },
];

export const ROW_FIELDS = HEADER_ROW_FIELDS.map(column => column.field);

export const FIELDS_FOR_BACKEND_SORTING = {
  [RULE_TYPE_NAME]: 'ruleType',
  [VERSION]: 'version',
  [LAST_MODIFIED]: 'modificationDateTime',
  [EDITOR]: 'modifiedBy',
};

export const SORT_ORDER_FOR_BACKEND = {
  ASC: 'Asc',
  DESC: 'Desc',
};

export const SORTABLE_FIELDS = [
  RULE_TYPE_NAME,
  VERSION,
  LAST_MODIFIED,
  EDITOR,
];

export const RT_ITEMS_PER_PAGE = 50;
