import uniqueId from 'lodash/uniqueId';
import { action } from 'mobx';

import { RowItemStore } from '../abstractStores/RowItemStore';
import { RULE_TYPE_NAME, VERSION, BASED_ON_VERSION, LAST_MODIFIED, EDITOR } from '../../constants/common';

export class RuleItemStore extends RowItemStore {
  @action.bound init(item) {
    this.data = {
      [RULE_TYPE_NAME]: item.ruleTypeName,
      [VERSION]: `${item.displayVersion} ${item.isActive ? '(active version)' : ''}`,
      [BASED_ON_VERSION]: item.basedOn ? `Version ${item.basedOn}` : 'Original',
      [LAST_MODIFIED]: item.lastModified, // TODO: time formatter
      [EDITOR]: item.editor,
    };
    this.id = uniqueId();
    this.isActive = item.isActive;
    this.isDraft = item.isDraft;
    this.isPending = item.isPending;
  }
}
