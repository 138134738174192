import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { AddRuleLineDialog } from '../add-rule-line-dialog';
import { AddRuleLineField } from '../add-rule-line-field/AddRuleLineField';
import { CANCEL, ADD } from '../../constants/common';

const useStyles = makeStyles(() => ({
  buttonWrapper: {},
  button: {},
  buttons: {},
  dialogBody: {},
}));

export const AddRuleLine = (props) => {
  const classes = useStyles(props);
  const {
    fields,
    isDialogOpen,
    closeDialog,
    onAdd,
    updateDraftLineObject,
    isAddButtonActive,
  } = props;

  const [focusedIndex, setFocusedIndex] = useState(0);

  const onAddClick = useCallback(() => {
    onAdd();
    closeDialog();
  }, [onAdd, closeDialog]);

  return (
    <AddRuleLineDialog
      isOpen={isDialogOpen}
      header="Add new line"
      applyLabel={ADD}
      cancelLabel={CANCEL}
      onApply={onAddClick}
      onCancel={closeDialog}
      onCrossCancel={closeDialog}
      isAddButtonActive={isAddButtonActive}
      classes={{
        buttonWrapper: classes.buttonWrapper,
        button: classes.button,
        buttons: classes.buttons,
        body: classes.dialogBody,
      }}
    >
      {fields.map((el, index) => <AddRuleLineField id={`addNewFieldRulePageId${index}`} updateDraftLineObject={updateDraftLineObject} index={index} setFocusedIndex={setFocusedIndex} isFocused={focusedIndex === index} el={el} />)}
    </AddRuleLineDialog>
  );
};
