import React, { memo } from 'react';
import { SnackbarProvider } from 'notistack';

import { Notification } from '../notification';
import { STACK_MAX } from '../../constants/notification';
import { SnackbarAdapter } from '../snackbar-adapter';

export const NotificationProvider = memo((props) => {
  const { children } = props;

  return (
    <SnackbarProvider
      maxSnack={STACK_MAX}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      content={(key) => (<SnackbarAdapter id={key} />)}
    >
      {children}
      <Notification />
    </SnackbarProvider>
  );
});

NotificationProvider.propTypes = {};
