import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useStores } from '../../custom-hooks/store/use-stores';

import { Label } from '../label';

import NavbarImg from '../../assets/images/navbar.png';

const useStyles = makeStyles(({ colors, mixins }) => ({
  navigationWrapper: {
    ...mixins.flexColumnEnd,
    position: 'relative',
    height: 100,
    padding: '6px 100px',
    transition: '.7s',
    zIndex: 1,
  },
  linksWrapper: {
    display: 'flex',
    marginRight: 24,
  },
  label: {
    padding: '0 24px',
    transition: '.2s',
    color: colors.black,

    '&:hover': {
      background: colors.whiteOpacity2,
    },
  },
  labelText: {
    color: colors.black,
  },
  navPlaceholder: {
    height: 100,
    backgroundImage: `url(${NavbarImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  selected: {
    background: colors.black,

    '& span': {
      color: colors.white,
    },
  },
  link: {
    width: 'max-content',
  },
}));

export const Navigation = observer((props) => {
  const classes = useStyles(props);
  const { uiStore: { navLinks } } = useStores();

  const renderLink = ({ pathname, label }) => {
    return (
      <NavLink
        key={pathname}
        to={pathname}
        activeClassName={classes.selected}
        className={classes.link}
      >
        <Label
          text={label}
          classes={{ labelRoot: classes.label, text: classes.labelText }}
          size="xl"
          color="white"
        />
      </NavLink>
    );
  };

  return (
    <div className={cx(classes.navigationWrapper, classes.navPlaceholder)} data-testid="navigationWrapper">
      <div className={classes.linksWrapper}>
        {navLinks && navLinks.map(renderLink)}
      </div>
    </div>

  );
});

Navigation.propTypes = {
  isCollapsed: bool,
};

Navigation.defaultProps = {
  isCollapsed: false,
};
