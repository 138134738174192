import React, { useEffect, useRef } from 'react';
import { bool, func, string, object, oneOfType, number } from 'prop-types';
import Input from 'react-toolbox/lib/input';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { SvgIcon } from '../svg-icon';
import { INPUT_TYPES } from '../../constants/common';
import { textareaTestId } from '../../test-data/dataTestIds';

import '../../assets/icons/loupe.svg';

const useStyles = makeStyles(({ borders, spaces, fontSizes, colors }) => ({
  textField: {
    position: 'relative',
    display: 'flex',
    flexDirection: ({ multiline }) => multiline && 'column',
    width: ({ width }) => width || '100%',
    height: ({ multiline }) => multiline && '100%' || 30,
    borderRadius: 0,
    '& input': {
      width: '100%',
      padding: `0 ${spaces.md}`,
      border: ({ bordered }) => bordered && borders.gray,
      background: ({ disabled }) => (disabled ? colors.lightGray : 'transparent'),
      opacity: ({ disabled }) => disabled && 0.6,
      fontSize: fontSizes.sm,
      textAlign: 'inherit',
      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      /* Firefox */
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
    '& textarea': {
      border: ({ bordered }) => bordered && borders.gray,
      height: '100%',
      width: '100%',
      borderRadius: 0,
      lineHeight: '20px',
      padding: '6px',
      outline: 'none',
      resize: 'none',
    },
    '& span': {
      '&:last-of-type': {
        color: colors.darkGray,
        fontSize: 10,
        alignSelf: 'flex-end',
      },
    },
  },
  icon: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: 'calc(50% - 10px)',
    left: 10,
  },
  search: {
    '& input': {
      paddingLeft: 35,
    },
  },
}));

export const TextInput = (props) => {
  const classes = useStyles(props);
  const {
    autoFocus,
    disabled,
    multiline,
    isSearch,
    dataTestId,
    rows,
    value,
    maxLength,
    placeholder,
    type,
    min,
    max,
    name,
    autoComplete,
    onBlur,
    onChange,
    onKeyDown,
    onClick,
    focus,
    id,
  } = props;
  const ref = useRef();

  const onInputChange = (inputValue) => {
    if (inputValue === value || disabled) return null;

    onChange && onChange(inputValue);
  };

  useEffect(() => {
    if (focus) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [focus]);

  return (
    <Input
      id={id}
      innerRef={ref}
      autoFocus={autoFocus}
      data-testid={dataTestId}
      type={type}
      multiline={multiline}
      disabled={disabled}
      placeholder={placeholder}
      rows={rows}
      name={name}
      value={value}
      min={min}
      max={max}
      maxLength={maxLength}
      autoComplete={autoComplete ? 'on' : 'off'}
      icon={isSearch ? <SvgIcon classes={{ svgIconRoot: classes.icon }} name="loupe" /> : null}
      theme={{
        input: cx(classes.textField, { [classes.search]: isSearch }),
        textarea: classes.textArea,
      }}
      onKeyDown={onKeyDown}
      onChange={onInputChange}
      onBlur={onBlur}
      onClick={onClick}
    />
  );
};

TextInput.propTypes = {
  autoFocus: bool,
  multiline: bool,
  disabled: bool,
  bordered: bool,
  isSearch: bool,
  autoComplete: bool,
  dataTestId: string,
  maxLength: number,
  min: number,
  max: number,
  classes: object,
  value: oneOfType([string, number]),
  placeholder: string,
  type: string,
  rows: number,
  name: string.isRequired,
  onChange: func,
  onBlur: func,
  width: oneOfType([string, number]),
};

TextInput.defaultProps = {
  autoFocus: false,
  multiline: false,
  bordered: true,
  disabled: false,
  isSearch: false,
  autoComplete: false,
  dataTestId: textareaTestId,
  placeholder: '',
  type: INPUT_TYPES.TEXT,
  onBlur: null,
  classes: {},
  onChange: () => {},
};
