import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((mixins) => ({
  addNewVersionWrapper: {
    ...mixins.flexColumnGreedy,
  },
}));

export const AddNewVersion = observer(() => {
  const classes = useStyles();

  return (
    <div className={classes.addNewVersionWrapper}>
      <h2>Add new version</h2>
    </div>
  );
});

AddNewVersion.propTypes = {};

AddNewVersion.defaultProps = {};
