import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, object, number, oneOf } from 'prop-types';
import pluralize from 'pluralize';

const useStyles = makeStyles(({ mixins, colors, fontFamilies, fontSizes }) => ({
  selectionCounter: {
    ...mixins.transition,
    fontFamily: fontFamilies.secondary,
    fontSize: fontSizes.sm,
    color: colors.darkGray,
  },
}));

export const COUNTER_MODES = {
  NONE: '',
  SELECTED: 'SELECTED',
  FOUND: 'FOUND',
  ACTIVE: 'ACTIVE',
};

export const Counter = memo((props) => {
  const classes = useStyles(props);
  const { label, count, mode, dataTestId } = props;

  const getKeyWord = () => {
    switch (mode) {
      case COUNTER_MODES.SELECTED:
        return 'selected';
      case COUNTER_MODES.FOUND:
        return 'found';
      case COUNTER_MODES.ACTIVE:
        return 'active';
      default:
        return '';
    }
  };

  return (
    <div
      data-testid={dataTestId}
      className={classes.selectionCounter}
    >
      {pluralize(label, count, true)} {getKeyWord()}
    </div>
  );
});

Counter.propTypes = {
  count: number.isRequired,
  dataTestId: string,
  label: string,
  mode: oneOf(Object.values(COUNTER_MODES)),
  classes: object,
};

Counter.defaultProps = {
  label: 'Item',
  dataTestId: '',
  classes: {},
  mode: COUNTER_MODES.NONE,
};
