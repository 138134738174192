import { createMuiTheme } from '@material-ui/core/styles';

import * as colors from './colors';
import * as spaces from './spaces';
import * as fontSizes from './fontSizes';
import * as fontFamilies from './fontFamilies';
import * as borders from './borders';
import * as mixins from './mixins';
import * as boxShadows from './boxShadows';

export const theme = createMuiTheme({
  fontFamilies,
  fontSizes,
  colors,
  spaces,
  borders,
  mixins,
  boxShadows,
});
