import { ApiAdapter } from './api';
import defaultConfig from '../../config/base.config';
import { UserService } from './api/userService';
import { RuleTypesService } from './api/ruleTypesService';
import { RuleLineServices } from './api/ruleLinesServices';
import { AddRuleTypeServices } from './api/addRuleTypeServices';

export const initConfig = (config) => {
  if (!Object.keys(config.api).length) {
    config.api = {
      url: '',
      flexUrl: '',
    };
  }

  return config;
};

export const readConfig = () => {
  let config;

  if (window.__CONFIG__) {
    config = JSON.parse(JSON.stringify(window.__CONFIG__));
    delete window.__CONFIG__;
  } else {
    config = defaultConfig;
  }
  return initConfig(config);
};

const config = readConfig();

const apiAdapter = new ApiAdapter(config);
const ruleTypesService = new RuleTypesService(apiAdapter, config);
const userService = new UserService(apiAdapter, config);
const ruleLineService = new RuleLineServices(apiAdapter, config);
const addRuleTypeServices = new AddRuleTypeServices(apiAdapter, config);

export default {
  config,
  apiAdapter,
  userService,
  ruleTypesService,
  ruleLineService,
  addRuleTypeServices,
};
