import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backToRuleTypesContainer: {
    width: '100%',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.05)',
    cursor: 'pointer',
  },
  backText: {
    marginLeft: 124,
    fontWeight: 500,
  },
}));

export const BackToRuletypesLabel = (props) => {
  const classes = useStyles();
  const {
    text,
    onClick,
    id,
  } = props;
  return (
    <div id={id} className={classes.backToRuleTypesContainer}>
      <p className={classes.backText} data-testid="backToRuleTypesLabelTestId" onClick={onClick}>{text}</p>
    </div>
  );
};
