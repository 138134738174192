import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';

import { useStores } from '../../custom-hooks/store/use-stores';

import {
  TYPE_ERROR,
  TYPE_SUCCESS,
} from '../../constants/notification';

import '../../assets/icons/success-oval.svg';
import '../../assets/icons/failed-oval.svg';
import '../../assets/icons/processing-oval.svg';
import '../../assets/icons/warning-oval.svg';
import '../../assets/icons/close.svg';

export const Notification = observer(() => {
  const {
    notificationStore: {
      markAsShown,
      notifications,
    },
  } = useStores();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const variants = {
    [TYPE_ERROR]: 'error',
    [TYPE_SUCCESS]: 'success',
  };

  useEffect(() => {
    const showedIds = [];

    notifications.forEach((notification) => {
      const {
        shown,
        forceClose,
        id,
        type,
        message,
        lifetime,
      } = notification;

      if (!shown) {
        enqueueSnackbar(message, {
          key: id,
          persist: +lifetime === 0,
          variant: variants[type] || variants[TYPE_SUCCESS],
          autoHideDuration: lifetime,
        });

        showedIds.push(id);
      } else if (forceClose) {
        closeSnackbar(id);
      }
    });

    markAsShown(showedIds);
  }, [notifications]);

  return null;
});

Notification.propTypes = {};
