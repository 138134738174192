import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { object, oneOf } from 'prop-types';

import * as themeColors from '../../styles/colors';

export const VERTICAL = 'vertical';
export const HORIZONTAL = 'horizontal';
export const BULLET = 'bullet';

const dividerStyles = {
  [VERTICAL]: {
    width: 1,
    height: 12,
    borderRadius: null,
    marginHorizontal: 16,
  },
  [HORIZONTAL]: {
    width: 32,
    height: 1,
    borderRadius: null,
    marginHorizontal: 16,
  },
  [BULLET]: {
    width: 4,
    height: 4,
    borderRadius: 2,
    marginHorizontal: 10,
  },
};

const useStyles = makeStyles(({ colors }) => ({
  dividerWrapper: {
    margin: ({ variant }) => `0 ${dividerStyles[variant].marginHorizontal}px`,
    display: 'inline-flex',
    alignItems: 'center',
  },
  divider: {
    background: ({ color }) => colors[color] || colors.darkGray,
    width: ({ variant }) => dividerStyles[variant].width,
    height: ({ variant }) => dividerStyles[variant].height,
    borderRadius: ({ variant }) => dividerStyles[variant].borderRadius,
  },
}));

export const Divider = memo((props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.dividerWrapper}>
      <div className={classes.divider} />
    </div>
  );
});

Divider.propTypes = {
  variant: oneOf([VERTICAL, HORIZONTAL, BULLET]),
  color: oneOf(Object.keys(themeColors)),
  classes: object,
};

Divider.defaultProps = {
  variant: VERTICAL,
  classes: {},
};
