import React, { useRef, useState, useEffect } from 'react';
import xorBy from 'lodash/xorBy';
import { unionBy } from 'lodash';
import { string, func, arrayOf, shape, array } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Dropdown } from '../dropdown';
import { buildSingleDropdownOptions } from '../../utils/dropdown';

const useStyles = makeStyles(({ colors, borders, fontSizes, fontFamilies, mixins }) => ({
  multiSelectFilterRoot: {},
  header: {
    ...mixins.flexSpaceBetween,
    flex: 1,
    marginBottom: 10,
    fontSize: fontSizes.md,
  },
  label: {
    fontFamily: fontFamilies.primary,
    color: colors.darkGray,
    marginRight: 20,
    textTransform: 'uppercase',
  },
  actionsRow: {
    display: 'flex',
    fontFamily: fontFamilies.secondary,
    color: colors.blue,
  },
  actionItem: {
    cursor: 'pointer',
    userSelect: 'none',
    '& + &': {
      borderLeft: borders.gray,
      paddingLeft: 10,
      marginLeft: 10,
    },
  },
  wrapper: {
    ...mixins.flexColumn,
    marginBottom: 10,
  },
  dropdownWrapper: {
    position: 'initial',
    boxShadow: 'none',
  },
}));

export const MultiSelectFilter = (props) => {
  const classes = useStyles(props);
  const actionsRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const { options, label, selected, currentlySelectedFilters, onSelect } = props;

  useEffect(() => {
    if (!currentlySelectedFilters.length && !!selected.length) {
      onSelect && onSelect(buildSingleDropdownOptions(selected));
    }
  }, []);

  const onSelectAll = () => {
    const filteredOptions = !searchValue ? options
      : options
        .filter(({ label: optionLabel, field }) => `${optionLabel}${field}`.toLowerCase()
          .includes(searchValue.toLowerCase()));

    onSelect && onSelect(unionBy(filteredOptions, currentlySelectedFilters, 'field'));
  };

  const onResetAll = () => onSelect && onSelect([]);

  const onSelectItem = (selectedItem) => {
    const newlySelected = xorBy(currentlySelectedFilters, [selectedItem], 'field');
    onSelect && onSelect(newlySelected);
  };

  const onSearchChange = (searchTerm) => setSearchValue(searchTerm);

  return (
    <div className={classes.multiSelectFilterRoot}>
      <div className={classes.header}>
        <div className={classes.label}>{label}</div>

        <div
          ref={actionsRef}
          className={classes.actionsRow}
        >
          <div
            className={classes.actionItem}
            onClick={onSelectAll}
          >
            Select all
          </div>

          <div
            className={classes.actionItem}
            onClick={onResetAll}
          >
            Clear all
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <Dropdown
          isMultiple
          withSearch
          width="100%"
          options={options}
          selected={currentlySelectedFilters}
          onSelect={onSelectItem}
          onSearch={onSearchChange}
          blurExceptionNodes={[actionsRef]}
          classes={{ wrapper: classes.dropdownWrapper }}
        />
      </div>
    </div>
  );
};

MultiSelectFilter.propTypes = {
  label: string,
  selected: array,
  currentlySelectedFilters: arrayOf(shape({
    field: string,
    label: string,
  })),
  options: arrayOf(shape({
    field: string,
    label: string,
  })),
  onSelect: func,
};

MultiSelectFilter.defaultProps = {
  label: '',
  selected: [],
  options: [],
  currentlySelectedFilters: [],
};
