import React, { memo } from 'react';
import { string, func, object } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
  svgIconRoot: {
    cursor: ({ onClick }) => !!onClick && 'pointer' || 'initial',
  },
}));

export const SvgIcon = memo((props) => {
  const classes = useStyles(props);
  const { name, onClick, onMouseDown, width, height, id } = props;

  return (
    <svg id={id} data-testid="svgIconTestId" width={width} height={height} onMouseDown={onMouseDown} className={classes.svgIconRoot} onClick={onClick}>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
});

SvgIcon.propTypes = {
  name: string,
  classes: object,
  onClick: func,
  onMouseDown: func,
};
