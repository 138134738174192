import { observable, action } from 'mobx';
import services from '../../services';

const { addRuleTypeServices } = services;

export class AddRuleTypeStore {
  @observable inputParams = [];

  @observable outputParams = [];

  @action.bound
  addInputParam(id, isValid = false, name = '', type = '', description = '', enumValues = '', example = '', required = true) {
    this.inputParams = [...this.inputParams, { id: id, isValid: isValid, name: name, type: type, description: description, enumValues: enumValues, example: example, required: required }];
  }

  @action.bound
  addOutputParam(id, isValid = false, name = '', type = '', description = '', enumValues = '', example = '') {
    this.outputParams = [...this.outputParams, { id: id, isValid: isValid, name: name, type: type, description: description, enumValues: enumValues, example: example, required: true }];
  }

  @action.bound
  clearParams() {
    this.inputParams = [];
    this.outputParams = [];
  }

  @action.bound
  removeInputParam(id) {
    this.inputParams = this.inputParams.filter((el) => el.id !== id);
  }

  @action.bound
  removeOutputParam(id) {
    this.outputParams = this.outputParams.filter((el) => el.id !== id);
  }

  @action.bound
  updateInputParam(id, field, value) {
    const foundParam = this.inputParams.find((el) => el.id === id);
    foundParam[field] = value;
  }

  @action.bound
  updateOutputParam(id, field, value) {
    const foundParam = this.outputParams.find((el) => el.id === id);
    foundParam[field] = value;
  }

  @action.bound
  setInputParamValidity(id, val) {
    const param = this.inputParams.find((el) => el.id === id);
    if (param) {
      param.isValid = val;
    }
  }

  @action.bound
  setOutputParamValidity(id, val) {
    const param = this.outputParams.find((el) => el.id === id);
    if (param) {
      param.isValid = val;
    }
  }

  @action.bound
  async createNewRuleType(name, description, ruleTitle) {
    const ruleTypeObj = {
      title: ruleTitle,
      description: description,
      requestFields: [],
      responseFields: [],
    };
    this.inputParams.forEach((el) => {
      const newObj = {
        name: el.name,
        type: el.type.toLowerCase(),
        description: el.description,
        enumValues: el.enumValues.length > 0 ? el.enumValues : '',
        example: el.enumValues.length > 0 ? el.enumValues[0] : el.name,
        required: el.required,
      };
      if (el.type !== 'Enum') {
        delete newObj.enumValues;
      }
      ruleTypeObj.requestFields.push(newObj);
    });
    this.outputParams.forEach((el) => {
      const newObj = {
        name: el.name,
        type: el.type.toLowerCase(),
        description: el.description,
        enumValues: el.enumValues.length > 0 ? el.enumValues : '',
        example: el.enumValues.length > 0 ? el.enumValues[0] : el.name,
        required: el.required,
      };
      if (el.type !== 'Enum') {
        delete newObj.enumValues;
      }
      ruleTypeObj.responseFields.push(newObj);
    });
    const res = await addRuleTypeServices.createRuleType(name, ruleTypeObj);
    return { isCreated: res.status === 200, version: res.data.version };
  }

  @action.bound
  async getRuleTypeMetadata(ruleTypeName) {
    const res = await addRuleTypeServices.getRuleTypeMetadata(ruleTypeName);
    return res.data;
  }

  @action.bound
  async getRuleFlexTypes() {
    const res = await addRuleTypeServices.getFlexRuleTypes();
    return res;
  }

  @action.bound
  async getFlexRuleTypesWithVersions() {
    const res = await addRuleTypeServices.getFlexRuleTypesWithVersions();
    return res;
  }
}
