import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Zoom } from '@material-ui/core';
import { bool, func, string, object } from 'prop-types';

import { ErrorBoundary } from '../error-boundary';

const TRANSITION_DURATION = 1500;

const Transition = React.forwardRef((props, ref) => {
  return (
    <Zoom
      in
      timeout={{ enter: TRANSITION_DURATION, exit: TRANSITION_DURATION }}
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const useStyles = makeStyles(({ boxShadows }) => ({
  popup: {
    padding: '15px 45px 36px',
    position: 'relative',
  },
  paper: {
    boxShadow: boxShadows.commonShadow,
  },
}));

export const Popup = memo((props) => {
  const {
    isOpen,
    BackdropProps,
    children,
    transitionComponent,
    onBackgroundClick,
  } = props;
  const classes = useStyles(props);

  return (
    <Dialog
      open={isOpen}
      maxWidth={false}
      BackdropProps={BackdropProps}
      classes={{ paper: classes.paper }}
      scroll="paper"
      TransitionComponent={transitionComponent}
      onClose={onBackgroundClick}
    >
      <div className={classes.popup}>
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </div>
    </Dialog>
  );
});

Popup.propType = {
  isOpen: bool.isRequired,
  BackdropProps: object,
  children: string.isRequired,
  transitionComponent: object,
  onBackgroundClick: func,
};

Popup.defaultProps = {
  transitionComponent: Transition,
};
