import React, { memo } from 'react';
import { Tooltip } from '@material-ui/core';
import { bool, string, object } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    whiteSpace: 'pre-wrap',
  },
  arrow: {},
  childrenClass: {},
}));

export const CustomTooltip = memo((props) => {
  const {
    isWithArrow,
    isHintDisabled,
    title,
    placement,
    interactive,
    disableFocusListener,
    disableHoverListener,
    disableTouchListener,
  } = props;
  const classes = useStyles(props);
  const { childrenClass, ...tooltipClasses } = classes;

  return (
    <Tooltip
      arrow={isWithArrow}
      interactive={interactive}
      disableFocusListener={disableFocusListener || isHintDisabled}
      disableHoverListener={disableHoverListener || isHintDisabled}
      disableTouchListener={disableTouchListener || isHintDisabled}
      placement={placement}
      title={title}
      classes={tooltipClasses}
    >
      <span className={childrenClass}>
        {props.children}
      </span>
    </Tooltip>
  );
});

CustomTooltip.propTypes = {
  disableFocusListener: bool,
  disableHoverListener: bool,
  disableTouchListener: bool,
  interactive: bool,
  isHintDisabled: bool,
  isWithArrow: bool,
  title: string,
  placement: string,
  classes: object,
};

CustomTooltip.defaultProps = {
  disableFocusListener: false,
  disableHoverListener: false,
  disableTouchListener: false,
  isWithArrow: false,
  isHintDisabled: false,
  interactive: true,
  title: '',
};
