import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '../svg-icon';
import { black, white } from '../../styles/colors';

import '../../assets/icons/cloud-upload.svg';

const useStyles = makeStyles(() => ({
  uploadFileContainer: {
    width: 410,
    height: 200,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
    border: '2px dashed #BDBDBD',
  },
  browseButton: {
    width: 128,
    height: 32,
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: black,
    color: white,
    cursor: 'pointer',
    '& label:hover': {
      color: black,
      backgroundColor: white,
      transition: '.2s',
    },
  },
  browseInput: {
    display: 'none',
  },
  uploadText: {
    fontSize: 16,
    fontWeight: 700,
    color: black,
  },
  fileNameContainer: {
    display: 'flex',
  },
  disabledInput: {
    width: 128,
    height: 32,
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CACACA',
    color: white,
  },
}));

export const UploadFile = (props) => {
  const classes = useStyles(props);

  const { handleChangeFile, isFilePicked } = props;
  const labelOrDivClass = isFilePicked ? classes.disabledInput : classes.browseButton;
  return (
    <div className={classes.uploadFileContainer}>
      <SvgIcon name="cloud-upload" width={36} height={36} />
      <p className={classes.uploadText}>UPLOAD FILE</p>
      <div className={labelOrDivClass}>
        <label htmlFor="file-input" className={labelOrDivClass}>Browse</label>
        <input
          id="file-input"
          accept=".json"
          disabled={isFilePicked}
          onClick={(event) => { event.currentTarget.value = null; }}
          onChange={handleChangeFile}
          className={classes.browseInput}
          type="file"
        />
      </div>
    </div>
  );
};
