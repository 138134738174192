import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '../svg-icon';
import { darkestGray } from '../../styles/colors';

import '../../assets/icons/cross.svg';

const useStyles = makeStyles(() => ({
  filterBoxContainer: {
    border: `1px solid ${darkestGray}`,
    borderRadius: 3,
    display: 'inline-block',
    padding: '4px 8px',
    marginTop: 24,
    marginBottom: 10,
    marginRight: 8,
  },
  filterBoxContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 600,
  },
  svgIconRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const FilterBox = (props) => {
  const { name, value, onClick } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.filterBoxContainer}>
      <div className={classes.filterBoxContent}>
        <span className={classes.filterBoxContent}>{name.toUpperCase()} : {typeof (value) === 'string' ? value.toUpperCase() : value}</span>
        <div className={classes.filterBoxIconsContainer}>
          <SvgIcon width={12} height={12} name="cross" classes={classes} onClick={() => onClick(name, value)} />
        </div>
      </div>
    </div>
  );
};
