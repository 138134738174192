import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  tabsRootContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  inputsContainer: {
    marginRight: 50,
  },
  tabsTextContainer: {
    fontWeight: 700,
    fontSize: 18,
    cursor: 'pointer',
  },
  selectedTab: {
    borderBottom: '1px solid #000',
  },
}));

export const ControlTabs = ({ selectedTab, setSelectedTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabsRootContainer}>
      <div onClick={() => setSelectedTab('INPUTS')} className={selectedTab === 'INPUTS' ? cx(classes.inputsContainer, classes.tabsTextContainer, classes.selectedTab) : cx(classes.inputsContainer, classes.tabsTextContainer)}>
        <p>INPUTS</p>
      </div>
      <div onClick={() => setSelectedTab('JSON VIEWER')} className={selectedTab === 'JSON VIEWER' ? cx(classes.tabsTextContainer, classes.selectedTab) : classes.tabsTextContainer}>
        <p>JSON VIEWER</p>
      </div>
    </div>
  );
};
