import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { SvgIcon } from '../svg-icon';

import '../../assets/icons/plusBlack.svg';

const useStyles = makeStyles(() => ({
  addNewLineContainer: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 14,
    marginLeft: 36,
    cursor: 'pointer',
  },
  disabledAddNewLine: {
    cursor: 'initial',
    color: '#757575',
  },
  svgIconRoot: {
    marginRight: 8,
    color: 'black',
  },
}));

export const AddNewLine = ({ isDisabled, onClick, id }) => {
  const classes = useStyles();

  return (
    <p id={id} data-testid="addNewLineTestId" onClick={isDisabled ? null : onClick} className={isDisabled ? cx(classes.addNewLineContainer, classes.disabledAddNewLine) : classes.addNewLineContainer}>
      <SvgIcon classes={classes} width={12} height={12} name="plusBlack" /> Add new line
    </p>
  );
};
