import React from 'react';
import { bool, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { Checkbox } from '../checkbox';
import { Divider } from '../divider';
import * as themeColors from '../../styles/colors';

const useStyles = makeStyles(({ mixins }) => ({
  secondaryFiltersContainer: {
    ...mixins.flexRow,
  },
  draftContainer: {
    ...mixins.flexCenterCenter,
    marginTop: 30,
    fontSize: 14,
    fontWeight: 500,
  },
  resetFiltersContainer: {
    ...mixins.flexCenterCenter,
    margin: '24px 0',
  },
  resetFilters: {
    background: themeColors.white,
  },
  divider: {
    background: themeColors.white,
  },
  dividerWrapper: {
    margin: '0px 11px',
  },
}));

export const FilterCheckbox = observer((props) => {
  const classes = useStyles();

  const { text, checked, onCheck, id } = props;

  const eventHandlers = {
    onCheck: (event) => {
      event.stopPropagation();
      onCheck();
    },
  };

  return (
    <div id={id} data-testid="filterCheckboxTestId" className={classes.secondaryFiltersContainer}>
      <div className={classes.draftContainer}>
        <Checkbox label={text} checked={checked} onCheck={eventHandlers.onCheck} />
      </div>
      <Divider classes={classes} />
    </div>
  );
});

FilterCheckbox.propTypes = {
  disableSearch: bool,
  onSearch: func,
};

FilterCheckbox.defaultProps = {
  disableSearch: false,
};
