import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '../svg-icon';
import { blackOpacity5, grayBackground } from '../../styles/colors';

import '../../assets/icons/plus.svg';

const useStyles = makeStyles(() => ({
  paramContainer: {
    width: '100%',
    height: 50,
    backgroundColor: grayBackground,
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    fontSize: 14,
    fontWeight: 500,
    color: blackOpacity5,
  },
  svgIconRoot: {
    fill: blackOpacity5,
    marginRight: 8,
    marginLeft: 12,
  },
}));

export const AddRuleTypeParamLabel = ({ label, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.paramContainer}>
      <SvgIcon classes={classes} width={18} height={18} name="plus" onClick={onClick} />
      <span data-testid="addRuleTypeParamLabelTestId" className={classes.labelText} onClick={onClick}>{label}</span>
    </div>
  );
};
