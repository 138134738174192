import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { uniqueId, capitalize } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { CircularProgress, Box } from '@material-ui/core';
import { BackToRuletypesLabel } from '../../components/back-to-ruletypes-label';
import { TextInput } from '../../components/text-input';
import { SymbolsCounter } from '../../components/symbols-counter';
import { AddRuleTypeParamLabel } from '../../components/add-rule-type-param-label';
import { Button, buttonTypes } from '../../components/button';
import { Dialog } from '../../components/dialog';
import { CONTINUE, CANCEL } from '../../constants/common';
import { useStores } from '../../custom-hooks/store/use-stores';
import { Collapsed } from '../../components/collapsed/Collapsed';
import { black, white, red, invalidRed, whiteOrange } from '../../styles/colors';
import { AddParameterForm } from '../../components/add-parameter-form/AddParameterForm';
import { UploadFile } from '../../components/upload-file';
import { UploadDialog } from '../../components/upload-dialog/UploadDialog';

const useStyles = makeStyles(() => ({
  backContainer: {
    position: 'absolute',
    width: '100%',
    left: 0,
  },
  addNewHeader: {
    marginTop: 75,
    marginLeft: 20,
    fontSize: 18,
    fontWeight: 700,
  },
  ruleTypeInfo: {
    display: 'flex',
    marginTop: 20,
  },
  textField: {
    height: 50,
  },
  nameContainer: {
    marginRight: 12,
    height: 50,
    '& textarea': {
      height: '100% !important',
    },
  },
  descriptionContainer: {
    height: 121,
    '& textarea': {
      height: '100% !important',
    },
  },
  inputAndCountContainer: {
    width: '100%',
  },
  addParamsContainer: {
    display: 'flex',
    marginTop: 45,
    marginBottom: 72,
    paddingLeft: 20,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: 150,
    height: 40,
    marginRight: 24,
  },
  dialogButton: {
    width: 192,
    height: 40,
  },
  buttonSave: {
    width: 150,
    height: 40,
  },
  buttons: {
    display: 'flex',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popup: {
    width: 500,
    padding: '0 48px 48px 48px',
  },
  header: {
    color: 'white',
    border: 'none',
  },
  dialogCross: {
    position: 'absolute',
    top: 29,
    right: 29,
  },
  cancelPopupText: {
    color: 'black',
  },
  invalidTextFieldInput: {
    '& textarea': {
      border: `1px solid ${invalidRed}`,
    },
  },
  addParamControl: {
    width: '100%',
  },
  addParamMarginRight: {
    marginRight: 12,
  },
  divider: {
    width: 48,
    backgroundColor: white,
  },
  inputBackgroundColor: {
    backgroundColor: '#EEF4FB',
  },
  outputBackgroundColor: {
    backgroundColor: whiteOrange,
  },
  uploadFilePopupButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  saveContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  savePopup: {
    width: 500,
    height: 180,
    padding: 0,
  },
  saveButtons: {
    marginTop: 0,
  },
  saveBody: {
    color: black,
    marginTop: 10,
  },
  saveText: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 12,
  },
  saveBodyText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#767676',
    marginTop: 24,
  },
  uploadHeader: {
    color: white,
    border: 'none',
  },
  quitHeader: {
    color: white,
    border: 'none',
  },
  quitCross: {
    position: 'absolute',
    top: 29,
    right: 29,
  },
  quitSwoosh: {
    marginTop: 60,
    marginBottom: 48,
  },
  quitText: {
    fontSize: 18,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  quitPopup: {
    width: 500,
    padding: '15px 5px 36px',
  },
  uploadDialogButton: {
    width: 192,
    height: 40,
  },
  uploadChainbuttons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '0 25px',
  },
  uploadChainBody: {
    maxWidth: 500,
    padding: '0 48px',
  },
  headerContent: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 18,
    fontWeight: 700,
  },
  errorText: {
    color: red,
    width: '100%',
  },
  nameAndTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const AddRuleType = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const params = {
    name: queryParams.get('name'),
    isEdit: queryParams.get('isEdit') === 'true',
  };

  let rtName = '';
  if (params.name) {
    const index = params.name.indexOf('-');
    rtName = params.name.substr(0, index);
  }

  const [ruleName, setRuleName] = useState(rtName);
  const [ruleDescription, setRuleDescription] = useState(params.description ? params.description : '');
  const [ruleTitle, setRuleTitle] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(!params.isEdit);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [isInvalidRuleNameInput, setIsInvalidRuleNameInput] = useState(false);
  const [isInvalidRuleDescrInput, setIsInvalidRuleDescrInput] = useState(false);
  const [isInvalidRuleTitleInput, setIsInvalidRuleTitleInput] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isUploadFileApplyDisabled, setIsUploadFileApplyDisabled] = useState(true);
  const [isSavingRuleTypePopupOpen, setIsSavingRuleTypePopupOpen] = useState(false);
  const [isSuccessfulSavePopupOpen, setIsSuccessfulSavePopupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isErrorFromUploadOpen, setIsErrorFromUploadOpen] = useState(false);
  const [isQuitFromUploadOpen, setIsQuitFromUploadOpen] = useState(false);
  const [newRTVersion, setNewRTVersion] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const isRulePage = true;

  const classes = useStyles();

  const {
    notificationStore: {
      addError,
      addSuccess,
    },
    addRyleTypeStore: {
      inputParams,
      outputParams,
      addInputParam,
      addOutputParam,
      setInputParamValidity,
      setOutputParamValidity,
      removeInputParam,
      removeOutputParam,
      updateInputParam,
      updateOutputParam,
      createNewRuleType,
      clearParams,
      getRuleTypeMetadata,
      getFlexRuleTypesWithVersions,
      getRuleFlexTypes,
    },
    ruleLineStore: {
      uploadFile,
    },
    ruleTypesStore: {
      clearHomePageFilters,
    },
    uiStore: {
      userPermissions,
    },
  } = useStores();

  useEffect(() => {
    if (userPermissions && !userPermissions.includes('METADATA_EDITOR')) {
      history.push('/');
    }
    clearParams();
  }, []);

  const createParamObject = (addParamFunc, param) => {
    return addParamFunc(
      uniqueId(),
      true,
      param.name,
      capitalize(param.type),
      param.description ? param.description : '',
      param.enumValues ? param.enumValues : [],
      param.example ? param.example : '',
      param.required,
    );
  };

  const loopAndCreateParams = (inParams, outParams) => {
    inParams.forEach((el) => {
      createParamObject(addInputParam, el);
    });

    outParams.forEach((el) => {
      createParamObject(addOutputParam, el);
    });
  };

  useEffect(() => {
    if (params.name) {
      clearParams();
      const getMetadata = async () => {
        let metadata = null;
        try {
          metadata = await getRuleTypeMetadata(params.name);
        } catch (error) {
          addError(error);
        }
        if (metadata) {
          if (metadata.description) {
            setRuleDescription(metadata.description);
          }
          if (metadata.title) {
            setRuleTitle(metadata.title);
          }
          if (metadata.fields) {
            const inParams = metadata.fields.filter((p) => p.inOut === 'IN');
            const outParams = metadata.fields.filter((p) => p.inOut === 'OUT');
            loopAndCreateParams(inParams, outParams);
          } else if (metadata.requestFields && metadata.responseFields) {
            const inParams = metadata.requestFields;
            const outParams = metadata.responseFields;
            loopAndCreateParams(inParams, outParams);
          }
        }
      };
      getMetadata();
    }
  }, [params.name]);

  const onBackToRuletypesClick = useCallback(() => {
    clearHomePageFilters();
    history.push('/mpruleengineui/ruletypes');
  }, [history]);

  const openCancelPopup = useCallback(() => setIsCancelPopupOpen(true), [setIsCancelPopupOpen]);
  const closeCancelPopup = useCallback(() => setIsCancelPopupOpen(false), [setIsCancelPopupOpen]);

  const openUploadDialog = useCallback(() => setIsUploadDialogOpen(true), [setIsUploadDialogOpen]);
  const closeUploadDialog = useCallback(() => setIsUploadDialogOpen(false), [setIsUploadDialogOpen]);

  const openSuccessSavePopup = useCallback(() => setIsSuccessfulSavePopupOpen(true), [setIsSuccessfulSavePopupOpen]);
  const closeSuccessSavePopup = useCallback(() => setIsSuccessfulSavePopupOpen(false), [setIsSuccessfulSavePopupOpen]);

  const openQuitUploadDialog = useCallback(() => setIsQuitFromUploadOpen(true), [setIsQuitFromUploadOpen]);
  const closeQuitUploadDialog = useCallback(() => setIsQuitFromUploadOpen(false), [setIsQuitFromUploadOpen]);

  const openErrorUploadDialog = useCallback(() => setIsErrorFromUploadOpen(true), [setIsErrorFromUploadOpen]);
  const closeErrorUploadDialog = useCallback(() => {
    setIsErrorFromUploadOpen(false);
    setSelectedFile(null);
    setIsFilePicked(false);
    setIsSaveClicked(false);
    setProgress(0);
  }, [setIsErrorFromUploadOpen, setSelectedFile, setIsFilePicked, setProgress, setIsSaveClicked]);

  const closeUploadAndQuitUploadDialogs = useCallback(() => {
    closeQuitUploadDialog();
    closeUploadDialog();
  }, [closeUploadDialog, closeQuitUploadDialog]);

  const setInputParamIsValid = useCallback((id, val) => {
    setInputParamValidity(id, val);
  }, [setInputParamValidity]);

  const setOutputParamIsValid = useCallback((id, val) => {
    setOutputParamValidity(id, val);
  }, [setOutputParamValidity]);

  const addInputParameter = useCallback(() => {
    addInputParam(uniqueId());
  }, [addInputParam, uniqueId]);

  const addOutputParameter = useCallback(() => {
    addOutputParam(uniqueId());
  }, [addOutputParam, uniqueId]);

  const handleOnChangeRuleName = useCallback((val) => {
    if (val === '' || val.length <= 25) {
      setRuleName(val);
      if (ruleDescription.length > 0) {
        setIsSaveDisabled(false);
      }
      if (!ruleName.match(/\W/g) || !val.match(/\W/g)) {
        setIsInvalidRuleNameInput(false);
      }
    }
    if (val.length === 0) {
      setIsSaveDisabled(true);
    }
  }, [setRuleName, ruleDescription, ruleName, setIsInvalidRuleNameInput, setIsSaveDisabled]);

  const handleOnChangeRuleTitle = useCallback((val) => {
    if (val === '' || val.length <= 50) {
      setRuleTitle(val);
    }
    if (val.length === 0) {
      setIsInvalidRuleTitleInput(true);
      setIsSaveDisabled(true);
    } else {
      setIsInvalidRuleTitleInput(false);
      setIsSaveDisabled(false);
    }
  }, [setRuleTitle, setIsInvalidRuleTitleInput, setIsSaveDisabled]);

  const handleOnBlurRuleName = useCallback(() => {
    if (ruleName.match(/\W/g)) {
      setIsInvalidRuleNameInput(true);
      addError('Please fill in required information');
    }
  }, [ruleName, setIsInvalidRuleNameInput, addError]);

  const handleOnChangeRuleDescription = useCallback((val) => {
    if (val === '' || val.length <= 150) {
      setRuleDescription(val);
      if (ruleName.length > 0) {
        setIsSaveDisabled(false);
      }
    }
    if (val.length === 0) {
      setIsInvalidRuleDescrInput(true);
      setIsSaveDisabled(true);
    } else {
      setIsInvalidRuleDescrInput(false);
    }
  }, [setRuleDescription, ruleName, setIsSaveDisabled]);

  useEffect(() => {
    setTimeout(() => {
      if (inputParams.every((el) => !el.required) && !isFirstRender) {
        addError('Rule type must have at least one required input parameter');
      }
      setIsFirstRender(false);
    }, 0);
  }, [inputParams && inputParams.length, addError]);

  const handleFileUpload = async () => {
    const res = await uploadFile(ruleName, selectedFile, setProgress);
    if ((!!res[0] && res[0].code) || !!res[0] && res[0].message) {
      setUploadError(res[0].message);
      openErrorUploadDialog();
    } else if (res.status === 200) {
      addSuccess('New version is created!');
      setIsUploadDialogOpen(false);
      const flexRTsWithVersions = await getRuleFlexTypes();
      const foundFlexRT = flexRTsWithVersions[ruleName];
      const metadataVersion = foundFlexRT ? foundFlexRT[foundFlexRT.length - 1] : 1;
      const { version } = res.data;
      setTimeout(() => {
        const url = '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: `${ruleName}-${metadataVersion}`, ruleTypeVersion: version, isDraft: false, basedOnVersion: 'Original' });
        history.push(url);
      }, 2000);
    }
  };

  const handleOnSaveClick = async () => {
    setIsSavingRuleTypePopupOpen(true);
    const { isCreated, version } = await createNewRuleType(ruleName, ruleDescription, ruleTitle);
    if (isCreated) {
      setNewRTVersion(version);
      setIsSavingRuleTypePopupOpen(false);
      openSuccessSavePopup();
    } else {
      addError('Error occured while creating rule type');
    }
  };

  const redirectToNewRuleTypePage = useCallback(async () => {
    const metadataVersion = newRTVersion;
    const flexRTsWithVersions = await getFlexRuleTypesWithVersions();
    const foundFlexRT = flexRTsWithVersions.rules.find((el) => el.ruleType === `${ruleName}-${metadataVersion}`);
    let rtVersion = 0;
    if (foundFlexRT.versions.length > 0) {
      rtVersion = foundFlexRT.versions[foundFlexRT.versions.length - 1].version;
    }
    rtVersion = rtVersion === 'DRAFT' ? 'Draft' : rtVersion;
    const url = '/mpruleengineui/view?' + new URLSearchParams({ ruleTypeName: `${ruleName}-${metadataVersion}`, ruleTypeVersion: rtVersion, isDraft: rtVersion === 'Draft', basedOnVersion: rtVersion !== 'Draft' ? 'Original' : foundFlexRT.versions[0].version });
    history.push(url);
  }, [newRTVersion, history]);

  const saveDisabledState = isSaveDisabled
    || isInvalidRuleNameInput
    || ruleTitle.length === 0
    || inputParams.some((el) => !el.isValid)
    || outputParams.some((el) => !el.isValid)
    || (inputParams && inputParams.length === 0)
    || (outputParams && outputParams.length === 0)
    || new Set([...inputParams.map(el => el.name), ...outputParams.map(el => el.name)]).size
    !== [...inputParams.map(el => el.name), ...outputParams.map(el => el.name)].length
    || inputParams.every((el) => !el.required);

  return (
    <Fragment>
      <div className={classes.backContainer}>
        <BackToRuletypesLabel id="backToRuleTypesOnAddRuleTypePageId" text="← Back to ruletypes" onClick={onBackToRuletypesClick} />
      </div>
      <div className={classes.addNewHeader}>
        <p>Add new rule type</p>
        <div className={classes.ruleTypeInfo}>
          <div className={classes.nameAndTitle}>
            <div>
              <TextInput
                multiline
                value={ruleName}
                disabled={params.isEdit}
                onChange={handleOnChangeRuleName}
                onBlur={handleOnBlurRuleName}
                classes={{ textField: isInvalidRuleNameInput ? cx(classes.nameContainer, classes.invalidTextFieldInput) : classes.nameContainer }}
                width={550}
                placeholder="* Enter rule type name"
              />
              <SymbolsCounter maxCount={25} currentSymbolsCount={ruleName.length} />
            </div>
            <div>
              <TextInput
                multiline
                value={ruleTitle}
                onChange={handleOnChangeRuleTitle}
                classes={{ textField: isInvalidRuleTitleInput ? cx(classes.nameContainer, classes.invalidTextFieldInput) : classes.nameContainer }}
                width={550}
                placeholder="* Enter a title for the API request body"
              />
              <SymbolsCounter maxCount={50} currentSymbolsCount={ruleTitle.length} />
            </div>
          </div>
          <div className={classes.inputAndCountContainer}>
            <TextInput
              multiline
              value={ruleDescription}
              onChange={handleOnChangeRuleDescription}
              classes={{ textField: isInvalidRuleDescrInput ? cx(classes.descriptionContainer, classes.invalidTextFieldInput) : classes.descriptionContainer }}
              placeholder="* Description"
            />
            <SymbolsCounter maxCount={150} currentSymbolsCount={ruleDescription.length} />
          </div>
        </div>
      </div>
      <div className={classes.addParamsContainer}>
        <div className={classes.addParamControl}>
          {inputParams.map((el) => {
            return (
              <Collapsed
                key={el.id}
                isEdit={params.isEdit}
                el={el}
                isFolded={params.isEdit}
                updateParameter={updateInputParam}
                classes={{ headerContainer: classes.inputBackgroundColor }}
                color="#EEF4FB"
                header="NAME"
                id={el.id}
                inOrOut="IN"
                inputParams={inputParams}
                outputParams={outputParams}
                removeParam={() => removeInputParam(el.id)}
                setIsValid={setInputParamIsValid}
                addError={addError}
              >
                <AddParameterForm />
              </Collapsed>
            );
          })}
          <AddRuleTypeParamLabel onClick={addInputParameter} label="Click to add input param" />
        </div>
        <p className={classes.divider} />
        <div className={classes.addParamControl}>
          {outputParams.map((el) => {
            return (
              <Collapsed
                key={el.id}
                el={el}
                isEdit={params.isEdit}
                isFolded={params.isEdit}
                updateParameter={updateOutputParam}
                classes={{ headerContainer: classes.outputBackgroundColor }}
                color="#FFE7D3"
                header="NAME"
                id={el.id}
                inOrOut="OUT"
                removeParam={() => removeOutputParam(el.id)}
                setIsValid={setOutputParamIsValid}
                inputParams={inputParams}
                outputParams={outputParams}
                addError={addError}
              >
                <AddParameterForm />
              </Collapsed>
            );
          })}
          <AddRuleTypeParamLabel onClick={addOutputParameter} label="Click to add output param" />
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          type={buttonTypes.SECONDARY}
          label={CANCEL}
          classes={{ button: classes.button }}
          onClick={(ruleName.length > 0 || ruleDescription.length > 0) ? openCancelPopup : onBackToRuletypesClick}
        />
        <Button
          type={buttonTypes.PRIMARY}
          label="SAVE"
          classes={{ button: classes.buttonSave }}
          disabled={saveDisabledState}
          onClick={handleOnSaveClick}
        />
      </div>
      <Dialog
        isOpen={isCancelPopupOpen}
        isRulePage={isRulePage}
        applyLabel={CONTINUE}
        header="a"
        cancelLabel="BACK TO EDITING"
        onApply={onBackToRuletypesClick}
        onCancel={closeCancelPopup}
        onCrossCancel={closeCancelPopup}
        classes={{
          button: classes.dialogButton,
          buttons: classes.buttons,
          body: classes.body,
          popup: classes.popup,
          cross: classes.dialogCross,
          header: classes.header,
        }}
        withSwoosh
        withCross
        isReversed
      >
        <div className={classes.cancelPopupText}>
          All your changes will be lost. Do you want to continue?
        </div>
      </Dialog>
      <Dialog
        isOpen={isSavingRuleTypePopupOpen}
        isRulePage={isRulePage}
        classes={{
          buttons: classes.saveButtons,
          popup: classes.savePopup,
          body: classes.saveBody,
        }}
      >
        <div className={classes.saveContainer}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            width={60}
            height={60}
          >
            <CircularProgress color={black} />
          </Box>
          <p className={classes.saveText}>Saving...</p>
          <p className={classes.saveBodyText}>It might take us a 10 sec to save the rule type.</p>
        </div>
      </Dialog>
      <Dialog
        isOpen={isSuccessfulSavePopupOpen}
        isRulePage={isRulePage}
        header="a"
        withCross
        withSwoosh
        isReversed
        onCrossCancel={closeSuccessSavePopup}
        onApply={openUploadDialog}
        onCancel={redirectToNewRuleTypePage}
        applyLabel="Upload data file"
        cancelLabel="Add rule data manually"
        classes={{
          button: classes.dialogButton,
          buttons: classes.buttons,
          body: classes.body,
          popup: classes.popup,
          cross: classes.dialogCross,
          header: classes.header,
        }}
      >
        Rule type &quot;{ruleName}&quot; was successfully added!
      </Dialog>
      <UploadDialog
        isOpen={isUploadDialogOpen}
        withCross
        header="a"
        classes={{
          header: classes.uploadHeader,
          buttons: classes.uploadFilePopupButtons,
          button: classes.uploadDialogButton,
        }}
        applyLabel="Save as new version"
        cancelLabel="Cancel"
        applyButtonDisabled={isUploadFileApplyDisabled}
        onApply={handleFileUpload}
        onCancel={isFilePicked ? openQuitUploadDialog : closeUploadDialog}
        onCrossCancel={isFilePicked ? openQuitUploadDialog : closeUploadDialog}
        setIsUploadFileApplyDisabled={setIsUploadFileApplyDisabled}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        isFilePicked={isFilePicked}
        setIsFilePicked={setIsFilePicked}
        progress={progress}
        setProgress={setProgress}
        isSaveClicked={isSaveClicked}
        setIsSaveClicked={setIsSaveClicked}
        isReversed
      >
        <UploadFile />
      </UploadDialog>
      <Dialog
        isOpen={isQuitFromUploadOpen}
        header="a"
        isRulePage={isRulePage}
        applyLabel="Back to upload"
        cancelLabel="Quit upload"
        onApply={closeQuitUploadDialog}
        onCancel={closeUploadAndQuitUploadDialogs}
        onCrossCancel={closeQuitUploadDialog}
        classes={{
          buttonWrapper: classes.buttonWrapper,
          button: classes.uploadDialogButton,
          buttons: classes.uploadChainbuttons,
          body: classes.uploadChainBody,
          header: classes.quitHeader,
          headerContent: classes.headerContent,
          swoosh: classes.quitSwoosh,
          cross: classes.quitCross,
          popup: classes.quitPopup,
        }}
        withSwoosh
        withCross
      >
        <div className={classes.quitText}>
          Quit uploading a new version?
        </div>
      </Dialog>
      <Dialog
        isOpen={isErrorFromUploadOpen}
        header="a"
        isRulePage={isRulePage}
        applyLabel="Upload another file"
        cancelLabel="Cancel upload"
        onApply={closeErrorUploadDialog}
        onCancel={() => {
          closeErrorUploadDialog();
          closeUploadDialog();
        }}
        onCrossCancel={() => {
          closeErrorUploadDialog();
          closeUploadDialog();
        }}
        classes={{
          buttonWrapper: classes.buttonWrapper,
          button: classes.uploadDialogButton,
          buttons: classes.uploadChainbuttons,
          body: classes.uploadChainBody,
          header: classes.quitHeader,
          headerContent: classes.headerContent,
          swoosh: classes.quitSwoosh,
          cross: classes.quitCross,
          popup: classes.quitPopup,
        }}
        withSwoosh
        withCross
      >
        <div className={classes.errorCont}>
          {!!selectedFile && selectedFile.name} cannot be used to create a new version.
          <p className={classes.errorText}>Error: {uploadError}</p>
        </div>
      </Dialog>
    </Fragment>
  );
});
