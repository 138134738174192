import React, { Fragment, useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { array, func, string, number, arrayOf, bool, object, shape, oneOf } from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { ASC, DESC, NONE, ROW_HEIGHT } from '../../constants/common';
import { HeaderRow } from '../header-row';
import { VirtualTableRow } from './VirtualTableRow';

const useStyles = makeStyles(({ fontFamilies, borders, colors, mixins }) => ({
  tableRoot: {
    ...mixins.flexColumn,
    height: '100%',
    marginBottom: 20,
    position: 'relative',
    overflow: 'scroll',
  },
  placeholder: {
    ...mixins.flexCenterCenter,
    width: '100%',
    height: 180,
    border: borders.gray,
    fontFamily: fontFamilies.primary,
    color: colors.darkGray,
    textTransform: 'uppercase',
  },
  headerRow: {
    display: 'flex',
  },
  checkboxPlaceholder: {
    display: 'flex',
    minWidth: 22,
    maxWidth: 22,
    flex: 1,
    position: 'sticky',
    background: colors.white,
    top: 0,
    left: 0,
    zIndex: 1,
  },
  defaultCursor: {
    cursor: ({ isEditMode }) => isEditMode && 'default',
  },
  tableWrapper: {
    flex: 1,
    position: 'relative',
  },
  noDataPlaceholder: {
    position: 'absolute',
    top: 105,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: fontFamilies.primary,
    color: colors.darkGray,
    textTransform: 'uppercase',
  },
}));

export const Table = (props) => {
  const headerRowRef = useRef(null);

  const classes = useStyles(props);
  const {
    isHeaderRowDisabled,
    withCheckbox,
    isDataLoading,
    placeholder,
    minValue,
    maxValue,
    items,
    highlightedItems,
    originalItemsCount,
    rowFields,
    rowTooltips,
    filtersData,
    filtering,
    sorting,
    headerRowFields,
    tooltipEditingBehavior,
    columnsSizing,
    headerClasses,
    onCheckboxClick,
    onRowClick,
    onCellClick,
    onFieldChange,
    onSetCurrentFilters,
    onFiltersApply,
    onSortApply,
    isRulePage,
    onHeaderCheckboxClick,
    isDraftExists,
    addedRuleLines,
    editCell,
    onKeyDown,
    addError,
    invalidFields,
    isDraft,
    isUserEditor,
    cancelEditModeById,
  } = props;
  const [isEmpty, setIsEmpty] = useState(!!originalItemsCount && !items.length);
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false);
  useEffect(() => setIsEmpty(!!originalItemsCount && !items.length), [originalItemsCount, items]);

  const scrollableTableRef = useCallback(node => {
    if (node !== null) {
      // Next line is syncing initial position in case table remount (e.g. switch state from loading -> loaded)
      node.scrollLeft = headerRowRef.current.scrollLeft;
      node.addEventListener('scroll', ({ target: { scrollLeft } }) => {
        headerRowRef.current.scrollLeft = scrollLeft;
      });
    }
  }, []);

  const renderers = {
    placeholder: () => (
      <div className={classes.placeholder}>
        {isDataLoading ? (<span>Loading...</span>) : (<span>{placeholder}</span>)}
      </div>
    ),
    noDataPlaceholder: () => (
      <div className={classes.noDataPlaceholder}>{placeholder}</div>
    ),
  };

  // This allow us to avoid tons of re-renders
  const memoizedRowClasses = useMemo(() => ({
    rowItems: classes.defaultCursor,
    checkboxInput: classes.defaultCursor,
  }), [classes.defaultCursor]);

  const itemData = {
    isEmpty,
    withCheckbox: withCheckbox && !isEmpty,
    minValue,
    maxValue,
    items,
    highlightedItems,
    rowFields,
    addedRuleLines,
    columnsSizing,
    rowTooltips,
    tooltipEditingBehavior,
    classes: memoizedRowClasses,
    onCheckboxClick,
    onRowClick,
    onCellClick,
    onFieldChange,
    isRulePage,
    isDraftExists,
    editCell,
    onKeyDown,
    headerRowFields,
    addError,
    invalidFields,
    isDraft,
    cancelEditModeById,
    lastItem: items.length,
  };

  return (
    <div className={classes.tableRoot}>
      {(!originalItemsCount || isDataLoading) && renderers.placeholder() || (
        <Fragment>
          <div
            ref={headerRowRef}
            className={classes.headerRow}
          >
            {isRulePage ? null : !!withCheckbox && !isEmpty && <div className={classes.checkboxPlaceholder} />}
            <HeaderRow
              disabled={isHeaderRowDisabled}
              withCheckbox={withCheckbox && !isEmpty}
              rowFields={headerRowFields}
              columnsSizing={columnsSizing}
              filtersData={filtersData}
              filtering={filtering}
              sorting={sorting}
              onSetCurrentFilters={onSetCurrentFilters}
              onFiltersApply={onFiltersApply}
              onSortApply={onSortApply}
              isDraftExists={isDraftExists}
              classes={headerClasses}
              isRulePage={isRulePage}
              isDraft={isDraft}
              onCheckboxClick={onHeaderCheckboxClick}
              isSelected={isHeaderCheckboxChecked}
              setIsSelected={setIsHeaderCheckboxChecked}
              isUserEditor={isUserEditor}
            />
          </div>
          <div className={classes.tableWrapper}>
            <AutoSizer>
              {({ width }) => (
                <List
                  style={isRulePage ? { overflow: 'visible' } : { overflow: 'scroll' }}
                  outerRef={scrollableTableRef}
                  overscanCount={20}
                  height={ROW_HEIGHT * itemData.items.length}
                  itemCount={itemData.items.length}
                  itemSize={ROW_HEIGHT}
                  width={width}
                  itemData={itemData}
                >
                  {VirtualTableRow}
                </List>
              )}
            </AutoSizer>
          </div>
        </Fragment>
      )}
      {!items.length && !!originalItemsCount && renderers.noDataPlaceholder()}
    </div>
  );
};

Table.propTypes = {
  isDataLoading: bool,
  isHeaderRowDisabled: bool,
  withCheckbox: bool,
  placeholder: string,
  originalItemsCount: number,
  minValue: number,
  maxValue: number,
  items: array,
  highlightedItems: array,
  rowFields: arrayOf(string),
  rowTooltips: object,
  filtersData: object,
  tooltipEditingBehavior: object,
  headerClasses: object,
  sorting: shape({
    sortableFields: arrayOf(string).isRequired,
    sortBy: string.isRequired,
    sortOrder: oneOf([NONE, ASC, DESC]).isRequired,
  }),
  filtering: shape({
    appliedFilters: object,
    currentlySelectedFilters: object,
  }),
  columnsSizing: string.isRequired,
  onRowClick: func,
  onSortApply: func,
  onCheckboxClick: func,
  onFieldChange: func,
  onFiltersApply: func,
};

Table.defaultProps = {
  isDataLoading: false,
  isHeaderRowDisabled: false,
  withCheckbox: false,
  placeholder: 'No data to display',
  originalItemsCount: 0,
  items: [],
  highlightedItems: [],
  rowFields: [],
  filtersData: {},
  tooltipEditingBehavior: {},
  headerClasses: {},
};
